import { takeLatest, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

export function* mapsSave({ payload }) {
  try {
    const response = yield call(api.post, 'maps', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export default all([
  takeLatest('@maps/MAPS_SAVE_REQUEST', mapsSave),
]);
