import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { salesUpdateSuccess } from './actions';

export function* salesSave({ payload }) {
  try {
    const response = yield call(api.post, 'sales', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* salesUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `sales/${payload.id}`, payload.data);
    toast.success('Venda atualizada com sucesso!');
    yield put(salesUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a venda.');
  }
}

export function* salesStatusUpdate({ payload }) {
  try {
    console.log(payload)
    const response = yield call(api.put, `sales/status/${payload.sales_id}/${payload.status}`);
    toast.success('Painel de venda atualizada com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a venda.');
  }
}

export function* salesDelete({ payload }) {
  try {
    yield call(api.delete, `sales/${payload.sales_id}`);
    toast.success('Venda excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a venda.');
  }
}

export default all([
  takeLatest('@sales/SALES_SAVE_REQUEST', salesSave),
  takeLatest('@sales/SALES_UPDATE_REQUEST', salesUpdate),
  takeLatest('@sales/SALES_STATUS_UPDATE_REQUEST', salesStatusUpdate),
  takeLatest('@sales/DELETE_SALES_REQUEST', salesDelete),
]);
