import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { productUpdateSuccess } from './actions';

export function* productSave({ payload }) {
  try {
    const response = yield call(api.post, 'product', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* productUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `product/${payload.id}`, payload.data);
    toast.success('Produto/serviço atualizado com sucesso!');
    yield put(productUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o motivo.');
  }
}

export function* productDelete({ payload }) {
  try {
    yield call(api.delete, `product/${payload.product_id}`);
    toast.success('Produto/Serviço excluído com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar o motivo.');
  }
}

export default all([
  takeLatest('@product/PRODUCT_SAVE_REQUEST', productSave),
  takeLatest('@product/PRODUCT_UPDATE_REQUEST', productUpdate),
  takeLatest('@product/DELETE_PRODUCT_REQUEST', productDelete),
]);
