import React from 'react';
import { Columns } from 'react-bulma-components';
import { PieChart } from 'bizcharts';
import { SalesCityContainer } from './styles';

export default function SalesCity({ data }) {
  const sales = [];
  if(data.length === 0){
    sales.push({
      'value': 0
    })
  } else {
    {data.map((value, index) => (
      sales.push({
        'type': value.city_name,
        'value': value.city_count
      })
    ))}
  }

  return (
    <SalesCityContainer>
      <Columns>
        <Columns.Column size={12}>
          <PieChart
            data={sales}
            radius={0.8}
            angleField='value'
            colorField='type'
            label={{
              visible: true,
              type: 'outer',
              offset: 20,
            }}
          />
        </Columns.Column>
      </Columns>
    </SalesCityContainer>
  );
}
