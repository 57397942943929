export function brandSaveRequest(data) {
  return {
    type: '@brand/BRAND_SAVE_REQUEST',
    payload: { data },
  };
}

export function brandSaveSuccess(data) {
  return {
    type: '@brand/BRAND_SAVE_SUCCESS',
    payload: { data },
  };
}

export function brandUpdateRequest(id, data) {
  return {
    type: '@brand/BRAND_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function brandUpdateSuccess() {
  return {
    type: '@brand/BRAND_UPDATE_SUCCESS',
  };
}

export function brandDeleteRequest(brand_id) {
  return {
    type: '@brand/DELETE_BRAND_REQUEST',
    payload: { brand_id },
  };
}
