import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';

import { signOut } from '~/store/modules/auth/actions';
import api from '~/services/api';

import userDefault from '../../assets/user-default.svg';
import { userSaveRequest } from '~/store/modules/user/actions';
import MenuRegistrations from '~/components/MenuRegistrations';

export default function Profile() {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [image, setImage] = useState({ preview: "", raw: "" });

  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  async function onSubmit(data) {
    if(image.preview) {
      const formData = new FormData();
      formData.append("image", image.raw);

      const response = await api.post('files', formData);
      const { id, path } = response.data;

      data.file_id = id;
    }
    dispatch(userSaveRequest(data));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de usuário</li>
            </ul>
            <h1>Cadastro de usuário</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/user">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>

            <div>
              <label htmlFor="upload-button" className="imagem-avatar-upload-label">
                {image.preview ? (
                  <img src={image.preview} alt="" className="imagem-avatar-upload"/>
                ) : (
                  <img src={userDefault} alt="" className="imagem-avatar-upload"/>
                )}
                <span>Imagem de 150x150 pixels</span>
              </label>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange}
              />
            </div>


              <div className="field">
                <label htmlFor="name" className="label">
                  Nome do usuário
                </label>
                <div className="control">
                  <input
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o seu nome completo"
                    className="input"
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <label htmlFor="email" className="label">
                  E-mail do usuário
                </label>
                <div className="control">
                  <input
                    name="email"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o seu email"
                    className="input"
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <Columns className="no-padding mb-0">
                <Columns.Column size={6} className="mb-0">
                  <div className="field">
                    <label htmlFor="password" className="label">
                      Senha
                    </label>
                    <div className="control">
                      <input
                        name="password"
                        type="password"
                        ref={register({ required: true })}
                        className="input"
                        placeholder="********"
                      />
                      {errors.password && errors.password.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>

                <Columns.Column size={6} className="mb-0">
                  <div className="field">
                    <label htmlFor="password_confirmation" className="label">
                      Confirmação de senha
                    </label>
                    <div className="control">
                      <input
                        name="password_confirmation"
                        type="password"
                        ref={register({ required: true })}
                        className="input"
                        placeholder="********"
                      />
                      {errors.password_confirmation && errors.password_confirmation.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
              </Columns>

              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Nível de acesso
                  </label>
                  <select name="roles" ref={register({ required: true })} className="select">
                    <option value="" className="placeholder" selected disabled>Selecione o nível</option>
                    <option value="admin">Administrador</option>
                    <option value="representante">Representante</option>
                    <option value="escritorio">Escritório</option>
                    <option value="usuario">Cliente</option>
                  </select>
                  {errors.roles && errors.roles.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <div className="checkbox-button">
                    <label className="checkbox">
                      <input
                        name="active"
                        ref={register()}
                        type="checkbox"
                      /> <span>Usuário inativo</span></label>
                  </div>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </form>
            </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
