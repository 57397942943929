import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MdAttachMoney, MdTrendingUp, MdTrendingDown, MdMoneyOff, MdSearch, MdClear } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns } from 'react-bulma-components';
import { format } from 'date-fns'
import { useForm } from 'react-hook-form';
import GoalsAchieved from '../../components/Dashboard/GoalsAchieved';
import WorkDone from '../../components/Dashboard/WorkDone';
import GoogleMaps from '../../components/Dashboard/GoogleMaps';

import ReasonVisit from '../../components/Dashboard/ReasonVisit';
import SalesCity from '../../components/Dashboard/SalesCity';
import SalesType from '../../components/Dashboard/SalesType';
import ClientsMoreVisits from '../../components/Dashboard/ClientsMoreVisits';
import NumberVisitsDays from '../../components/Dashboard/NumberVisitsDays';

import api from '~/services/api';
import DatePicker, { registerLocale }  from "react-datepicker";
import pt from "date-fns/locale/pt";
registerLocale("pt", pt);

export default function Dashboard() {
  const inputRefStartDate = useRef();
  const inputRefEndDate = useRef();
  const date = new Date();
  const { register, setValue } = useForm();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  const [data, setData] = useState('');
  const [dataRep, setDataRep] = useState('');
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [user, setUser] = useState([]);
  const [userSeletedRep, setUserSeletedRep] = useState(false);
  const [userSeleted, setUserSeleted] = useState(null);

  const profile = useSelector((state) => state.user.profile);
  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  function convertToReal(number, options = {}) {
    const { moneySign = true } = options;
    if(Number.isNaN(number) || !number) return "need a number as the first parameter";
    if(typeof number === "string") {
      number = Number(number);
    }
    let res;
    const config = moneySign ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};
    moneySign
    ? res = number.toLocaleString('pt-BR', config)
    : res = number.toLocaleString('pt-BR', config)
    const needComma = number => number <= 1000;
    if(needComma(number)) {
      res = res.toString().replace(".", ",");
    }
    return res;
  }

  useEffect(() => {
    const getData = async () => {
      const date_start = format(new Date(startDate), 'yyyy-MM-dd');
      const date_end = format(new Date(endDate), 'yyyy-MM-dd');
      try {
        const response = await api.get(`dashboard/widgets/${date_start}/${date_end}/true/null`);
        setData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const fetchDataUser = async () => {
      setIsLoadingUser(true);
      try {
        const response = await api.get('/user/registers/all');
        setUser(response.data);
        setIsLoadingUser(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataUser();
  }, []);

  const selectUserRep = e => {
    setUserSeleted(e.target.value);
    // LoadInfoUserSeleted(e.target.value);
  };

  async function LoadInfoUserSeleted(user_id){
    const date_start = format(new Date(startDate), 'yyyy-MM-dd');
    const date_end = format(new Date(endDate), 'yyyy-MM-dd');
    try {
      const response = await api.get(`/dashboard/widgets/user-seleted/${date_start}/${date_end}/false/${user_id}`);
      setDataRep(response.data.data);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  async function handleUpdateDashboard(){
    const date_start = format(new Date(startDate), 'yyyy-MM-dd');
    const date_end = format(new Date(endDate), 'yyyy-MM-dd');
    try {
      const response = await api.get(`dashboard/widgets/${date_start}/${date_end}/false/${userSeleted}`);
      setData(response.data.data);
      await LoadInfoUserSeleted(userSeleted);
      if (userSeleted) {
        setUserSeletedRep(true);
      }
      
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  async function handleUpdateDashboardClear(){
    const date_start = format(new Date(new Date(date.getFullYear(), 0, 1)), 'yyyy-MM-dd');
    const date_end = format(new Date(new Date(date.getFullYear(), date.getMonth() + 1, 0)), 'yyyy-MM-dd');

    setUserSeleted(null);
    setUserSeletedRep(false);
    setValue('user_id', '');
    try {
      const response = await api.get(`dashboard/widgets/${date_start}/${date_end}/true/null`);
      console.log(response.data.data)
      setData(response.data.data);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  const dateStartWidegt = format(new Date(startDate), 'dd/MM/yyyy');
  const dateEndWidegt = format(new Date(endDate), 'dd/MM/yyyy');

  if (data) {
    return (
      <div className="w2d-container dashboard">
        <Columns>
          <Columns.Column size={5}>
            <div className="title-page">
              <h1>Dashboard</h1>
            </div>
          </Columns.Column>
          <Columns.Column size={7}>
            <div className="has-text-right filter-form">
              {admin ?
                <div className="filter-form-column">
                  <div className="field">
                    <div className="control">
                      <select name="user_id" ref={register} className="select user-dashboard-filter" onChange={selectUserRep}>
                        <option value="" className="placeholder">Selecione um representante</option>
                        { isLoadingUser ? (
                          <option>Carregando</option>
                        ) : (
                          user.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              : ''}
              <div className="filter-form-column">
                <div className="field">
                  <div className="control">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      locale="pt"
                      ref={inputRefStartDate}
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      className="input"
                      popperPlacement="top-right"
                    />
                  </div>
                </div>
              </div>
              <div className="filter-form-column">
                <div className="field">
                  <div className="control">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      locale="pt"
                      ref={inputRefEndDate}
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      className="input"
                      popperPlacement="top-right"
                    />
                  </div>
                </div>
              </div>
              <div className="filter-form-column">
                <button
                  className="button-filter-dashboard"
                  onClick={() => handleUpdateDashboard()}
                >
                  <MdSearch
                    size={24}
                    color="#ffffff"
                  />
                </button>
                <button
                  className="button-filter-dashboard clear"
                  onClick={() => handleUpdateDashboardClear()}
                >
                  <MdClear
                    size={24}
                    color="#ffffff"
                  /><span>Limpar</span>
                </button>
              </div>
            </div>
          </Columns.Column>
        </Columns>

        <Columns>
          <Columns.Column className="is-gapless">
            <div className="widget-dashboard">
              <h3>Novos clientes</h3>
              <div className="value">{data.total_customer.total_current_month}</div>
              { (data.total_customer.total_previous_month > data.total_customer.total_current_month) ? (
                <div className="status less">
                  <MdTrendingDown
                    size={32}
                    color="#ffffff"
                  />
                </div>
              ) : (
                <div className="status more">
                  <MdTrendingUp
                    size={32}
                    color="#ffffff"
                  />
                </div>
              )}
              <div className="text-month">Período anterior <span>{data.total_customer.total_previous_month}</span></div>
            </div>
          </Columns.Column>
          <Columns.Column className="is-gapless">
            <div className="widget-dashboard">
              <h3>Total de Vendas <span>(AP)</span></h3>
              <div className="value">{(!data.total_hect.total_hect_currency_month ? '0,00' : convertToReal(data.total_hect.total_hect_currency_month))}</div>
              { (data.total_hect.total_hect_currency_month > data.total_hect.total_hect_previous_month) ? (
                <div className="status more">
                  <MdTrendingUp
                    size={32}
                    color="#ffffff"
                  />
                </div>
              ) : (
                <div className="status less">
                  <MdTrendingDown
                    size={32}
                    color="#ffffff"
                  />
                </div>
              )}
              {(admin) ? 
                <div className="text-month">Meta <span>{(!data.total_hect.total_hect_previous_month ? '0,00' : convertToReal(data.total_hect.total_hect_previous_month))}</span></div>
              : ''}              
            </div>
          </Columns.Column>
          <Columns.Column className="is-gapless">
            <div className="widget-dashboard">
              <h3>Total de Vendas <span>(Máquinas)</span></h3>
              <div className="value">{(!data.total_sales.total_sales_currency_month ? '0,00' : convertToReal(data.total_sales.total_sales_currency_month))}</div>
              <div className="status sales">
                <MdAttachMoney
                  size={32}
                  color="#ffffff"
                />
              </div>
              <div className="text-month">Período anterior <span>{(!data.total_sales.total_sales_previous_month ? '0,00' : convertToReal(data.total_sales.total_sales_previous_month))}</span></div>
            </div>
          </Columns.Column>
        </Columns>

        <Columns>
          <Columns.Column className="is-gapless">
            <div className="widget-dashboard">
              <h2>Trabalhos realizados - Agricultura de Precisão</h2>
              <WorkDone data={data.graphics_products} />
            </div>
          </Columns.Column>
          <Columns.Column className="is-gapless">
            <div className="widget-dashboard">
              <h2>Metas atingidas - Vendas</h2>
              <GoalsAchieved data={data.graphics_goals} />
            </div>
          </Columns.Column>
        </Columns>

        <Columns>
          <Columns.Column className="is-gapless">
            <h2>Mótivo das visitas</h2>
            <div className="widget-dashboard margin-top">
                <ReasonVisit data={data.reason_visits} />
            </div>
          </Columns.Column>
          <Columns.Column className="is-gapless">
            <h2>Venda por cidade</h2>
            <div className="widget-dashboard margin-top">
                <SalesCity data={data.sales_cities} />
            </div>
          </Columns.Column>
          <Columns.Column className="is-gapless">
            <h2>Venda por tipo</h2>
            <div className="widget-dashboard margin-top">
                <SalesType data={data.sales_types} />
            </div>
          </Columns.Column>
        </Columns>


        <>
          {admin ?
            <Columns>
              {userSeletedRep ?
                <>
                  <Columns.Column size={4} className="is-gapless">
                    <h2>Clientes mais visitados</h2>
                    <div className="widget-dashboard margin-top">
                        <ClientsMoreVisits data={dataRep.customer_visits} />
                    </div>
                  </Columns.Column>

                  <Columns.Column size={8} className="is-gapless">
                    <h2>Número de visitas por dia do representante</h2>
                    <div className="widget-dashboard margin-top">
                        <NumberVisitsDays data={dataRep.visits_by_days} />
                    </div>
                  </Columns.Column>
                </>
              : ''}
            </Columns>
          : ''}
        </>


        <Columns>
          <Columns.Column className="is-gapless">
            <h2>Últimas vinte (20) cidades mais visitadas</h2>
            <div className="widget-dashboard margin-top">
              <GoogleMaps data={data} />
            </div>
          </Columns.Column>
        </Columns>
      </div>
    );
  } else {
    return (
      <div className="loading dashboard">
        <ImSpinner11
          size={24}
          color="#2a775a"
          className="fa-spin"
        /> Carregando... Aguarde :)
      </div>
    );
  }
}
