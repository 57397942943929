export function formserviceSaveRequest(data) {
  return {
    type: '@formservice/FORMSERVICE_SAVE_REQUEST',
    payload: { data },
  };
}

export function formserviceSaveSuccess(data) {
  return {
    type: '@formservice/FORMSERVICE_SAVE_SUCCESS',
    payload: { data },
  };
}

export function formserviceUpdateRequest(id, data) {
  return {
    type: '@formservice/FORMSERVICE_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function formserviceUpdateSuccess() {
  return {
    type: '@formservice/FORMSERVICE_UPDATE_SUCCESS',
  };
}

export function formserviceDeleteRequest(formservice_id) {
  return {
    type: '@formservice/DELETE_FORMSERVICE_REQUEST',
    payload: { formservice_id },
  };
}
