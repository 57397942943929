import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { settingUpdateSuccess } from './actions';

export function* settingUpdate({ payload }) {
  try {
    const response = yield call(api.put, `settings/1`, payload.data);
    toast.success('Configurações atualizadas com sucesso!');
    yield put(settingUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar as configurações.');
  }
}
export default all([
  takeLatest('@setting/SETTING_UPDATE_REQUEST', settingUpdate),
]);
