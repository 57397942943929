// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { default as NumberFormat } from 'react-number-format';
import { format } from 'date-fns'

import api from '~/services/api';
import MenuRegistrations from '~/components/MenuRegistrations';
import { customerSaveRequest } from '~/store/modules/customer/actions';

import DatePicker, { registerLocale }  from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
registerLocale("pt", pt); // register it with the name you want

export default function CustomerAdd() {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const [product, setProduct] = useState({});
  const [customer, setCustomer] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [user, setUser] = useState([]);
  const [typeCustomer, setTypeCustomer] = useState('0');
  const [typeSex, setTypeSex] = useState('o');

  const onChange = e => {
    setCustomer({...customer,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    data.cpf = customer.cpf;
    data.phone = customer.phone;
    data.type_customer = typeCustomer;
    data.sex = typeSex;
    data.date_birth = format(new Date(startDate), 'yyyy-MM-dd');
    dispatch(customerSaveRequest(data));
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('states');
        setStates(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  const findyCity = e => {
    const fetchCities = async () => {
      if (e.target.value !== '') {
        try {
          const response = await api.get(`cities/${e.target.value}`);
          setCities(response.data);
        } catch (error) {
          console.log('Error: '+error);
        }
      }
    };
    fetchCities();
  }

  useEffect(() => {
    const fetchDataUser = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/user/registers/all');
        setUser(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataUser();
  }, []);

  const onChangeType = (e) => {
    setTypeCustomer(e.target.value.toString());
  }
  
  const onChangeSex = (e) => {
    setTypeSex(e.target.value.toString());
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de cliente</li>
            </ul>
            <h1>Cadastro de cliente</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/customer">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>
              <input name="user_id" type="hidden" ref={register} defaultValue={profile.id}/>

              <div className="field">
                <label htmlFor="name" className="label">
                  Nome do cliente
                </label>
                <div className="control">
                  <input
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o nome do cliente"
                    className="input"
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div className="field">
                <label htmlFor="name" className="label">
                  Tipo de cliente
                </label>
                <div className="control">
                  <label htmlFor="customer_f" className="radio">
                    <input
                      id="customer_f"
                      type="radio"
                      value="0"
                      name="type_customer"
                      checked
                      onClick={onChangeType}
                    /> <span> Física </span>
                  </label>
                  <label htmlFor="customer_j" className="radio">
                    <input
                      id="customer_j"
                      type="radio"
                      value="1"
                      name="type_customer"
                      onClick={onChangeType}
                    /> <span> Jurídica </span>
                  </label>
                </div>
              </div>

              <div className="field">
                <label htmlFor="name" className="label">
                  Sexo
                </label>
                <div className="control">
                  <label htmlFor="sex_m" className="radio">
                    <input
                      id="sex_m"
                      type="radio"
                      value="m"
                      name="sex"
                      onClick={onChangeSex}
                    /> <span> Masculino </span>
                  </label>
                  <label htmlFor="sex_f" className="radio">
                    <input
                      id="sex_f"
                      type="radio"
                      value="f"
                      name="sex"
                      onClick={onChangeSex}
                    /> <span> Feminino </span>
                  </label>
                  <label htmlFor="sex_o" className="radio">
                    <input
                      id="sex_o"
                      type="radio"
                      value="o"
                      name="sex"
                      checked
                      onClick={onChangeSex}
                    /> <span> Outros </span>
                  </label>
                </div>
              </div>
              
              {typeCustomer === '0' ? (
                <div className="field">
                  <label htmlFor="cpf" className="label">
                    CPF
                  </label>
                  <div className="control">
                    <NumberFormat
                      className="input"
                      format="###.###.###-##"
                      mask="_"
                      placeholder="Informe o cpf do cliente"
                      onChange={onChange}
                      name="cpf"
                      ref={e => register({ name: "cpf" })}
                      onValueChange={(values) => {
                        const {value} = values;
                        setCustomer({...customer, cpf: value})}
                      }
                      getInputRef={(props) =>
                        <input name="cpf" type="text" ref={register({ required: true })} value={customer.cpf}/>
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="field">
                  <label htmlFor="cpf" className="label">
                    CNPJ
                  </label>
                  <div className="control">
                    <NumberFormat
                      className="input"
                      format="##.###.###/####-##"
                      mask="_"
                      placeholder="Informe o cnpj do cliente"
                      onChange={onChange}
                      name="cpf"
                      ref={e => register({ name: "cpf" })}
                      onValueChange={(values) => {
                        const {value} = values;
                        setCustomer({...customer, cpf: value})}
                      }
                      getInputRef={(props) =>
                        <input name="cpf" type="text" ref={register({ required: true })} value={customer.cpf}/>
                      }
                    />
                  </div>
                </div>
              )}

              <div className="field">
                <label htmlFor="phone" className="label">
                  Telefone
                </label>
                <div className="control">
                  <NumberFormat
                    className="input"
                    format="(##) #####-####"
                    mask="_"
                    placeholder="Informe o telefone do cliente"
                    onChange={onChange}
                    name="phone"
                    ref={e => register({ name: "phone" })}
                    onValueChange={(values) => {
                      const {value} = values;
                      setCustomer({...customer, phone: value})}
                    }
                    getInputRef={(props) =>
                      <input name="phone" type="text" ref={register({ required: true })} value={customer.phone}/>
                    }
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="date_birth" className="label">
                  Data de nascimento
                </label>
                <div className="control">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    className="input"
                    name="date_birth"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="hectares" className="label">
                  Área total (hectáres)
                </label>
                <div className="control">
                  <input
                    name="hectares"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe a quantidade de hectáres do cliente"
                    className="input"
                  />
                  {errors.hectares && errors.hectares.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div className="field">
                <label htmlFor="hectares_ap" className="label">
                  Área total de Agricultura de Precisão (hectáres)
                </label>
                <div className="control">
                  <input
                    name="hectares_ap"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe a quantidade de hectáres do cliente"
                    className="input"
                  />
                  {errors.hectares_ap && errors.hectares_ap.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Nível tecnológico do cliente
                  </label>
                  <select name="tech" ref={register({ required: true })} className="select">
                    <option value="" className="placeholder" selected disabled>Selecione o nível</option>
                    <option value="basico">Básico</option>
                    <option value="intermediario">Intermediário</option>
                    <option value="avancado">Avançado</option>
                  </select>
                  {errors.tech && errors.tech.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Estado
                  </label>
                  <select name="state_id" ref={register({ required: true })} className="select" onBlur={findyCity}>
                    <option value="" className="placeholder" selected disabled>Selecione um estado</option>
                    { isLoading ? (
                      <option>Carregando</option>
                    ) : (
                      states.map((value, index) => {
                        return <option value={value.id} key={index}>{value.title}</option>
                      })
                    )}
                  </select>
                  {errors.state_id && errors.state_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Cidade
                  </label>
                  <select name="city_id" ref={register({ required: true })} className="select">
                    <option value="" className="placeholder" selected disabled>Selecione uma cidade</option>
                    { isLoading ? (
                      <option>Carregando</option>
                    ) : (
                      cities.map((value, index) => {
                        return <option value={value.id} key={index}>{value.title}</option>
                      })
                    )}
                  </select>
                  {errors.city_id && errors.city_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div className="field">
                <label htmlFor="name" className="label">
                  Histórico
                </label>
                <div className="control">
                  <textarea
                    name="description"
                    ref={register}
                    placeholder="Informe o histórico"
                    className="textarea"
                  />
                </div>
              </div>
              {profile.roles[0].name === 'admin' ? (
                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Responsável pelo cliente
                    </label>
                    <select name="user_id" ref={register({ required: true })} className="select">
                      <option value="" className="placeholder">Selecione o responsável</option>
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        user.map((value, index) => {
                          return <option value={value.id} key={index}>{value.name}</option>
                        })
                      )}
                    </select>
                    {errors.user_id && errors.user_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <input name="user_id" type="hidden" ref={register} defaultValue={profile.id}/>
              )}


              <div className="field">
                <label htmlFor="new" className="label">
                  Cliente novo?
                </label>
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      value="true"
                      ref={register}
                      name="new"
                    /> Sim
                  </label>
                </div>
              </div>


              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
