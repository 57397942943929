import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Columns } from 'react-bulma-components';
import { useForm } from 'react-hook-form';

import bg from '~/assets/bg-login.jpg';
import logo from '~/assets/logo.png';
import { Imagem, FormAuth } from './styles';

import { forgotRequest } from '~/store/modules/auth/actions';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const { register, handleSubmit, errors } = useForm();

  function onSubmit(data) {
    dispatch(forgotRequest(data));
  }

  return (
    <Columns>
      <Columns.Column>
        <Imagem>
          <img src={bg} alt="" />
        </Imagem>
      </Columns.Column>
      <Columns.Column>
        <FormAuth>
          <div className="form-manager">
            <div className="logo-form">
              <img src={logo} alt="Ceres Agricultura de precisão" />
            </div>
            <div className="form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <label htmlFor="email" className="label">
                    E-mail
                  </label>
                  <div className="control">
                    <input
                      name="email"
                      type="email"
                      ref={register({ required: true })}
                      placeholder="Informe seu e-mail"
                      className="input"
                    />
                    {errors.email && errors.email.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <button type="submit" className="button is-primary">
                          {loading ? 'Enviando, aguarde ...' : 'Recuperar senha'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <Columns>
                  <Columns.Column>
                    <p className="forgot-password">
                      <Link to="/"> Voltar para o login </Link>
                    </p>
                  </Columns.Column>
                </Columns>
              </form>
            </div>
          </div>
          <div className="copyright">
            Todos os direitos reservados a Ceres Agricultura de Precisão.
          </div>
        </FormAuth>
      </Columns.Column>
    </Columns>
  );
}
