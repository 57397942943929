import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MdHome, MdSettings, MdNotifications, MdAccountCircle, MdExitToApp } from 'react-icons/md';
// eslint-disable-next-line import/no-unresolved
import logo from '~/assets/logo-header.png';
import { Container, Content, Profile } from './styles';

import { signOut } from '~/store/modules/auth/actions';

export default function Header() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  function UrlParameter() {
    const location = useLocation();
    const currentPath = location.pathname;
    const urlParam = currentPath.split("/");
    return urlParam[1];
  }

  const urlActive = UrlParameter();

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <Content>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <Link to="/dashboard">
            <img src={logo} alt="Ceres Agricultura de Precisão" />
          </Link>
          <Link to="/dashboard" className="link-header menu-icon">
            <MdHome size={30} color="#A3AEB7" />
          </Link>
          <NavLink to="/registrations/customer" className={`link-header ${ urlActive === 'registrations' ? ( 'active') : ('')} `}>
            Cadastros
          </NavLink>
          {(profile.roles[0].name !== 'escritorio') ? (
            <NavLink to="/application/list" className={`link-header ${ urlActive === 'application' ? ( 'active') : ('')} `}>
              Aplicações
            </NavLink>
          ) : ('') }
          {(profile.roles[0].name !== 'escritorio') ? (
            <NavLink to="/visits/list" className={`link-header ${ urlActive === 'visits' ? ( 'active') : ('')} `}>
              Visitas
            </NavLink>
          ) : ('') }
          {(admin || profile.roles[0].name === 'escritorio') ?
            <NavLink to="/maps/list" className={`link-header ${ urlActive === 'maps' ? ( 'active') : ('')} `}>
              Upload de Mapas
            </NavLink>
          :
            <NavLink to="/maps/list" className={`link-header ${ urlActive === 'maps' ? ( 'active') : ('')} `}>
              Mapas
            </NavLink>
          }
          <NavLink to="/sales/ap/list" className={`navbar-item has-dropdown is-hoverable link-header ${ urlActive === 'sales' ? ( 'active') : ('')} `}>
            Vendas
            <div className="navbar-dropdown">
              <NavLink to="/sales/ap/list" className={`${ urlActive === 'sales/ap' ? ( 'active') : ('')} `}>
                Agricultura de Precisão
              </NavLink>
              <NavLink to="/sales/machine/list" className={`${ urlActive === 'sales/machine' ? ( 'active') : ('')} `}>
                Máquinas
              </NavLink>
            </div>
          </NavLink>

          {(profile.roles[0].name === 'representante') ? (
            <NavLink to="/collections/ap" className={`link-header ${ urlActive === 'collections/ap' ? ( 'active') : ('')} `}>
              Coletas
            </NavLink>
          ) : ('') }

          {admin ?
            <>
              <NavLink to="/reports/list" className={`link-header ${ urlActive === 'reports' ? ( 'active') : ('')} `}>
                Relatórios
              </NavLink>
            </>
          : ''}
        </nav>

        <aside>
          <Profile>
            {admin ?
              <div className="icon-header-profile">
                <Link to="/settings/edit">
                  <MdSettings size={25} color="#D5D5D5" />
                </Link>
              </div>
            : ''}
            <div className="icon-header-profile">
              <Link to="/profile">
                <MdAccountCircle size={25} color="#D5D5D5" />
                <span>{profile.name}</span>
              </Link>
            </div>
          </Profile>
          <div className="icon-header-profile">
              <Link to="#" onClick={handleSignOut} >
                <MdExitToApp size={25} color="#D5D5D5" />
              </Link>
            </div>
        </aside>
      </Content>
    </Container>
  );
}
