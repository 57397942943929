import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { updateProfileSuccess, updateProfilefailure, userUpdateSuccess } from './actions';

export function* updateProfile({  id, payload }) {
  try {
    console.log(payload.data)
    const { name, email, file_id, ...rest } = payload.data;

    const profile = {
      name,
      email,
      file_id,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = yield call(api.put, `user/${payload.id}`, profile);

    toast.success('Perfil atualizado com sucesso!');

    yield put(updateProfileSuccess(response.data));
  } catch (error) {
    console.tron.log(error);
    toast.error('Erro ao atualizar Perfil');
    yield put(updateProfilefailure());
  }
}
// Manutenção de usuários
export function* userSave({ payload }) {
  try {
    const response = yield call(api.post, 'user', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* userUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `user/${payload.id}`, payload.data);
    toast.success('Uusário atualizado com sucesso!');
    yield put(userUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o usuário.');
  }
}

export function* userDelete({ payload }) {
  try {
    yield call(api.delete, `user/${payload.user_id}`);
    toast.success('Usuário inativado com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar o usuário.');
  }
}

export function* userActive({ payload }) {
  try {
    yield call(api.post, `user/active/${payload.user_id}`);
    toast.success('Usuário ativado com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar o usuário.');
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  // Manutenção de Usuários
  takeLatest('@user/USER_SAVE_REQUEST', userSave),
  takeLatest('@user/USER_UPDATE_REQUEST', userUpdate),
  takeLatest('@user/DELETE_USER_REQUEST', userDelete),
  takeLatest('@user/ACTIVE_USER_REQUEST', userActive),
]);
