import styled from 'styled-components';

export const SalesCityContainer = styled.div`
  .column.is-3{
    margin-top: 60px;
  }

  .align-column-center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .item {
      display: block;
    }
  }

  h3{
    color: #43425D !important;
    margin-top: 20px;
    position: relative;

    span{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      left: -20px;
      top: 4px;

      &.hit-target{
        background: #6496f9;
      }
      &.remaining{
        background: #eee;
      }
    }
  }

  .value{
    color: rgba(77,79,91,0.4) !important;
    font-size: 14px !important;
  }
`;
