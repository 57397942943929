// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdAddCircleOutline } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { format } from 'date-fns'

import api from '~/services/api';

function formatDate(value){
  const [yyyy, mm, dd] = value.split(/-/g);
  return `${dd}/${mm}/${yyyy}`
}
function DisplayFormatDate({ date }) {
  const dateFormat = useMemo(() => {
    return formatDate(date);
  }, [date]);

  return dateFormat;
}

export default function ApplicationView() {
  const [application, setApplication] = useState([]);
  const [applicationProducts, setApplicationProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const ViewApplication = () => {
    const { id } = useParams();
    return id;
  };
  const application_id = ViewApplication();

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`application/${application_id}`);
      setApplication(response.data.application);
      const applications_products = await api.get(`/application-products/show/${application_id}`);
      console.log(applications_products.data);
      setApplicationProducts(applications_products.data);
      setIsLoading(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Detalhes da aplicações</li>
            </ul>
            <h1 className="application">Cliente: <span>{application.customer_name}</span></h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/application/list">
            <Button className="button app-margin-right">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>

          <Link to="/application/create">
            <Button className="button is-primary">
              <MdAddCircleOutline
                size={15}
                color="#FFFFFF"
                className="icon-button-back"
              />
              Cadastrar aplicação
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={12} className="bg-content">
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <>
                <Columns className="header-table-application no-padding">
                  <Columns.Column size={4}>
                    <div className="title-line-1">Cliente: <strong>{application.customer_name}</strong></div>
                    <div className="title-line-2">Localização: <strong>{application.city_name} - {application.state_name}</strong></div>
                  </Columns.Column>
                  <Columns.Column size={4}>
                    <div className="title-line-1">Talhão: <strong>{application.field_name}</strong></div>
                  </Columns.Column>
                </Columns>

                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th>Produto</th>
                      <th>Forma de Aplicação</th>
                      <th>Cultura</th>
                      <th>Tipo</th>
                      <th>Porcentagem</th>
                      <th>Quantidade de HA</th>
                      <th>Responsável</th>
                      <th>Data Aplicação</th>
                      <th>Informações</th>
                    </tr>
                  </thead>
                  {applicationProducts.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="8" className="has-text-centered">Nenhum Registro encontrado :(</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {applicationProducts.map((value, index) => (
                        <tr key={value.id}>
                          <td>{value.product_name}</td>
                          <td>{value.form_applications_name}</td>
                          <td>{value.culture_name}</td>
                          {(value.type_correction === '1') && (value.type_maintenance === '1') ? (
                            <td>Correção e Manutenção</td>
                          ) : ('')}
                          {(value.type_correction === '1') && (value.type_maintenance === '0') ? (
                            <td>Correção</td>
                          ) : ('')}
                          {(value.type_correction === '0') && (value.type_maintenance === '1') ? (
                            <td>Manutenção</td>
                          ) : ('')}
                          <td>{value.percentage}%</td>
                          <td>{value.qtde_hectares}</td>
                          <td>{value.user_name}</td>
                          <td><DisplayFormatDate date={value.date_application.substr(0, 10)} /></td>
                          <td>{value.info}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th>Descrição da aplicação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{application.description}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
