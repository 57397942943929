import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Menu } from './styles';

export default function MenuRegistrations() {
  const profile = useSelector((state) => state.user.profile);
  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  const isActive = {
    fontWeight: "bold",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  };

  return (
    <Menu>
      <NavLink to="/registrations/customer" activeClassName="selected">Clientes</NavLink>
      <NavLink to="/registrations/field" activeClassName="selected">Talhão</NavLink>
      {(admin || profile.roles[0].name === 'escritorio') ?
        <>
          <NavLink to="/registrations/segment" activeClassName="selected">Segmento</NavLink>
          <NavLink to="/registrations/form-service" activeClassName="selected">Formas de Atendimento</NavLink>
          <NavLink to="/registrations/reason" activeClassName="selected">Motivo</NavLink>
          <NavLink to="/registrations/product" activeClassName="selected">Produto/Serviço</NavLink>
          <NavLink to="/registrations/product-application" activeClassName="selected">Produto para Aplicação</NavLink>
          <NavLink to="/registrations/form-application" activeClassName="selected">Forma de Aplicação</NavLink>
          <NavLink to="/registrations/culture" activeClassName="selected">Cultura</NavLink>
          <NavLink to="/registrations/brand" activeClassName="selected">Marcas</NavLink>
          <NavLink to="/registrations/user" activeClassName="selected">Usuário</NavLink>
        </>
      : ''}
      {/* <NavLink to="/registrations/delivery" activeClassName="selected">Entregas</NavLink> */}
    </Menu>
  );
}
