// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdCheck } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { default as NumberFormat } from 'react-number-format';

import api from '~/services/api';
import MenuConfig from '~/components/MenuConfig';
import { settingUpdateRequest } from '~/store/modules/setting/actions';

export default function ProductAdd() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [setting, setSetting] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/settings`);
        setSetting(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };
    fetchData();
  }, []);

  const onChange = e => {
    setSetting({...setting,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    data.phone = setting.phone;
    dispatch(settingUpdateRequest(data));
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Configurações</li>
            </ul>
            <h1>Configurações</h1>
          </div>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuConfig />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <form className="manager" onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <label htmlFor="name" className="label">
                    E-mail de contato
                  </label>
                  <div className="control">
                    <input
                      name="email"
                      type="text"
                      ref={register()}
                      placeholder="Informe o e-mail geral do sistema"
                      className="input"
                      defaultValue={setting.email}
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="price" className="label">
                    Telefone de contato
                  </label>
                  <div className="control">
                    <NumberFormat
                      className="input"
                      placeholder="Informe o telefone de contato"
                      mask="_"
                      format={"(##) ####-####"}
                      value={setting.phone}
                      onValueChange={(values) => {
                        const {value} = values;
                        setSetting({...setting, phone: value})}
                      }
                      getInputRef={(props) =>
                        <input name="phone" type="text" ref={register({ required: true })} value={setting.phone}/>
                      }
                    />
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="price" className="label">
                    Comissão Agricultura de Precisão em %
                  </label>
                  <div className="control">
                    <input
                      name="commission_ag"
                      type="text"
                      ref={register()}
                      placeholder="Informe a comissão para agricultura de precisão"
                      className="input"
                      defaultValue={setting.commission_ag}
                    />
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="price" className="label">
                    Comissão Implementos Agrícolas em %
                  </label>
                  <div className="control">
                    <input
                      name="commission_ia"
                      type="text"
                      ref={register()}
                      placeholder="Informe a comissão para implementos agrícolas"
                      className="input"
                      defaultValue={setting.commission_ia}
                    />
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="price" className="label">
                    Comissão Tecnologia em %
                  </label>
                  <div className="control">
                    <input
                      name="commission_te"
                      type="text"
                      ref={register()}
                      placeholder="Informe a comissão para tecnologia"
                      className="input"
                      defaultValue={setting.commission_te}
                    />
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control has-text-right">
                        <button type="submit" className="button is-primary">
                          <MdCheck
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          />
                          Salvar dados
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
