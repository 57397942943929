// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import MenuRegistrations from '~/components/MenuRegistrations';
import { fieldSaveRequest } from '~/store/modules/field/actions';

export default function FieldAdd() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState([]);

  function onSubmit(data) {
    dispatch(fieldSaveRequest(data));
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('customer/registers/all');
        setCustomer(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de talhão</li>
            </ul>
            <h1>Cadastro de talhão</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/field">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Cliente
                  </label>
                  <select name="customer_id" ref={register({ required: true })} className="select">
                    <option value="" className="placeholder">Selecione um cliente</option>
                    { isLoading ? (
                      <option>Carregando</option>
                    ) : (
                      customer.map((value, index) => {
                        return <option value={value.id} key={index}>{value.name}</option>
                      })
                    )}
                  </select>
                  {errors.customer_id && errors.customer_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <label htmlFor="name" className="label">
                  Nome do talhão
                </label>
                <div className="control">
                  <input
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o nome do talhão"
                    className="input"
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <label htmlFor="name" className="label">
                  Quantidade de hectares
                </label>
                <div className="control">
                  <input
                    name="qtde_hectares"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe a quantidade de hectares"
                    className="input"
                  />
                  {errors.qtde_hectares && errors.qtde_hectares.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <label htmlFor="registration" className="label">
                  Matrícula da propriedade
                </label>
                <div className="control">
                  <input
                    name="registration"
                    type="text"
                    ref={register()}
                    placeholder="Informe a matrícula do cliente"
                    className="input"
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="name" className="label">
                  Produtividade do talhão em números
                </label>
                <div className="control">
                  <textarea
                    name="number_productivity"
                    ref={register}
                    placeholder="Informe os dados de produtividade do talhão"
                    className="textarea"
                  />
                </div>
              </div>
              <div className="field">
                <label htmlFor="name" className="label">
                  Histórico
                </label>
                <div className="control">
                  <textarea
                    name="description"
                    ref={register}
                    placeholder="Informe o histórico"
                    className="textarea"
                  />
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
