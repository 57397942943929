// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import InputColor from 'react-input-color';

import MenuRegistrations from '~/components/MenuRegistrations';
import api from '~/services/api';
import { productUpdateRequest } from '~/store/modules/product/actions';

export default function ProductEdit() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [color, setColor] = useState([]);

  const EditProduct = () => {
    const { id } = useParams();
    return id;
  };
  const product_id = EditProduct();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`/product/${product_id}`);
        setProduct(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };
    fetchData();
  }, []);


  function handleChangeColor(color){
    setColor(color);
  }

  function onSubmit(data) {
    const object = { ...data };
    object.color = color.hex;
    dispatch(productUpdateRequest(product_id, object));
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Atualizar produto/serviço</li>
            </ul>
            <h1>Atualizar produto/serviço</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/product">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <form className="manager" onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <label htmlFor="name" className="label">
                    Nome do produto/serviço
                  </label>
                  <div className="control">
                    <input
                      name="name"
                      type="text"
                      ref={register({ required: true })}
                      placeholder="Informe o nome do produto/serviço"
                      className="input"
                      defaultValue={product.name}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="color" className="label">
                    Cor do produto/serviço
                  </label>
                  <div className="control">
                    <InputColor
                      initialValue={product.color}
                      onChange={handleChangeColor}
                      placement="right"
                      className="input"
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="name" className="label">
                    Detalhes do produto/serviço
                  </label>
                  <div className="control">
                    <textarea
                      name="description"
                      ref={register}
                      placeholder="Informe os detalhes do produto/serviço"
                      className="textarea"
                      defaultValue={product.description}
                    />
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control has-text-right">
                        <button type="submit" className="button is-primary">
                          <MdCheck
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          />
                          Salvar dados
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
