export function productApplicationSaveRequest(data) {
  return {
    type: '@productApplication/PRODUCT_APPLICATION_SAVE_REQUEST',
    payload: { data },
  };
}

export function productApplicationSaveSuccess(data) {
  return {
    type: '@productApplication/PRODUCT_APPLICATION_SAVE_SUCCESS',
    payload: { data },
  };
}

export function productApplicationUpdateRequest(id, data) {
  return {
    type: '@productApplication/PRODUCT_APPLICATION_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function productApplicationUpdateSuccess() {
  return {
    type: '@productApplication/PRODUCT_APPLICATION_UPDATE_SUCCESS',
  };
}

export function productApplicationDeleteRequest(product_application_id) {
  return {
    type: '@productApplication/DELETE_PRODUCT_APPLICATION_REQUEST',
    payload: { product_application_id },
  };
}
