import React, { useState, useEffect, useRef } from 'react';
import { Columns } from 'react-bulma-components';
import { RiFilterOffFill } from 'react-icons/ri';
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";
import { format } from 'date-fns'

import api from '~/services/api';
import DatePicker from "react-datepicker";

const ModalReportsManagerApplication = props => {
  const inputRefCustomer = useRef();
  const inputRefProduct = useRef();
  const inputRefCulture = useRef();
  const inputRefFormApplication = useRef();
  const inputRefUser = useRef();
  const inputRefCorrecaoMinus100 = useRef();
  const inputRefOnlyCorrecao = useRef();
  const inputRefOnlyManutencao = useRef();

  const [isLoadingPDF, setIsLoadingPDF] = useState(true);
  const [isLoadingXLS, setIsLoadingXLS] = useState(true);
  const [isLoadingSelects, setIsLoadingSelects] = useState(true);

  const [filter, setFilter] = useState({});
  const [customer, setCustomer] = useState([]);
  const [product, setProduct] = useState([]);
  const [culture, setCulture] = useState([]);
  const [formApplication, setFormApplication] = useState([]);
  const [user, setUser] = useState([]);
  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  const fetchDataCustomer = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('customer/registers/all');
      setCustomer(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchDataProductApplication = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/product-application/registers/all');
      setProduct(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchDataUser = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/user/registers/all');
      setUser(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchFormApplication = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/form-application/registers/all');
      setFormApplication(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchCulture = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/culture/registers/all');
      setCulture(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  useEffect(() => {
    fetchDataCustomer();
    fetchDataProductApplication();
    fetchDataUser();
    fetchFormApplication();
    fetchCulture();
  }, []);

  function cleanInputsFilter() {
    inputRefCustomer.current.value = '';
    inputRefProduct.current.value = '';
    inputRefFormApplication.current.value = '';
    inputRefCorrecaoMinus100.current.value = '';
    inputRefOnlyCorrecao.current.value = '';
    inputRefOnlyManutencao.current.value = '';
    inputRefUser.current.value = '';
    inputRefCulture.current.value = '';
    setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  }

  const handleSubmit = (event, type) => {
    if (type === 'pdf'){
      setIsLoadingPDF(false);
    } else {
      setIsLoadingXLS(false);
    }
    event.preventDefault();

    setFilter({
      params: {
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        customer_id: inputRefCustomer.current.value,
        product_id: inputRefProduct.current.value,
        form_application_id: inputRefFormApplication.current.value,
        culture_id: inputRefCulture.current.value,
        user_id: inputRefUser.current.value,
        correcaoMinus100: inputRefCorrecaoMinus100.current.checked,
        onlyCorrecao: inputRefOnlyCorrecao.current.checked,
        onlyManutencao: inputRefOnlyManutencao.current.checked,
      }
    });
    if (type === 'pdf'){
      api.get(`/reports/applications`, {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          customer_id: inputRefCustomer.current.value,
          product_id: inputRefProduct.current.value,
          form_application_id: inputRefFormApplication.current.value,
          culture_id: inputRefCulture.current.value,
          user_id: inputRefUser.current.value,
          total_visits: inputRefCorrecaoMinus100.current.checked,
          onlyCorrecao: inputRefOnlyCorrecao.current.checked,
          onlyManutencao: inputRefOnlyManutencao.current.checked,
          type: type,
        },
        responseType: 'blob'
      }).then(response => {
        setIsLoadingPDF(true);
        const file = new Blob([response.data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    } else {
      api.get(`/reports/applications`, {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          customer_id: inputRefCustomer.current.value,
          product_id: inputRefProduct.current.value,
          form_application_id: inputRefFormApplication.current.value,
          culture_id: inputRefCulture.current.value,
          user_id: inputRefUser.current.value,
          total_visits: inputRefCorrecaoMinus100.current.checked,
          onlyCorrecao: inputRefOnlyCorrecao.current.checked,
          onlyManutencao: inputRefOnlyManutencao.current.checked,
          type: type,
        },
        responseType: 'blob'
      }).then(response => {
        setIsLoadingXLS(true);
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Aplicacoes.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  }

  return (
    <>
      <div className={props.show ? 'report modal is-active' : 'report modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Relatório de aplicações</p>
            <button
              onClick={props.onClose}
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <Columns>
              <Columns.Column>
                <Columns>
                  <form className="manager filter">
                    <Columns>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Filtro de cliente
                            </label>
                            <select name="customer_id" ref={inputRefCustomer} className="select">
                              <option value="" className="placeholder">Todos os Cliente</option>
                              { isLoadingSelects ? (
                                <option>Carregando</option>
                              ) : (
                                customer.map((value, index) => {
                                  return <option value={value.id} key={index}>{value.name}</option>
                                })
                              )}
                            </select>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Filtro de produto
                            </label>
                            <select name="customer_id" ref={inputRefProduct} className="select">
                              <option value="" className="placeholder">Todos os produtos</option>
                              { isLoadingSelects ? (
                                <option>Carregando</option>
                              ) : (
                                product.map((value, index) => {
                                  return <option value={value.id} key={index}>{value.name}</option>
                                })
                              )}
                            </select>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Filtro de cultura
                            </label>
                            <select name="reason_id" ref={inputRefCulture} className="select">
                              <option value="" className="placeholder">Todos as culturas</option>
                              { isLoadingSelects ? (
                                <option>Carregando</option>
                              ) : (
                                culture.map((value, index) => {
                                  return <option value={value.id} key={index}>{value.name}</option>
                                })
                              )}
                            </select>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Filtro de aplicação
                            </label>
                            <select name="form_services_id" ref={inputRefFormApplication} className="select">
                              <option value="" className="placeholder">Todos os formas de aplicação</option>
                              { isLoadingSelects ? (
                                <span>Carregando</span>
                              ) : (
                                formApplication.map((value, index) => {
                                  return <option value={value.id} key={index}>{value.name}</option>
                                })
                              )}
                            </select>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Filtro de responsável
                            </label>
                            <select name="user_id" ref={inputRefUser} className="select">
                              <option value="" className="placeholder">Todos os Responsável</option>
                              { isLoadingSelects ? (
                                <option>Carregando</option>
                              ) : (
                                user.map((value, index) => {
                                  return <option value={value.id} key={index}>{value.name}</option>
                                })
                              )}
                            </select>
                          </div>
                        </div>
                      </Columns.Column>

                      <Columns.Column size={12}>
                        <div className="field inline">
                          <div className="field">
                            <div className="control">
                              <label htmlFor="name" className="label">
                                Data Inicial
                              </label>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className="input"
                                popperPlacement="top-right"
                              />
                            </div>
                          </div>
                          <span className="between-dates"></span>
                          <div className="field">
                            <div className="control">
                              <label htmlFor="name" className="label">
                                Data Final
                              </label>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                className="input"
                                popperPlacement="top-right"
                              />
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="new" className="label">
                              Mostrar correção menor 100% ?
                            </label>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                value="true"
                                ref={inputRefCorrecaoMinus100}
                                name="total_visits"
                              /> Sim
                            </label>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="new" className="label">
                              Somente Correção?
                            </label>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                value="true"
                                ref={inputRefOnlyCorrecao}
                                name="km_inicial"
                              /> Sim
                            </label>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="new" className="label">
                              Somente Manutenção?
                            </label>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                value="true"
                                ref={inputRefOnlyManutencao}
                                name="km_inicial"
                              /> Sim
                            </label>
                          </div>
                        </div>
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column>
                        <div className="button-clean" onClick={cleanInputsFilter} >
                          <RiFilterOffFill
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          /> Limpar filtros
                        </div>
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column size={6}>
                        <div className="field is-horizontal">
                          <div className="field-body">
                            <div className="field">
                              <div className="control">
                                <button onClick={e => handleSubmit(e,'xls')} className="button is-info">
                                  <AiOutlineFileExcel
                                    size={15}
                                    color="#FFFFFF"
                                    className="icon-button-back"
                                  />
                                  {isLoadingXLS ? 'Gerar Excel' : 'Carregando, aguarde!'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={6}>
                        <div className="field is-horizontal">
                          <div className="field-body">
                            <div className="field">
                              <div className="control has-text-right">
                                <button onClick={e => handleSubmit(e,'pdf')} className="button is-info">
                                  <AiOutlineFilePdf
                                    size={15}
                                    color="#FFFFFF"
                                    className="icon-button-back"
                                  />
                                  {isLoadingPDF ? 'Gerar PDF' : 'Carregando, aguarde!'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                    </Columns>

                  </form>
                </Columns>
              </Columns.Column>
            </Columns>
          </section>
        </div>
      </div>
    </>
  )
}

export default ModalReportsManagerApplication;
