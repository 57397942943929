import React from 'react';
import { useDispatch } from 'react-redux';

export default function SignUp() {
  const dispatch = useDispatch();

  return (
    <h1>Cadastre-se</h1>
  );
}
