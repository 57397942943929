import React from 'react';

import { FooterBg } from './styles';

export default function Footer() {
  return (
    <FooterBg>
      <p>Todos os direitos reservados a Ceres Agricultura de Precisão</p>
    </FooterBg>
  );
}
