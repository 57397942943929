export function productSaveRequest(data) {
  return {
    type: '@product/PRODUCT_SAVE_REQUEST',
    payload: { data },
  };
}

export function productSaveSuccess(data) {
  return {
    type: '@product/PRODUCT_SAVE_SUCCESS',
    payload: { data },
  };
}

export function productUpdateRequest(id, data) {
  return {
    type: '@product/PRODUCT_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function productUpdateSuccess() {
  return {
    type: '@product/PRODUCT_UPDATE_SUCCESS',
  };
}

export function productDeleteRequest(product_id) {
  return {
    type: '@product/DELETE_PRODUCT_REQUEST',
    payload: { product_id },
  };
}
