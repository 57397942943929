export function farmSaveRequest(data) {
  return {
    type: '@farm/FARM_SAVE_REQUEST',
    payload: { data },
  };
}

export function farmSaveSuccess(data) {
  return {
    type: '@farm/FARM_SAVE_SUCCESS',
    payload: { data },
  };
}

export function farmUpdateRequest(id, data) {
  return {
    type: '@farm/FARM_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function farmUpdateSuccess() {
  return {
    type: '@farm/FARM_UPDATE_SUCCESS',
  };
}

export function farmDeleteRequest(farm_id) {
  return {
    type: '@farm/DELETE_FARM_REQUEST',
    payload: { farm_id },
  };
}
