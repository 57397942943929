// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import { StyledDropzoneContainer, StyledDropzone } from './styles';

import api from '~/services/api';
import MenuMaps from '~/components/MenuMaps';

export default function MapsAdd() {
  const profile = useSelector(state => state.user.profile);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(true);
  const [isLoadingField, setIsLoadingField] = useState(true);
  const {register, handleSubmit, errors, control, watch } = useForm();
  const [fieldMaps, setFieldMaps] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [user, setUser] = useState([]);
  const [map, setMaps] = useState([]);
  const [files, setFiles] = useState({});
  const [fields, setFields] = useState([{ field_id:null }]);

  // Add fields dynamics
  function handleAdd() {
    const values = [...fields];
    values.push({ field_id:null });
    setFields(values);
  }
  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }
  const handleChangeFields = (i, event) => {
    const values = [...fields];
    values[i].field_id = event.target.value;
    setFields(values);
    setMaps({...map,
      fields: values
    });
  }

  const [fileNames, setFileNames] = useState([]);
  const handleDrop = acceptedFiles => {
    setFileNames(acceptedFiles.map(file => file.name));
    setFiles(acceptedFiles.map(file => file));
  }

  const onChange = e => {
    setMaps({...map,
      [e.target.name]: e.target.value}
    );
  };

  async function onSubmit(data) {
    data.customer_id = map.customer_id;
    data.fields = map.fields;

    try {
      const response = await api.post('maps', data);
      const { id } = response.data;

      files.forEach(async (file) => {
        const formData = new FormData();
        formData.append('maps_uploads', file);
        formData.append('customer_id', data.customer_id);
        formData.append('map_id', id);

        const response_files = await api.post('maps_files', formData);

      });

      toast.success('Mapa cadastrado com sucesso!');

    } catch (error) {

      toast.error('Não foi possível salvar os dados.');

    }
  }

  useEffect(() => {
    const fetchDataCustomer = async () => {
      setIsLoadingCustomer(true);
      try {
        const response = await api.get('/customer/registers/all');
        setCustomer(response.data);
        setIsLoadingCustomer(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataCustomer();
  }, []);

  const fetchDataField = async (e) => {
    setIsLoadingField(true);
    try {
      const response = await api.get(`field/find-by-customer/${e.target.value}`);
      setFieldMaps(response.data);
      setIsLoadingField(false);
      setFields([{ field_id:null }]);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  useEffect(() => {
    const fetchDataUser = async () => {
      setIsLoadingUser(true);
      try {
        const response = await api.get('/user/registers/all');
        setUser(response.data);
        setIsLoadingUser(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataUser();
  }, []);

  return (
    <div className="w2d-container">
      <Columns>
      <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Upload de mapas</li>
            </ul>
            <h1>Upload de mapas</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/maps/list">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuMaps />
          </Columns.Column>

          <Columns.Column size={9} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>
              <Columns className="no-padding">
                <Columns.Column size={12} className="no-padding">
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Cliente
                      </label>
                      <select name="customer_id" ref={register({ required: true })} className="select" onChange={onChange} onBlur={fetchDataField}>
                        <option defaultValue="" className="placeholder">Selecione um cliente</option>
                        { isLoadingCustomer ? (
                          <option>Carregando</option>
                        ) : (
                          customer.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.customer_id && errors.customer_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
              </Columns>

              {/* Código de replicação */}
              <>
                {fields.map((field, idx) => {
                  return (
                    <Columns className="no-padding" key={`${field}-${idx}`}>
                      <Columns.Column size={12} className="no-padding">
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Talhão
                            </label>
                            <select name={`field_id-${idx}`} ref={register({ required: true })} className="select" onBlur={e => handleChangeFields(idx, e)} >
                              <option defaultValue="" className="placeholder">Selecione o talhão</option>
                              { isLoadingField ? (
                                <option>Carregando</option>
                              ) : (
                                fieldMaps.map((value, index) => {
                                  return <option value={value.id} key={index}>{value.name}</option>
                                })
                              )}
                            </select>
                            {errors.field_id && errors.field_id.type === 'required' && (
                              <span className="error-inputs">
                                Este campo é obrigatório
                              </span>
                            )}
                          </div>
                        </div>
                      </Columns.Column>
                      <div className="action">
                        <button type="button" className="button is-danger no-padding" onClick={() => handleRemove(idx)}>Remover talhão</button>
                      </div>
                    </Columns>
                  );
                })}
                <Columns className="no-padding">
                  <Columns.Column size={12} className="no-padding">
                    <div className="action-right">
                      <button type="button" className="button is-primary no-padding" onClick={() => handleAdd()}>Adicionar campos</button>
                    </div>
                  </Columns.Column>
                </Columns>
              </>
              <br />

              <Columns className="no-padding">
                <Columns.Column size={12} className="no-padding">
                  <div className="field">
                    <div className="control">
                      <label htmlFor="year" className="label">
                        Ano do Mapa
                      </label>
                      <div className="control">
                      <select name="year" ref={register({ required: true })} className="select" onChange={onChange} >
                        <option defaultValue="" className="placeholder">Selecione um ano</option>
                        <option defaultValue="2019">2019</option>
                        <option defaultValue="2020">2020</option>
                        <option defaultValue="2021">2021</option>
                        <option defaultValue="2022">2022</option>
                        <option defaultValue="2023">2023</option>
                        <option defaultValue="2024">2024</option>
                        <option defaultValue="2025">2025</option>
                        <option defaultValue="2026">2026</option>
                        <option defaultValue="2027">2027</option>
                        <option defaultValue="2028">2028</option>
                        <option defaultValue="2029">2029</option>
                        <option defaultValue="2030">2030</option>

                      </select>
                      </div>
                    </div>
                    {errors.year && errors.year.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </Columns.Column>
              </Columns>

              <Columns className="no-padding">
                <Columns.Column size={12} className="no-padding">
                  <StyledDropzoneContainer>
                    <StyledDropzone>
                      <Dropzone onDrop={handleDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <p>Arraste e solte os arquivos ou clique para selecioná-los</p>
                          </div>
                        )}
                      </Dropzone>
                    </StyledDropzone>
                    <div>
                      <h4>Arquivos importados:</h4>
                      <ul>
                        {fileNames.map(fileName => (
                          <li key={fileName}>{fileName}</li>
                        ))}
                      </ul>
                    </div>
                  </StyledDropzoneContainer>
                </Columns.Column>
              </Columns>


              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Responsável pelo mapa
                  </label>
                  <select name="user_id" ref={register({ required: true })} className="select">
                    <option value="" className="placeholder">Selecione o responsável</option>
                    { isLoadingUser ? (
                      <option>Carregando</option>
                    ) : (
                      user.map((value, index) => {
                        return <option value={value.id} key={index}>{value.name}</option>
                      })
                    )}
                  </select>
                  {errors.user_id && errors.user_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>


              <Columns className="no-padding">
                <Columns.Column size={12} className="no-padding">
                  <div className="field">
                    <label htmlFor="description" className="label">
                      Descrição do mapa
                    </label>
                    <div className="control">
                      <textarea
                        name="description"
                        ref={register}
                        placeholder="Informações sobre a aplicação"
                        className="textarea"
                      />
                    </div>
                  </div>
                </Columns.Column>
              </Columns>

              <Columns className="no-padding">
                <Columns.Column size={12} className="no-padding">
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <div className="control has-text-right">
                          <button type="submit" className="button is-primary">
                            <MdCheck
                              size={15}
                              color="#FFFFFF"
                              className="icon-button-back"
                            />
                            Salvar dados
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Columns.Column>
              </Columns>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
