import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import segment from './segment/reducer';
import reason from './reason/reducer';
import formservice from './formservice/reducer';
import product from './product/reducer';
import customer from './customer/reducer';
import farm from './farm/reducer';
import field from './field/reducer';
import delivery from './delivery/reducer';
import visit from './visit/reducer';
import application from './application/reducer';
import setting from './setting/reducer';
import goal from './goal/reducer';
import maps from './maps/reducer';
import sales from './sales/reducer';
import brand from './brand/reducer';
import formapplication from './formapplication/reducer';
import productapplication from './productapplication/reducer';
import culture from './culture/reducer';

const reducers = combineReducers({
  auth,
  user,
  segment,
  reason,
  formservice,
  product,
  customer,
  farm,
  field,
  delivery,
  visit,
  application,
  setting,
  goal,
  maps,
  sales,
  brand,
  formapplication,
  productapplication,
  culture,
});

export default reducers;
