// eslint-disable-next-line import/no-unresolved
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdHome } from 'react-icons/md';
import { FcLineChart, FcRadarPlot, FcTodoList, FcCurrencyExchange, FcCollaboration, FcSalesPerformance, FcMindMap, FcComboChart, FcPositiveDynamic, FcBusinessman, FcCollect, FcDonate, FcSportsMode, FcMoneyTransfer, FcVoicePresentation, FcBiotech } from "react-icons/fc";
import { Columns } from 'react-bulma-components';

import ModalReports from '../../components/ModalReports'

export default function ReportsList() {
  // const [modalServices, setModalServices] = useState(false);
  const [modalReason, setModalReason] = useState(false);
  const [modalCityVisit, setModalCityVisit] = useState(false);
  // const [modalTypeServices, setModalTypeServices] = useState(false);
  const [modalFormServices, setModalFormServices] = useState(false);
  const [modalSalesServicesCity, setModalSalesServicesCity] = useState(false);

  const [modalManagerHecByServices, setModalManagerHecByServices] = useState(false);
  const [modalManagerMediaPriceServices, setModalManagerMediaPriceServices] = useState(false);
  const [modalManagerServicesTop, setModalManagerServicesTop] = useState(false);
  const [modalManagerCustomer, setModalManagerCustomer] = useState(false);
  const [modalManagerApplication, setModalManagerApplication] = useState(false);
  const [modalManagerSales, setModalManagerSales] = useState(false);
  const [modalManagerTarget, setModalManagerTarget] = useState(false);
  const [modalManagerMachineUsed, setModalManagerMachineUsed] = useState(false);
  const [modalManagerVisits, setModalManagerVisits] = useState(false);
  const [modalManagerSalesBilling, setModalManagerSalesBilling] = useState(false);

  return (
    <div className="w2d-container">
      {/* {modalServices ?
        <ModalReports onClose={() => setModalServices(false)} show={modalServices} type="ServicesByYear"/>
      : ''} */}

      {modalReason ?
        <ModalReports onClose={() => setModalReason(false)} show={modalReason} type="AgentReason"/>
      : ''}
      {modalCityVisit ?
        <ModalReports onClose={() => setModalCityVisit(false)} show={modalCityVisit} type="AgentCityVisit"/>
      : ''}
      {/* {modalTypeServices ?
        <ModalReports onClose={() => setModalTypeServices(false)} show={modalTypeServices} type="AgentTypeServices"/>
      : ''} */}
      {modalFormServices ?
        <ModalReports onClose={() => setModalFormServices(false)} show={modalFormServices} type="AgentFormServices"/>
      : ''}
      {modalSalesServicesCity ?
        <ModalReports onClose={() => setModalSalesServicesCity(false)} show={modalSalesServicesCity} type="AgentSalesServicesCity"/>
      : ''}

      {modalManagerHecByServices ?
        <ModalReports onClose={() => setModalManagerHecByServices(false)} show={modalManagerHecByServices} type="ManagerHecByServices"/>
      : ''}
      {modalManagerMediaPriceServices ?
        <ModalReports onClose={() => setModalManagerMediaPriceServices(false)} show={modalManagerMediaPriceServices} type="ManagerMediaPriceServices"/>
      : ''}
      {modalManagerServicesTop ?
        <ModalReports onClose={() => setModalManagerServicesTop(false)} show={modalManagerServicesTop} type="ManagerServicesTop"/>
      : ''}
      {modalManagerCustomer ?
        <ModalReports onClose={() => setModalManagerCustomer(false)} show={modalManagerCustomer} type="ManagerCustomer"/>
      : ''}
      {modalManagerApplication ?
        <ModalReports onClose={() => setModalManagerApplication(false)} show={modalManagerApplication} type="ManagerApplication"/>
      : ''}
      {modalManagerSales ?
        <ModalReports onClose={() => setModalManagerSales(false)} show={modalManagerSales} type="ManagerSales"/>
      : ''}
      
      {modalManagerSalesBilling ?
        <ModalReports onClose={() => setModalManagerSalesBilling(false)} show={modalManagerSalesBilling} type="ManagerSalesBilling"/>
      : ''}
      {/* {modalManagerTarget ?
        <ModalReports onClose={() => setModalManagerTarget(false)} show={modalManagerTarget} type="ManagerTarget"/>
      : ''} */}
      {modalManagerMachineUsed ?
        <ModalReports onClose={() => setModalManagerMachineUsed(false)} show={modalManagerMachineUsed} type="ManagerMachineUsed"/>
      : ''}
      {modalManagerVisits ?
        <ModalReports onClose={() => setModalManagerVisits(false)} show={modalManagerVisits} type="ManagerVisits"/>
      : ''}

      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Relatórios</li>
            </ul>
            <h1>Lista de relatórios</h1>
          </div>
        </Columns.Column>
      </Columns>
      <br /><br />

      {/* <Columns className="grid-reports-title">
        <p>Serviços</p>
        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalServices(true)}>
            <div>
              <FcLineChart size={40} />
            </div>
            <div>
              Serviços prestados no ano
            </div>
          </a>
        </Columns.Column>
      </Columns> */}

      <Columns className="grid-reports-title">
        <p>Vendedor</p>
        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalReason(true)}>
            <div>
              <FcTodoList size={40} />
            </div>
            <div>
              Motivo
            </div>
          </a>
        </Columns.Column>

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalCityVisit(true)}>
            <div>
              <FcRadarPlot size={40} />
            </div>
            <div>
              Cidades visitadas
            </div>
          </a>
        </Columns.Column>

        {/* <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalTypeServices(true)}>
            <div>
              <FcCurrencyExchange size={40} />
            </div>
            <div>
              Tipo de serviços oferecidos
            </div>
          </a>
        </Columns.Column> */}

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalFormServices(true)}>
            <div>
              <FcCollaboration size={40} />
            </div>
            <div>
              Porcentagem forma de contato
            </div>
          </a>
        </Columns.Column>

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalSalesServicesCity(true)}>
            <div>
              <FcSalesPerformance size={40} />
            </div>
            <div>
              Vendas por serviços e cidades
            </div>
          </a>
        </Columns.Column>
      </Columns>

      <Columns className="grid-reports-title">
        <p>Gerencial</p>
        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerHecByServices(true)}>
            <div>
              <FcMindMap size={40} />
            </div>
            <div>
              Hectares por serviços
            </div>
          </a>
        </Columns.Column>

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerMediaPriceServices(true)}>
            <div>
              <FcComboChart size={40} />
            </div>
            <div>
              Média preço por serviços
            </div>
          </a>
        </Columns.Column>

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerServicesTop(true)}>
            <div>
              <FcPositiveDynamic size={40} />
            </div>
            <div>
              Serviços mais vendidos
            </div>
          </a>
        </Columns.Column>

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerCustomer(true)}>
            <div>
              <FcBusinessman size={40} />
            </div>
            <div>
              Clientes
            </div>
          </a>
        </Columns.Column>

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerApplication(true)}>
            <div>
              <FcCollect size={40} />
            </div>
            <div>
              Aplicações
            </div>
          </a>
        </Columns.Column>

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerSales(true)}>
            <div>
              <FcDonate size={40} />
            </div>
            <div>
              Vendas
            </div>
          </a>
        </Columns.Column>

        {/* <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerTarget(true)}>
            <div>
              <FcSportsMode size={40} />
            </div>
            <div>
              Metas
            </div>
          </a>
        </Columns.Column> */}

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerMachineUsed(true)}>
            <div>
              <FcMoneyTransfer size={40} />
            </div>
            <div>
              Valor de maquinas nas trocas
            </div>
          </a>
        </Columns.Column>

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerVisits(true)}>
            <div>
              <FcVoicePresentation size={40} />
            </div>
            <div>
              Visitas
            </div>
          </a>
        </Columns.Column>

        <Columns.Column size={2}>
          <a href="javascript:void(0);" className="bg-reports-grid" onClick={() => setModalManagerSalesBilling(true)}>
            <div>
              <FcBiotech size={40} />
            </div>
            <div>
              Envio Análises
            </div>
          </a>
        </Columns.Column>
      </Columns>
    </div>
  );
}
