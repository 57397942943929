import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import "react-datepicker/dist/react-datepicker.css";
import '@lourenci/react-kanban/dist/styles.css'

import breadcrump_separator from '~/assets/breadcrump-separator.png';
import arrow_select from '~/assets/arrow-select.svg';

export default createGlobalStyle`

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*.focus {
  outline: 0;
}

html{
  overflow-y: hidden !important;
}

html, body, #root {
  height: 100%;
  overflow-x: hidden;
  background: #F5F5F5;
}

body {
  -webkit-font-smoothing: antialiased;
}

body, input, button, select {
  font: 14px 'Roboto', sans-serif;
}

.input:active,
.input:focus,
.is-active.input,
.is-active.textarea,
.is-focused.input,
.is-focused.textarea,
.select select.is-active,
.select select.is-focused,
.select select:active,
.select select:focus,
.textarea:active,
.textarea:focus{
  border-color: #556000;
  /* box-shadow: none; */
}
.input:active,
.input:focus,
.is-active.input,
.is-active.textarea,
.is-focused.input,
.is-focused.textarea,
.select select.is-active,
.select select.is-focused,
.select select:active,
.select select:focus,
.textarea:active,
.textarea:focus{
  border-color: #556000 !important;
  /* box-shadow: none !important; */
}
.select.react-select{
  min-height: 40px;
  height: auto !important;
  line-height: 25px;
  font-size: 14px !important;
  background-color: #ffffff !important;
  padding: 0px;
  background: none;
  width: 100%;
  display: block;
}
.select.react-select:not(.is-multiple):not(.is-loading):after{
  display: none;
}
.select.react-select > div{
  border-color: #556000 !important;
  width: 100%;
}
.select,
.input{
  min-height: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px !important;
  background-color: #ffffff !important;
}
.textarea{
  font-size: 14px !important;
}
::placeholder {
  color: gray !important;
}
.input, .select select, .textarea{
  border-color: #556000 !important;
}
.input.search{
  border-color: #dbdbdb !important;
}
.select {
  border-color: #556000 !important;
  color: #0D593F !important;
  border-radius: 4px;
  width: 100%;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(.5em - 1px) calc(.75em - 1px);
  position: relative;
  vertical-align: top;

  background: url(${arrow_select}) no-repeat 99%;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance:none;
  -moz-appearance:none;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

.copyright,
p{
  font-weight: 400;
  color: #0D593F;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.w2d-container{
  margin: 20px auto;
  padding: 0 20px;
  min-height: 70vh;

  .content{
    margin-top: 40px;

    .columns{
      padding-right: 13px;
      padding-left: 13px;

      &.no-padding{
        padding-right: 0px;
        padding-left: 0px;
        margin-bottom: 0px !important;
      }

      .column.no-padding {
        padding: 0 .75rem !important;
      }
    }


    .bg-content{
      background: #ffffff;
      border-radius: 5px;
      padding: 20px;
      -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      min-height: 300px;
    }

    .bg-content.application{
      background: #ffffff;
      border-radius: 5px;
      padding: 20px;
      -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      margin: 0 2px;
      min-height: auto;

      h2{
        color: #8D8D8D;
        font-size: 48px;
        line-height: 48px;
        font-weight: bold;
        margin: 0px !important;
      }
      .text-info{
        color: #8898AA;
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
        margin: 0px !important;
      }
    }
  }
}

.title-page{
  h1{
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #0D593F;
    font-weight: 800;

    &.application{
      font-size: 18px;

      span{
        color: #A3AEB7;
      }
    }
  }
  .w2d-breadcrumb {
    padding: 0;
    margin: 0 0 5px;
    list-style: none;

    li {
      display: inline-block;
      line-height: 30px;
      vertical-align: middle;
      color: #A3AEB7;
      font-size: 14px;
      font-weight: 600;
      position: relative;

      &:after{
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 4px;
        left: 19px;
        background: url(${breadcrump_separator}) no-repeat center;
      }

      &.home{
        margin-right: 5px;

        .link-dash{
          position: relative;
          top: 4px;
        }
      }

      &.end{
        padding-left: 15px;
      }

      &.end:after{
        display: none
      }

    }


  }

}
.column-align-right{
  text-align: right;
  margin-top: 20px;

  &.filter{
    margin-top: 0px;
  }
}
.button{
  font-weight: 600;
  color: #0D593F !important;
  font-size: 12px !important;
  padding: 20px 30px !important;
  text-transform: uppercase;

  .icon-button-back{
    position: relative;
    top: -1px;
    margin-right: 5px;
  }

  &.is-primary{
    color: white !important;
    background-color: #28A745 !important;
  }
  &.is-primary:active,
  &.is-primary:focus,
  &.is-primary:hover{
    background-color: #00c4a7 !important;
  }
  &.is-primary-circle{
    border: 1px solid #28A745 !important;
    color: #28A745 !important;
    height: 30px;
    padding: 0 20px !important;
    border-radius: 40px;
  }
  &.is-primary-circle:active,
  &.is-primary-circle:focus,
  &.is-primary-circle:hover{
    color: white !important;
    background-color: #28A745 !important;
  }

  &.no-padding{
    padding: 0 10px !important;
  }
}
.action{
  margin-left: 13px;
  margin-bottom: 20px;

  &.no-padding{
    margin-left: 0px;
  }
}
.action-right{
  width: 100%;
  display: inline-block;
  text-align: right;
  position: relative;
  top: -35px;
  max-width: 50%;
  float: right;
}
.button.is-danger.no-padding{
  color: #ffffff !important;
}

.link-add{
  top: 36px;
  left: 10%;
  position: relative;
  vertical-align: middle;
  display: flex;
  align-items: center;
  color: #3273dc;
  font-weight: 600;

  svg{
    margin-right: 5px;
  }

  &:hover,
  &:focus,
  &:active{
    color: #3273dc;
  }
}

.no-margin-bottom{
  margin: 0 !important;
  padding: 0 12px 0 0 !important;
}

.button-action {

  .is-danger,
  .is-primary,
  .is-info {
    color: #ffffff !important;
    padding: 12px !important;
    margin-right: 14px;
    font-size: 11px !important;

    svg{
      margin-right: 4px;
    }
  }
  .is-info.is-primary-circle {
    margin-right: 0px;
    margin-top: 2px;
  }
  .is-danger.is-primary-circle {
    margin-right: 0px;
    margin-top: 2px;
  }
  .is-danger{
    margin-right: 0px;
  }
  .is-info {
    background: #6C757D !important;
  }
  .is-danger {
    background: #DC3545 !important;
  }

  &.status-sales{
    color: #ffffff !important;
    padding: 6px 12px !important;
    font-size: 12px !important;
    background: #a7ce43 !important;
    font-weight: bold;
    display: initial;
    border-radius: 4px;
    text-transform: uppercase;
  }
}
.react-confirm-alert-overlay{
  background: rgba(0,0,0,0.8) !important;
}
.custom-ui-confirm-delete {
  text-align: center;
  width: 600px;
  padding: 40px;
  background: #61c779;
  border-radius: 4px;
  box-shadow: 0 20px 75px rgba(251, 2, 2, 0.5);
  color: #fff;

  .title{
    font-size: 18px;
    margin: 20px 0;
    color: #fff;
  }

  > button {
    width: 175px;
    padding: 5px 0 11px 0;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;

    svg {
      position: relative;
      top: 3px;
    }

    &:hover{
      background: #fff;
      color: #28bae6;
    }
  }

  > button.yes {
    background: #de4848;
    border: 1px solid #de4848;
    &:hover{
      background: none;
      color: #de4848;
    }
  }
  > button.yes.active {
    background: #28A745;
    border: 1px solid #28A745;
    &:hover{
      background: none;
      color: #ffffff;
    }
  }
}

label{
  color: #0D593F !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.manager{
  padding: 40px;

  &.filter{
    margin: 0px 13px;
    padding: 20px 5px 20px 30px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    width: 100%;

    label{
      color: #A3AEB7 !important;
      font-weight: 300 !important;
      font-size: 12px !important;
    }

    .between-dates{
      position: relative;
      top: 15px;
      margin-right: 5px;
      color: #A3AEB7 !important;
      font-weight: 400 !important;
      font-size: 12px !important;
    }

    .checkbox{
      position: relative;
      top: 9px;
      font-weight: 400 !important;
      font-size: 12px !important;
      color: #0D593F !important;
    }

    .column{
      padding: 0 20px 0 0;
    }

    .field {
      .control{
        margin-bottom: 0px;
      }
    }

    .field.inline{
      display: flex;

      .field {
        margin-right: 5px;
      }
    }
    .button.is-info{
      background: #6C757D !important;
      color: #ffffff !important;
      position: relative;
      top: 18px;

      &:hover,
      &:focus,
      &:active{
        background: #a9a9a9 !important
      }
    }

    .button-clean {
      background: #F53C56;
      color: #fff !important;
      text-align: center;
      position: relative;
      top: -5px;
      font-size: 14px;
      display: inline-block;
      padding: 2px 8px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  &.profile{
    padding: 40px 0 0;

    .button.is-danger {
      background: #DC3545 !important;
      width: 100% !important;
      color: #ffffff !important;
    }
    .button.is-primary {
      width: 100% !important;
      color: #ffffff !important;
      margin-bottom: 20px
    }
  }
  .field .control {
    margin-bottom: 20px;
  }

  .field .control span.error-inputs{
    font-size: 12px;
    color: red;
    font-weight: 300;
    font-style: italic;
  }

}

.loading {
  width: 100%;
  display: flex;
  height: 60%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #2a775a;

  svg {
    margin-right: 10px;
  }

  &.dashboard{
    padding: 10% 0;
    background: #ffffff;
    margin: 50px auto;
    width: 97%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  }

  .fa-spin {
    animation: fa-spin 2s infinite linear;
  }
  @keyframes fa-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
}

input[type="radio"] {
    -ms-transform: scale(1.3); /* IE 9 */
    -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
    transform: scale(1.3);
}
.radio-button > span{
  font-weight: 300;
  margin: 20px 0px 20px;
}
.radio {
  margin: 20px 5px 20px;

  span{
    font-weight: 300;
    margin-left: 5px;
  }
}
label.checkbox{
  margin-right: 20px;
}
input[type="checkbox"] {
    -ms-transform: scale(1.3); /* IE 9 */
    -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
    transform: scale(1.3);
    margin-right: 3px;
}
.checkbox-button > span{
  font-weight: 300;
  margin: 5px 0px 5px;
}
.checkbox {
  margin: 5px 5px 5px;

  span{
    font-weight: 300;
    margin-left: 5px;
  }
}

.react-datepicker-wrapper{
  width: 100% !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
  left: 23px !important;
}

hr {
  border: 0 !important;
  height: 1px !important;
  background: rgba(85, 96, 1, 0.2) !important;
  margin: 40px 0 !important;
}

.imagem-avatar-upload{
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  display: block;
  object-fit: cover;
}
.imagem-avatar-upload-label{
  margin-bottom: 20px;
  display: inline-block;
  font-size: 12px !important;
  font-weight: 300 !important;
}
.imagem-sales-upload{
  background: #e2e2e2;
  width: 300px;
  height: 200px;
  display: block;
  object-fit: contain;
  padding: 45px 45px 45px 38px;
  border-radius: 5px;
  &.uploaded{
    padding: 0;
  }
}
.imagem-sales-upload-label{
  margin-bottom: 20px;
  display: inline-block;
  font-size: 12px !important;
  font-weight: 300 !important;
  cursor: pointer;
}
.icon-zoom-div{
  position: relative;
  display: inline-block;

  .icon-zoom{
    position: absolute;
    z-index: 9999;
    bottom: 26px;
    right: 0;
    background: #5f5f5f;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    padding: 3px;
  }
}
.no-padding-menu{
  padding: 0 !important;
}
.search-table{
  padding: .75rem 0 !important;

  .button.is-info{
    padding: 19px 15px !important;
    background: #eee !important;
    border-color: #dbdbdb !important;
  }

}
.content table thead td, .content table thead th{
  border-width: 0 !important;
  background: #F1F3F9;
  padding: 15px 5px !important;
  font: 14px 'Roboto', sans-serif;
  font-weight: normal !important;
  color: #8898AA !important;
}

.content table tbody td, .content table tbody th{
  border-width: 0 !important;
  padding: 15px 5px !important;
  font: 14px 'Roboto', sans-serif;
  font-weight: normal !important;
}

.pagination {
  justify-content: flex-end !important;

  li {
    list-style: none;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    margin-left: 5px;
    width: 35px;
    height: 35px;
    line-height: 31px;
    margin-top: 0 !important;

    &.active {
      background: #61c779;

      a{
        color: #ffffff;
      }

    }

    a {
      font: 14px 'Roboto', sans-serif;
      color: #A3AEB7;
      font-weight: bold;

      svg{
        position: relative;
        top: 5px;
      }
    }
  }
}
.total-register{
  display: inline-block;
  padding-top: 10px;
  font: 14px 'Roboto', sans-serif;
  color: #A3AEB7;

  strong{
    color: #9e9e9e;
  }
}

.bg-table-product{
  display: inline-block;
  width: 40px;
  height: 20px;
}

.block-table{
  white-space: pre-wrap;
  line-height: 24px !important;

  span{
    font-weight: bold;
    color: #172B4D;
  }
}

.dashboard-boxs {
  .bg-content{
    background: #ffffff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
    position: relative;

    .title{
      color: #cccccc;
      font: 14px 'Roboto',sans-serif;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 5px;
      border-bottom: 2px solid #eaeaea;
    }
    .value{
      font-weight: bold;
      color: #4D4F5C;
      font-family: 'Roboto', sans-serif;
      font-size: 24px;
    }

    .icon{
      position: absolute;
      right: 0px;
      top: 0px;
      width: 80px;
      height: 103px;
      line-height: 135px;
      text-align: center;
      display: inline-block;
      -webkit-border-top-right-radius: 5px;
      -webkit-border-bottom-right-radius: 5px;
      -moz-border-radius-topright: 5px;
      -moz-border-radius-bottomright: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      &.clients{
        background: #2DCE98;
      }
      &.hect{
        background: #F53C56;
      }
      &.application{
        background: #7764E4;
      }
      &.goal{
        background: #11CDEF;
        line-height: 140px;
      }
    }
  }
}
.app-margin-right{
  margin-right: 10px;
}
.header-table-application{

  .title-line-1{
    font-weight: bold;
    color: #8898AA;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin: 10px 0;
    strong{
      color: #3B3B3B;
    }
  }
  .title-line-2{
    font-weight: bold;
    color: #8898AA;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    strong{
      color: #3B3B3B;
    }
  }
}

.upload-btn-wrapper input[type=file] {
  height: 40px;
  line-height: 40px;
  border: 1px solid #556000;
  min-height: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px !important;
  background-color: #ffffff !important;
  width: 100%;
  border-radius: 4px;

  ::-webkit-file-upload-button {
    background: #556000;
    color: #ffffff;
    padding: 10px 10px 12px;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;

    &:hover{
      background: #29a745;
    }
  }
}

.react-kanban-column-header{
  border-bottom: 1px solid #a2a2a2;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.react-kanban-card {
  max-width: 95%;
  width: 100%;
}

.react-kanban-column {
  width: 19.51%;
  background-color: #dddddd;
  padding: 5px 5px 5px 5px;
  margin: 5px 5px 5px 5px;
}
.sales-machine .react-kanban-column {
  width: 19.5%;
}

.title-card{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.react-kanban-card__title > span{
  width: 100%;
}

.react-kanban-board {
  justify-content: center;
  text-align: center;
}
[data-rbd-drag-handle-context-id="0"] > div,
[data-rbd-drag-handle-context-id="1"] > div,
[data-rbd-drag-handle-context-id="2"] > div,
[data-rbd-drag-handle-context-id="3"] > div,
[data-rbd-drag-handle-context-id="4"] > div,
[data-rbd-drag-handle-context-id="5"] > div,
[data-rbd-droppable-id="board-droppable"]{
  width: 100%;
}
.react-kanban-card-skeleton, .react-kanban-card, .react-kanban-card-adder-form{
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
  text-align: left;
}

/* Dashboard */
.dashboard
  h2{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #0D593F;
  }
  .widget-dashboard{
    &.margin-top{
      margin-top: 10px;
    }
  }
}
.filter-form{
  display: flex;
  justify-content: flex-end;

  .filter-form-column{
    margin: 0 5px;

    &:last-child{
      margin: 0 0 0 5px;
    }
  }

  .react-datepicker-popper{
    transform: translate3d(0px, 40px, 0px) !important;
  }
}
.button-filter-dashboard{
  border-radius: 4px;
  height: 2.85em;
  background: #2DCE98;
  border: 0px;
  width: auto;
  min-width: auto;
  margin-right: 3px;
  line-height: 3.4rem;
  padding: 0 10px;

  &.clear{
    color: #ffffff;
    position: relative;
    padding: 0 10px;
    margin-left: 5px;
    background: #F53C56;

    span{
      position: relative;
      top: -7px;
      width: auto;
    }
  }
}
.custom-padding{
  padding: .75rem 3px !important;
}
.widget-dashboard{
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 20px 20px;
  position: relative;
  margin-bottom: 20px;
  min-height: 129px;

  h3{
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: #8898AA;
    font-weight: bold;
    margin-top: 5px;

    span {
      font-size: 10px;
      color: #000000;
      font-weight: 300;
    }
  }
  .value{
    font-family: 'Roboto', sans-serif;
    margin-top: 0px;
    font-size: 22px;
    color: #000000;
    font-weight: bold;

    &.sales-approved{
      margin-top: 20px;
    }
  }
  .status{
    position: absolute;
    width: 54px;
    height: 54px;
    line-height: 54px;
    border-radius: 50%;
    display: block;
    text-align: center;
    padding-top: 9px;
    right: 20px;
    top: 35px;

    &.more{
      background: #2DCE98;
    }
    &.less{
      background: #F53C56;
    }
    &.sales{
      background: #7764E4;
      padding-left: 1px;
    }
    &.target{
      background: #fbc003;
    }
  }
  .text-month{
    margin-top: 20px;
    font-size: 12px;
    color: #4D4F5C;
    font-weight: 300;

    span{
      font-weight: bold;
    }
  }
}

/* Modal */
.modal-card{
  padding: 30px 10px;
}
.modal-card-head{
  padding: 20px 0 25px 0 !important;
  background-color: #d64f4f !important;
  border-radius: 0 !important;
  p{
    margin: 0 !important;
  }
  .delete {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 99999999;
    background: #000;
  }
}
.modal-card-title{
  line-height: 0px !important;
  color: #fff !important;
  font-weight: bold !important;
}
.modal-card-body{
  text-align: center;
}
.modal-card-body h2{
  font-size: 16px !important;
  text-align: center;
  margin-bottom: 20px !important;
  font-weight: bold !important;
}
.modal-card-body .button{
  padding: 16px !important;
}
.user-dashboard-filter{
  min-width: 220px;
}
.filter-list-registers{
  margin-right: 10px;

  .select {
    border-color: #dbdbdb !important;
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
    border-radius: 0 !important;
  }
}

.filter-list-registers .filter-user{
  position: relative;
  margin-right: 50px;

  .search-filter-user{
    position: absolute;
    top: 0;
    right: -52px;
  }
}

hr.custom{
  margin: -15px 0px 30px !important;
}

.dzu-dropzone{
  margin: 20px 0 !important;
}

/* Reports */
.grid-reports-title {
  margin-bottom: 40px !important;
  p{
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
    color: #A3AEB7;
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 0 12px;
    margin: 0;
    text-align: left;
  }
}
.bg-reports-grid {
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #cdcdcd;
  font-size: 14px;
  color: #4D4F5C;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.5px;

  svg{
    margin-bottom: 0px
  }

  &:hover,
  &:focus,
  &:active{
    background: #ffffff;
    color: #ee5659;
    -webkit-box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    font-weight: 600;
  }

}

.report.modal {

  .modal-card {
    padding: 30px 10px;
  }
  .modal-card-head{
    padding: 20px !important;
    background-color: #a8cf46 !important;
    border-radius: 0 !important;
    p{
      margin: 0 !important;
    }
  }
  .modal-card-title{
    line-height: 0px !important;
    color: #fff !important;
    font-weight: bold !important;
    text-align: left !important;
    font-size: 14px;
  }
  .modal-card-body{
    text-align: center;
    padding: 40px 20px !important;

    .manager.filter{
      border: none !important;

      label{
        display: inline-block;
      }
      .checkbox{
        top: 1px;
        margin-left: 10px;
      }

      .field .control{
        margin-bottom: 10px;
      }
      .field.is-horizontal{
        margin-top: 20px;
      }
      .button-clean {
        top: 20px;
        margin-bottom: 20px;
        text-align: left;
        display: block;
        max-width: 125px;
      }
      .button.is-info{
        width: 100%;
        padding: 20px 0 !important;
      }
    }
  }
  .modal-card-body h2{
    font-size: 16px !important;
    text-align: center;
    margin-bottom: 20px !important;
    font-weight: bold !important;
  }
  .modal-card-body .button{
    padding: 16px !important;
  }
}

.checkbox-filter{
  display: flex;
  position: absolute;
  right: 0px;
  top: 17px;

  .title {
    font-family: 'Roboto',sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: #8898AA;
    font-weight: bold;
    margin-top: 8px;
    margin-right: 10px;
    margin-bottom: 5px !important;
  }
  label.checkbox{
    margin-right: 10px;
    color: #43425d !important;
  }
}

.loadingGoals{
  min-height: 398px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #2a775a;
}

.totalizador{
  font-size: 10px;
  color: #A3AEB7 !important;
  margin-top: -3px;
}

.search-table{
  display: flex !important;
  gap: 20px;

  .field{
    width: 100%;
  }
}

.select-search{
  border-color: #dbdbdb !important;
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  color: #363636;
  height: 40px;
  min-height: 40px;
}

.react-kanban-column > div:last-of-type {
  max-height: 100vh;
  min-height: 100vh !important;
  overflow-y: scroll;
}

`;
