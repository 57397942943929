import React, { useState, useEffect } from 'react';
import { Columns } from 'react-bulma-components';
import {
	Chart,
	Interval,
	Tooltip,
	Coordinate,
	Legend,
	View,
	Annotation,
	getTheme
} from "bizcharts";

import { GraphicGoalsAchieved } from './styles';

export default function GoalsAchieved( { data } ) {
  const [isLoading, setIsLoading] = useState(false);
  const [typeGraphic, setTypeGraphic] = useState('machine');
  const [dataGoalsTotal, setDataGoalsTotal] = useState([]);
  const [dataGoalsTotalOk, setDataGoalsTotalOk] = useState([]);
  const [dataGoalOk, setDataGoalOk] = useState(0);
  const [dataRemainingGoalValue, setDataRemainingGoalValue] = useState([]);
  const [dataRemainingGoalPercentual, setDataRemainingGoalPercentual] = useState(0);

  let total_goals_machine = '';
  let total_goals_machine_OK = '';
  let remaining_goals_machine = '';
  let different_total_machine = '';
  let remaining_goal_machine = '';
  let goal_ok_machine = '';

  // Abrir com máquinas selecionadas
  useEffect(() => {
    const setData = async () => {

      
      total_goals_machine = parseFloat(data.total_goals_machine);
      total_goals_machine_OK = parseFloat(data.total_sales_machine[0].total_sales_machine);

      if(total_goals_machine_OK === 0) {
        total_goals_machine_OK = '0.0';
      }
      remaining_goals_machine = Math.abs(total_goals_machine - total_goals_machine_OK);

      if ( (total_goals_machine_OK !== 0) && (total_goals_machine !== 0) ) {
        different_total_machine = ((total_goals_machine_OK * 100) / total_goals_machine);
      } else {
        different_total_machine = '0.0';
      }
      remaining_goal_machine = Math.abs(different_total_machine - 100);
      if (total_goals_machine === 0.001){
        goal_ok_machine = 0.00;
      } else {
        goal_ok_machine = Math.abs(remaining_goal_machine - 100);
      }

      setDataGoalsTotal(convertToReal(data.total_goals_machine));
      setDataGoalsTotalOk(convertToReal(total_goals_machine_OK));
      setDataGoalOk(parseFloat(goal_ok_machine.toFixed(2)));
      if(remaining_goals_machine === 0){
        remaining_goals_machine = '0.00';
      }
      setDataRemainingGoalValue(convertToReal(remaining_goals_machine));
      if(isNaN(remaining_goal_machine)){
        remaining_goal_machine = '0.00'
      }
      setDataRemainingGoalPercentual(remaining_goal_machine);

    };
    setData();
  }, [data]);

  const myData = [
    { type: "Meta Atingida", percent: dataGoalOk },
    { type: "Restante", percent: dataRemainingGoalPercentual },
  ];
  const myContent = {
    percent: dataGoalOk+"%",
  };

  function convertToReal(number, options = {}) {
    const { moneySign = true } = options;
    if(Number.isNaN(number) || !number) return "need a number as the first parameter";
    if(typeof number === "string") {
      number = Number(number);
    }
    let res;
    const config = moneySign ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};
    moneySign
    ? res = number.toLocaleString('pt-BR', config)
    : res = number.toLocaleString('pt-BR', config)
    const needComma = number => number <= 1000;
    if(needComma(number)) {
      res = res.toString().replace(".", ",");
    }
    return res;
  }

  const changeTypeGraphics = (type) => {
    setIsLoading(true);
    if (type === 'ap'){
      setTypeGraphic('ap');

      total_goals_machine = parseFloat(data.total_goals_ap);
      total_goals_machine_OK = parseFloat(data.total_sales_ap[0].total_sales_ap);

      if(total_goals_machine_OK === 0) {
        total_goals_machine_OK = '0.0';
      }
      console.log(total_goals_machine)
      console.log(total_goals_machine_OK)
      if (total_goals_machine === 0) {
        remaining_goals_machine = 0.00;
      } else {
        remaining_goals_machine = Math.abs(total_goals_machine - total_goals_machine_OK);
      }

      if ( (total_goals_machine_OK !== 0) && (total_goals_machine !== 0) ) {
        different_total_machine = ((total_goals_machine_OK * 100) / total_goals_machine);
      } else {
        different_total_machine = '0.0';
      }

      remaining_goal_machine = Math.abs(different_total_machine - 100);

      if (total_goals_machine === 0.001){
        goal_ok_machine = 0.00;
        different_total_machine = 0.00;
        remaining_goal_machine =  0.00;
      } else {
        goal_ok_machine = Math.abs(different_total_machine);
      }

      setDataGoalsTotal(convertToReal(data.total_goals_ap));
      setDataGoalsTotalOk(convertToReal(total_goals_machine_OK));
      setDataGoalOk(parseFloat(goal_ok_machine.toFixed(2)));
      if(remaining_goals_machine === 0){
        remaining_goals_machine = '0.00';
      }
      setDataRemainingGoalValue(convertToReal(remaining_goals_machine));
      if(isNaN(remaining_goal_machine)){
        remaining_goal_machine = 0.00
      }
      setDataRemainingGoalPercentual(remaining_goal_machine);
      setIsLoading(false);

    } else {
      setTypeGraphic('machine');
      total_goals_machine = parseFloat(data.total_goals_machine);
      total_goals_machine_OK = parseFloat(data.total_sales_machine[0].total_sales_machine);

      if(total_goals_machine_OK === 0) {
        total_goals_machine_OK = '0.0';
      }

      remaining_goals_machine = Math.abs(total_goals_machine - total_goals_machine_OK);

      if ( (total_goals_machine_OK !== 0) && (total_goals_machine !== 0) ) {
        different_total_machine = ((total_goals_machine_OK * 100) / total_goals_machine);
      } else {
        different_total_machine = '0.0';
      }
      remaining_goal_machine = Math.abs(different_total_machine - 100);


      if (total_goals_machine === 0.001){
        goal_ok_machine = 0.00;
      } else {
        goal_ok_machine = Math.abs(remaining_goal_machine - 100);
      }

      setDataGoalsTotal(convertToReal(data.total_goals_machine));
      setDataGoalsTotalOk(convertToReal(total_goals_machine_OK));
      setDataGoalOk(parseFloat(goal_ok_machine.toFixed(2)));
      if(remaining_goals_machine === 0){
        remaining_goals_machine = '0.00';
      }
      setDataRemainingGoalValue(convertToReal(remaining_goals_machine));
      if(isNaN(remaining_goal_machine)){
        remaining_goal_machine = 0.00
      }
      setDataRemainingGoalPercentual(remaining_goal_machine);
      setIsLoading(false);
    }
  }

  function Ring({ data = [], content = {}, intervalConfig = {} }) {
    const brandFill = getTheme().colors10[0];
    return (
      <Chart placeholder={false} height={350} padding="auto" autoFit>
        <Legend visible={false} />
        <Tooltip visible={false} />
        <View
          data={data}
          scale={{
            percent: {
              formatter: (val) => {
                return (val * 100).toFixed(2) + "%";
              },
            },
          }}
        >
          <Coordinate type="theta" innerRadius={0.75} />
          <Interval
            position="percent"
            adjust="stack"
            color={["type", [brandFill, "#eee"]]}
            size={16}
            {...intervalConfig}
          />
          <Annotation.Text
            position={["50%", "50%"]}
            content={content.percent}
            style={{
              lineHeight: "40px",
              fontSize: "60",
              fontWeight: "bold",
              fill: "#000000",
              textAlign: "center",
            }}
          />
        </View>
      </Chart>
    );
  }

  return (
    <GraphicGoalsAchieved>
      <Columns>
        {isLoading ?
          <div className="loadingGoals">Carregando, aguarde!</div>
        :
          <>
            <Columns.Column size={12}>
              <div className="checkbox-filter">
                <div className="title">Filtrar por: </div>
                <label className="checkbox">
                    <input
                      type="checkbox"
                      value="true"
                      name="ap"
                      onClick={(e) => {changeTypeGraphics('ap')}}
                      checked={typeGraphic == "ap"}
                    /> AP
                  </label>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      value="true"
                      name="machine"
                      onClick={(e) => {changeTypeGraphics('machine')}}
                      checked={typeGraphic == "machine"}
                      defaultChecked
                    /> Máquinas
                  </label>
              </div>
            </Columns.Column>
            <Columns.Column size={9}>
              <Ring data={myData} content={myContent} />
            </Columns.Column>
            <Columns.Column size={3}>
              <div className="align-column-center">
                <div className="item">
                  <h3>
                    <span className="hit-target"></span>  Meta atingida
                  </h3>
                </div>
                <div className="item">
                  <div className="value">
                  {dataGoalsTotalOk}
                  </div>
                </div>
                <div className="item">
                  <h3>
                    <span className="remaining"></span> Restante
                  </h3>
                  <div className="value">
                    {dataRemainingGoalValue}
                  </div>
                </div>
                <div className="item">
                  <h3>
                    Total em metas
                  </h3>
                  <div className="value">
                    {dataGoalsTotal}
                  </div>
                </div>
              </div>
            </Columns.Column>
          </>
        }
      </Columns>
    </GraphicGoalsAchieved>
  );
}
