// eslint-disable-next-line import/no-unresolved
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';

import MenuRegistrations from '~/components/MenuRegistrations';
import { reasonSaveRequest } from '~/store/modules/reason/actions';

export default function ReasonAdd() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  function onSubmit(data) {
    dispatch(reasonSaveRequest(data));
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de motivo</li>
            </ul>
            <h1>Cadastro de motivo</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/reason">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <label htmlFor="name" className="label">
                  Nome do motivo
                </label>
                <div className="control">
                  <input
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o nome do motivo"
                    className="input"
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <label htmlFor="name" className="label">
                  Detalhes do motivo
                </label>
                <div className="control">
                  <textarea
                    name="description"
                    ref={register}
                    placeholder="Informe os detalhes do motivo"
                    className="textarea"
                  />
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
