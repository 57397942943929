import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { deliveryUpdateSuccess } from './actions';

export function* deliverySave({ payload }) {
  try {
    const response = yield call(api.post, 'delivery', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* deliveryUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `delivery/${payload.id}`, payload.data);
    toast.success('Entrega atualizada com sucesso!');
    yield put(deliveryUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a entrega.');
  }
}

export function* deliveryDelete({ payload }) {
  try {
    yield call(api.delete, `delivery/${payload.delivery_id}`);
    toast.success('Entrega excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a entrega.');
  }
}

export default all([
  takeLatest('@delivery/DELIVERY_SAVE_REQUEST', deliverySave),
  takeLatest('@delivery/DELIVERY_UPDATE_REQUEST', deliveryUpdate),
  takeLatest('@delivery/DELETE_DELIVERY_REQUEST', deliveryDelete),
]);
