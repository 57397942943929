export function reasonSaveRequest(data) {
  return {
    type: '@reason/REASON_SAVE_REQUEST',
    payload: { data },
  };
}

export function reasonSaveSuccess(data) {
  return {
    type: '@reason/REASON_SAVE_SUCCESS',
    payload: { data },
  };
}

export function reasonUpdateRequest(id, data) {
  return {
    type: '@reason/REASON_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function reasonUpdateSuccess() {
  return {
    type: '@reason/REASON_UPDATE_SUCCESS',
  };
}

export function reasonDeleteRequest(reason_id) {
  return {
    type: '@reason/DELETE_REASON_REQUEST',
    payload: { reason_id },
  };
}
