// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdAddCircleOutline, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { RiFilterOffFill, RiFilterFill } from 'react-icons/ri';
import { FiTrash, FiX } from 'react-icons/fi';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { confirmAlert } from 'react-confirm-alert';
import { format } from 'date-fns'
import Pagination from "react-js-pagination";

import api from '~/services/api';
import MenuVisit from '~/components/MenuVisit';
import { visitDeleteRequest } from '~/store/modules/visit/actions';

import DatePicker from "react-datepicker";

function formatDate(value){
  const [yyyy, mm, dd] = value.split(/-/g);
  return `${dd}/${mm}/${yyyy}`
}
function DisplayFormatDate({ date }) {
  const dateFormat = useMemo(() => {
    return formatDate(date);
  }, [date]);

  return dateFormat;
}

export default function VisitList() {
  const dispatch = useDispatch();
  const inputRefCustomer = useRef();
  const inputRefReason = useRef();
  const inputRefFormServices = useRef();
  const inputRefUser = useRef();
  const inputRefDivergencia = useRef();

  const [visit, setVisit] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSelects, setIsLoadingSelects] = useState(true);

  const [pageNro, setPageNro] = useState(1);
  const [searchDate, setSearchDate] = useState(false);
  const [pageFilter, setPageFilter] = useState(false);

  const [filter, setFilter] = useState({});
  const [customer, setCustomer] = useState([]);
  const [formService, setFormService] = useState([]);
  const [reason, setReason] = useState([]);
  const [user, setUser] = useState([]);

  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  const profile = useSelector((state) => state.user.profile);
  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`visit?page=${pageNumber}`);
      setVisit(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };
  const fetchDataCustomer = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('customer/registers/all');
      setCustomer(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchDataFormService = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('form-service/registers/all');
      setFormService(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchDataReason = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('reason/registers/all');
      setReason(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchDataUser = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/user/registers/all');
      setUser(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataCustomer();
    fetchDataFormService();
    fetchDataReason();
    fetchDataUser();
  }, []);

  const selectVisitByDate = async (pageNro) => {
    setIsLoading(true);
    if (searchDate !== '') {
      try {
        const response = await api.get(`/visit/filter/params`, filter);
        setVisit(response.data);
        setIsLoading(false);
        setPageFilter(true)
        setPageNro(pageNro)
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    } else {
      fetchData();
    }
  };

  function deleteVisit(visit_id, name) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-confirm-delete">
            <div className="title">
              Confirma a exclusão da visita: <strong>{name}</strong>?{' '}
            </div>
            <button onClick={onClose}>
              <FiX size="18px" /> Cancelar exclusão
            </button>
            <button
              className="yes"
              onClick={() => {
                handleClickDelete(visit_id);
                onClose();
                fetchData();
              }}
            >
              <FiTrash size="18px" /> Sim, pode excluir!
            </button>
          </div>
        );
      },
    });
  }

  function handleClickDelete(visit_id) {
    dispatch(visitDeleteRequest(visit_id));
  }

  function cleanInputsFilter() {
    inputRefCustomer.current.value = '';
    inputRefFormServices.current.value = '';
    if (admin) {
      inputRefDivergencia.current.value = '';
      inputRefUser.current.value = '';
    }
    inputRefReason.current.value = '';
    setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    setSearchDate(false);
    setPageFilter(false);
    setPageNro(1);
    fetchData();
  }

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    var refDivergencia = '';
    var refUser = '';
    if (admin) {
      refDivergencia = inputRefDivergencia.current.checked;
      refUser = inputRefUser.current.value;
    } else {
      refDivergencia = '';
      refUser = '';
    }
    setFilter({
      params: {
        customer_id: inputRefCustomer.current.value,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        form_services_id: inputRefFormServices.current.value,
        new: refDivergencia,
        reason_id: inputRefReason.current.value,
        user_id: refUser,
      }
    });
    api.get(`/visit/filter/params`, {
      params: {
        customer_id: inputRefCustomer.current.value,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        form_services_id: inputRefFormServices.current.value,
        new: refDivergencia,
        reason_id: inputRefReason.current.value,
        user_id: refUser,
      },
    }).then(response => {
      setVisit(response.data);
      setIsLoading(false);
      setSearchDate(true);
      setPageFilter(true);
      setPageNro(1)
    });

  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Lista de visitas</li>
            </ul>
            <h1>Filtros de visitas</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/visits/create">
            <Button className="button is-primary">
              <MdAddCircleOutline
                size={15}
                color="#FFFFFF"
                className="icon-button-back"
              />
              Cadastrar visita
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <Columns>
        <Columns.Column>
          <Columns>
            <form className="manager filter">
              <Columns>
                <Columns.Column size={admin ? '' : 2}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Filtro de cliente
                      </label>
                      <select name="customer_id" ref={inputRefCustomer} className="select">
                        <option value="" className="placeholder">Cliente</option>
                        { isLoadingSelects ? (
                          <option>Carregando</option>
                        ) : (
                          customer.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </Columns.Column>
                <Columns.Column size={admin ? '' : 2}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Filtro de motivo
                      </label>
                      <select name="reason_id" ref={inputRefReason} className="select">
                        <option value="" className="placeholder">Motivo</option>
                        { isLoadingSelects ? (
                          <option>Carregando</option>
                        ) : (
                          reason.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </Columns.Column>
                <Columns.Column size={admin ? '' : 2}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Filtro de atendimento
                      </label>
                      <select name="form_services_id" ref={inputRefFormServices} className="select">
                        <option value="" className="placeholder" >Atendimento por</option>
                        { isLoadingSelects ? (
                          <span>Carregando</span>
                        ) : (
                          formService.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </Columns.Column>
                {admin ?
                  <Columns.Column>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de responsável
                        </label>
                        <select name="user_id" ref={inputRefUser} className="select">
                          <option value="" className="placeholder">Responsável</option>
                          { isLoadingSelects ? (
                            <option>Carregando</option>
                          ) : (
                            user.map((value, index) => {
                              return <option value={value.id} key={index}>{value.name}</option>
                            })
                          )}
                        </select>
                      </div>
                    </div>
                  </Columns.Column>
                : ''}

                <Columns.Column size={admin ? 2 : 4}>
                  <label htmlFor="name" className="label">
                    Filtro entre data
                  </label>
                  <div className="field inline">
                    <div className="field">
                      <div className="control">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          locale="pt"
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          className="input"
                          popperPlacement="top-right"
                        />
                      </div>
                    </div>
                    <span className="between-dates">a</span>
                    <div className="field">
                      <div className="control">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          locale="pt"
                          selected={endDate}
                          onChange={date => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          className="input"
                          popperPlacement="top-right"
                        />
                      </div>
                    </div>
                  </div>
                </Columns.Column>
                {admin ?
                  <Columns.Column>
                    <div className="field">
                      <label htmlFor="new" className="label">
                        Filtrar divêrgencia?
                      </label>
                      <div className="control">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            value="true"
                            ref={inputRefDivergencia}
                            name="new"
                          /> Sim
                        </label>
                      </div>
                    </div>
                  </Columns.Column>
                : ''}
                <Columns.Column size={admin ? '' : 2}>
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <div className="control has-text-right">
                          <button onClick={handleSubmit} className="button is-info">
                            <RiFilterFill
                              size={15}
                              color="#FFFFFF"
                              className="icon-button-back"
                            />
                            Filtrar dados
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column>
                  <div className="button-clean" onClick={cleanInputsFilter} >
                    <RiFilterOffFill
                      size={15}
                      color="#FFFFFF"
                      className="icon-button-back"
                    /> Limpar filtros
                  </div>
                </Columns.Column>
              </Columns>
            </form>
          </Columns>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={2} className="no-padding-menu">
            <MenuVisit />
          </Columns.Column>
          <Columns.Column size={10} className="bg-content">
            <Columns>
              <Columns.Column size={6} className="search-table">
              </Columns.Column>
              <Columns.Column size={6} className="has-text-right">
                <span className="total-register">Total de registros: <strong>{visit.total}</strong></span>
              </Columns.Column>
            </Columns>
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <>
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th>Data da Visita</th>
                      <th>Cliente</th>
                      <th>Motivo</th>
                      <th>Forma de atendimento</th>
                      <th>Responsável</th>
                      {admin ? <th width="200" >Descrição</th> : ''}
                      <th width="180" className="has-text-centered">Ações</th>
                    </tr>
                  </thead>
                  {visit.data.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="5" className="has-text-centered">Nenhum Registro encontrado :(</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {visit.data.map((value, index) => (
                        <tr key={value.id}>
                          {/* <td>{format(new Date(value.created_at), 'dd/MM/yyyy')}</td> */}
                          <td><DisplayFormatDate date={value.created_at.substr(0, 10)} /></td>
                          <td>{value.customer_name}</td>
                          <td>{value.reason_name}</td>
                          <td>{value.form_service_name}</td>
                          <td>{value.user_name}</td>
                          {(admin) ? (<td>{value.description}</td>) : ''}
                          <td width="180" className="has-text-centered">
                            <div className="button-action">
                              <Link
                                to={`/visits/edit/${value.id}`}
                                className="button is-info"
                              >
                                Editar
                              </Link>
                              <Button
                                className="button is-danger"
                                onClick={() => deleteVisit(value.id, value.name)}
                              >
                                Excluir
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                AQUI: {pageFilter}
                {!pageFilter ? (
                  <Pagination
                    activePage={visit.current_page}
                    itemsCountPerPage={visit.per_page}
                    totalItemsCount={visit.total}
                    onChange={(pageNumber) => fetchData(pageNumber)}
                    prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                    firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                    nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                    lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
                  />
                ) : (
                  <Pagination
                    activePage={pageNro}
                    itemsCountPerPage={visit.per_page}
                    totalItemsCount={visit.total}
                    onChange={(pageNumber) => selectVisitByDate(pageNumber)}
                    prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                    firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                    nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                    lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
                  />
                )}
              </>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
