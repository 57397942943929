// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdAddCircleOutline, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { RiFilterOffFill, RiFilterFill } from 'react-icons/ri';
import { FiTrash, FiX } from 'react-icons/fi';
import { ImSpinner11 } from 'react-icons/im';
import { confirmAlert } from 'react-confirm-alert';
import { Columns, Button } from 'react-bulma-components';
import Pagination from "react-js-pagination";
import { format } from 'date-fns'

import api from '~/services/api';
import { applicationDeleteRequest } from '~/store/modules/application/actions';

import DatePicker from "react-datepicker";

function empty(str) {
  if (typeof str == 'undefined' || !str || str.length === 0 || str === "" || !/[^\s]/.test(str) || /^\s*$/.test(str) || str.replace(/\s/g,"") === "")
    return true;
  else
    return false;
}

export default function ApplicationList() {
  const dispatch = useDispatch();
  const inputRefCustomer = useRef();
  const inputRefField = useRef();
  const inputRefState = useRef();
  const inputRefCity = useRef();
  const inputRefProduct = useRef();
  const inputRefFormApplication = useRef();
  const inputRefMenor100 = useRef();

  const [application, setApplication] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [countHectares, setCountHectares] = useState();
  const [countProductsTotal, setCountProductsTotal] = useState([]);
  const [field, setField] = useState([]);
  const [productApplication, setProductApplication] = useState([]);
  const [formApplication, setFormApplication] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(true);
  const [isLoadingStates, setIsLoadingStates] = useState(true);
  const [isLoadingSelects, setIsLoadingSelects] = useState(true);

  const [filter, setFilter] = useState({});
  const [customer, setCustomer] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [pageNro, setPageNro] = useState(1);
  const [searchDate, setSearchDate] = useState(false);
  const [pageFilter, setPageFilter] = useState(false);

  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  const profile = useSelector((state) => state.user.profile);
  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`application?page=${pageNumber}`);
      setApplication(response.data);
      const count = await api.get(`/application/total/hectares`);
      setCountHectares(count.data.qtde_hectares.toFixed(2));
      const productsTotal = await api.get(`/application/total/products`);
      setCountProductsTotal(productsTotal.data);
      setIsLoading(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  const fetchDataCustomer = async () => {
    setIsLoadingCustomer(true);
    try {
      const response = await api.get('customer/registers/all');
      setCustomer(response.data);
      setIsLoadingCustomer(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  const fetchDataField = async (e) => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get(`field/find-by-customer/${e.target.value}`);
      setField(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  const fetchDataState = async () => {
    setIsLoadingStates(true);
    try {
      const response = await api.get('states');
      setStates(response.data);
      setIsLoadingStates(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  const findyCity = e => {
    const fetchCities = async () => {
      if (e.target.value !== '') {
        try {
          const response = await api.get(`cities/${e.target.value}`);
          setCities(response.data);
        } catch (error) {
          console.log('Error: '+error);
        }
      }
    };
    fetchCities();
  }

  useEffect(() => {
    fetchData();
    fetchDataCustomer();
    fetchDataState();
  }, []);

  const fetchDataProductApplication = async () => {
    try {
      const response = await api.get('product-application/registers/all');
      setProductApplication(response.data);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  useEffect(() => {
    fetchDataProductApplication();
  }, []);

  const fetchDataFormApplication = async () => {
    try {
      const response = await api.get('form-application/registers/all');
      setFormApplication(response.data);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  useEffect(() => {
    fetchDataFormApplication();
  }, []);

  const setSearch = e => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  async function handleSearch(){
    setIsLoading(true);
    if (searchInput !== '') {
      try {
        const response = await api.get(`application/search/${searchInput}`);
        setApplication(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    } else {
      fetchData();
    }
  }

  function cleanInputsFilter() {
    inputRefCustomer.current.value = '';
    inputRefField.current.value = '';
    inputRefState.current.value = '';
    inputRefCity.current.value = '';
    inputRefProduct.current.value = '';
    inputRefFormApplication.current.value = '';
    inputRefMenor100.current.value = '';
    setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    setSearchDate(false);
    setPageFilter(false);
    setPageNro(1);
    fetchData();
  }

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);

    setFilter({
      params: {
        customer_id: inputRefCustomer.current.value,
        field_id: inputRefField.current.value,
        state_id: inputRefState.current.value,
        city_id: inputRefCity.current.value,
        product_id: inputRefProduct.current.value,
        form_application_id: inputRefFormApplication.current.value,
        menor100: inputRefMenor100.current.checked,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
      }
    });
    api.get(`/application/filter/params`, {
      params: {
        customer_id: inputRefCustomer.current.value,
        field_id: inputRefField.current.value,
        state_id: inputRefState.current.value,
        city_id: inputRefCity.current.value,
        product_id: inputRefProduct.current.value,
        form_application_id: inputRefFormApplication.current.value,
        menor100: inputRefMenor100.current.checked,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
      },
    }).then(response => {
      setApplication(response.data);
      setIsLoading(false);
      setSearchDate(true);
      setPageFilter(true);
      setPageNro(1)
    });
  }

  const selectVisitByDate = async (pageNro) => {
    setIsLoading(true);
    if (searchDate !== '') {
      try {
        const response = await api.get(`/application/filter/params`, filter);
        setApplication(response.data);
        setIsLoading(false);
        setPageFilter(true)
        setPageNro(pageNro)
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    } else {
      fetchData();
    }
  };

  function deleteApplication(application_id, name) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-confirm-delete">
            <div className="title">
              Confirma a exclusão da aplicação: <strong>{name}</strong>?{' '}
            </div>
            <button onClick={onClose}>
              <FiX size="18px" /> Cancelar exclusão
            </button>
            <button
              className="yes"
              onClick={() => {
                handleClickDelete(application_id);
                onClose();
                fetchData();
              }}
            >
              <FiTrash size="18px" /> Sim, pode excluir!
            </button>
          </div>
        );
      },
    });
  }

  function handleClickDelete(application_id) {
    dispatch(applicationDeleteRequest(application_id));
  }

  return (
    <div className="w2d-container">

      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Lista de aplicações</li>
            </ul>
            <h1>Lista de aplicações</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/application/create">
            <Button className="button is-primary">
              <MdAddCircleOutline
                size={15}
                color="#FFFFFF"
                className="icon-button-back"
              />
              Cadastrar aplicação
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns className="bg-content application">
          <Columns.Column size={3}>
            <h2>{application.total}</h2>
            <div className="text-info">Total de aplicações</div>
          </Columns.Column>
          <Columns.Column size={4}>
            <h2>{countHectares > 1 ? countHectares : 0}</h2>
            <div className="text-info">Total de hectáres</div>
          </Columns.Column>
          <Columns.Column size={5}>

          </Columns.Column>
        </Columns>
      </div>
      <br />


      <Columns>
        <Columns.Column>
          <Columns>
            <form className="manager filter">
              <Columns>
                <Columns.Column size={3}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Filtro de cliente
                      </label>
                      <select name="customer_id" ref={inputRefCustomer} className="select" onChange={fetchDataField}>
                        <option value="" className="placeholder">Cliente</option>
                        { isLoadingCustomer ? (
                          <option>Carregando</option>
                        ) : (
                          customer.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </Columns.Column>

                <Columns.Column size={3}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Filtro de talhão
                      </label>
                      <select name="field_id" ref={inputRefField} className="select">
                        <option value="" className="placeholder">Talhão</option>
                        { isLoadingSelects ? (
                          <option>Carregando</option>
                        ) : (
                          field.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </Columns.Column>

                <Columns.Column size={2}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Filtro de Estado
                      </label>
                      <select name="state_id" ref={inputRefState} className="select" onChange={findyCity}>
                        <option value="" className="placeholder">Estado</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          states.map((value, index) => {
                            return <option value={value.id} key={index}>{value.title}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </Columns.Column>

                <Columns.Column size={2}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Filtro de Cidade
                      </label>
                      <select name="city_id" ref={inputRefCity} className="select">
                        <option value="" className="placeholder">Cidade</option>
                        { isLoadingStates ? (
                          <option>Carregando</option>
                        ) : (
                          cities.map((value, index) => {
                            return <option value={value.id} key={index}>{value.title}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </Columns.Column>

                <Columns.Column size={2}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Filtro de produto
                      </label>
                      <select name="city_id" ref={inputRefProduct} className="select">
                        <option value="" className="placeholder">Produto</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          productApplication.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </Columns.Column>

                <Columns.Column size={2}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Filtro de forma
                      </label>
                      <select name="city_id" ref={inputRefFormApplication} className="select">
                        <option value="" className="placeholder">Forma de aplicação</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          formApplication.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                    </div>
                  </div>
                </Columns.Column>

                <Columns.Column size={3}>
                  <label htmlFor="name" className="label">
                    Filtro entre data
                  </label>
                  <div className="field inline">
                    <div className="field">
                      <div className="control">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          locale="pt"
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          className="input"
                          popperPlacement="top-right"
                        />
                      </div>
                    </div>
                    <span className="between-dates">a</span>
                    <div className="field">
                      <div className="control">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          locale="pt"
                          selected={endDate}
                          onChange={date => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          className="input"
                          popperPlacement="top-right"
                        />
                      </div>
                    </div>
                  </div>
                </Columns.Column>

                <Columns.Column>
                  <div className="field">
                    <label htmlFor="menor100" className="label">
                      Correção menor 100%
                    </label>
                    <div className="control">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          value="true"
                          ref={inputRefMenor100}
                          name="menor100"
                        /> Sim
                      </label>
                    </div>
                  </div>
                </Columns.Column>

                <Columns.Column size={admin ? '' : 2}>
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <div className="control has-text-right">
                          <button onClick={handleSubmit} className="button is-info">
                            <RiFilterFill
                              size={15}
                              color="#FFFFFF"
                              className="icon-button-back"
                            />
                            Filtrar dados
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column>
                  <div className="button-clean" onClick={cleanInputsFilter} >
                    <RiFilterOffFill
                      size={15}
                      color="#FFFFFF"
                      className="icon-button-back"
                    /> Limpar filtros
                  </div>
                </Columns.Column>
              </Columns>
            </form>
          </Columns>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={12} className="bg-content">
            <Columns>
              <Columns.Column size={3} className="search-table">
                <div className="field has-addons">
                  <div className="control">
                    <input
                      className="input search"
                      type="text"
                      placeholder="Pesquisar"
                      autoComplete="off"
                      onChange={setSearch}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="control">
                    <a
                      className="button is-info"
                      onClick={handleSearch}
                      >
                      <MdSearch
                        size={20}
                        color="#a9a9a9"
                      />
                    </a>
                  </div>
                </div>
              </Columns.Column>
              <Columns.Column size={9} className="has-text-right">
                <span className="total-register">Total de registros: <strong>{application.total}</strong></span>
              </Columns.Column>
            </Columns>
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <>
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th width="80" className="has-text-centered">Código</th>
                      <th>Cliente</th>
                      <th>Talhão</th>
                      <th>Cidades - Estado</th>
                      <th>Correção: Produtos / Forma de Aplicação / %</th>
                      <th>Informação</th>
                      <th width="180" className="has-text-centered">Ações</th>
                    </tr>
                  </thead>
                  {application.data.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="8" className="has-text-centered">Nenhum Registro encontrado :(</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {application.data.map((value, index) => (
                        <tr key={value.id}>
                          <td key={value.id} className="has-text-centered">{value.id}</td>
                          <td>{value.customer_name}</td>
                          <td>{value.field_name}</td>
                          <td>{value.city_name} - {value.state_name}</td>
                          <td className="block-table">
                            {(!empty(value.type_correction)) ? (
                              <>
                                <strong>Correção: </strong>
                                <div dangerouslySetInnerHTML={ {__html: value.type_correction} } />
                              </>
                            ) : ('') }
                            {(!empty(value.type_maintenance)) ? (
                              <>
                                <strong>Manutenção: </strong>
                                <div dangerouslySetInnerHTML={ {__html: value.type_maintenance} } />
                              </>
                            ) : ('') }
                            {(!empty(value.maintenance_and_correction)) ? (
                              <>
                                <strong>Correção e Manutenção: </strong>
                                <div dangerouslySetInnerHTML={ {__html: value.maintenance_and_correction} } />
                              </>
                            ) : ('') }
                            
                          </td>
                          <td>{value.description}</td>
                          <td width="180" className="has-text-centered">
                            <div className="button-action">
                              <div className="button-action">
                                <Link
                                  to={`/application/view/${value.id}`}
                                  className="button is-primary-circle"
                                >
                                  Ver Aplicação
                                </Link>
                              </div>
                            </div>
                            <div className="button-action">
                              <div className="button-action">
                                <Link
                                  to={`/application/edit/${value.id}`}
                                  className="button is-info is-primary-circle"
                                >
                                  Editar Aplicação
                                </Link>
                              </div>
                            </div>
                            {(admin) ? (
                              <div className="button-action">
                                <div className="button-action">
                                  <Button
                                    className="button is-danger is-primary-circle"
                                    onClick={() => deleteApplication(value.id, value.customer_name)}
                                  >
                                    Excluir Aplicação
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {!pageFilter ? (
                  <Pagination
                    activePage={application.current_page}
                    itemsCountPerPage={application.per_page}
                    totalItemsCount={application.total}
                    onChange={(pageNumber) => fetchData(pageNumber)}
                    prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                    firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                    nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                    lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
                  />
                ) : (
                  <Pagination
                    activePage={pageNro}
                    itemsCountPerPage={application.per_page}
                    totalItemsCount={application.total}
                    onChange={(pageNumber) => selectVisitByDate(pageNumber)}
                    prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                    firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                    nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                    lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
                  />
                )}
              </>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
