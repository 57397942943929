import React from 'react';
import { Link } from 'react-router-dom';
import { Columns } from 'react-bulma-components';
import { MdKeyboardBackspace } from 'react-icons/md';
import bg from '~/assets/bg-login.jpg';
import logo from '~/assets/logo.png';
import { Imagem, FormAuth } from './styles';


export default function ForgotPassword() {

  return (
    <Columns>
      <Columns.Column>
        <Imagem>
          <img src={bg} alt="" />
        </Imagem>
      </Columns.Column>
      <Columns.Column>
        <FormAuth>
          <div className="logo-form">
            <img src={logo} alt="Ceres Agricultura de precisão" />
          </div>
          <div className="description">
            <h1>Erro :(</h1>
            <h2>Desculpe, não conseguimos atender sua solicitação.</h2>
            <p>
              <Link to="/">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              /> Voltar para o inicio </Link>
            </p>
          </div>
          <div className="copyright">
            Todos os direitos reservados a Ceres Agricultura de Precisão.
          </div>
        </FormAuth>
      </Columns.Column>
    </Columns>
  );
}
