import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { format } from 'date-fns'

const DateApplication = (props) => {

  const [startDate, setStartDate] = useState(new Date());
  const [placeholderDate, setPlaceholder] = useState(localStorage.getItem("ceres_date_application"));

  useEffect(() => {
    if (props.setDefaultDateApplication) {
      var formatDate = format(new Date(props.setDefaultDateApplication), 'MM/dd/yyyy');
      setStartDate(new Date(formatDate));
    }
  }, [props.setDefaultDateApplication]);

  const date_regex = /^\d{2}\/\d{2}\/\d{4}$/;

  const handleChangeRaw = rawInput => {
    setStartDate(rawInput);
    if (date_regex.test(rawInput)) {
      setPlaceholder(rawInput);
      localStorage.setItem("ceres_date_application", rawInput);
    } else {
      if (rawInput === "") {
        let dateStr = localStorage.getItem("ceres_date_application");
        localStorage.setItem("ceres_date_application", dateStr);
        setPlaceholder(localStorage.getItem("ceres_date_application"));
      }
    }
  };

  const handleSelectedDate = selectedDate => {    
    let dateStr = moment(selectedDate)
      .format("MM/DD/YYYY")
      .toString();
    localStorage.setItem("ceres_date_application", dateStr);
    props.onChange(selectedDate);
    setStartDate(selectedDate);
  };

  return (
    <>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        onChange={date => handleSelectedDate(date)}
        onChangeRaw={e => handleChangeRaw(e.target.value)}
        placeholderText={placeholderDate}
        locale="pt"
        className="input"
        value={startDate}
      />
    </>
  );
};

export default DateApplication;
