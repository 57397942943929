import styled from 'styled-components';

export const FooterBg = styled.div`
  background-color: #ffffff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  -webkit-box-shadow: 0px -3px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -3px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -3px 15px 0px rgba(0, 0, 0, 0.1);

  position: relative;
  top: 50px;
  width: 100%;

  p {
    font-size: 14px;
    color: #0d593f;
  }
`;
