// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { default as NumberFormat } from 'react-number-format';
import { useForm } from 'react-hook-form';
import { format, addDays } from 'date-fns'

import api from '~/services/api';
import MenuConfig from '~/components/MenuConfig';
import { goalSaveRequest } from '~/store/modules/goal/actions';

import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
registerLocale("pt", pt);

export default function GoalAdd() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [goal, setGoal] = useState();
  const [user, setUser] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [typeGoal, setTypeGoal] = useState();
  const [endDate, setEndDate] = useState(addDays(new Date(), 60));

  const typeGoalChange = e => {
    setTypeGoal(e.target.value);
  }

  function onSubmit(data) {
    if(typeGoal === '1') {
      data.value = goal.value;
    }
    data.date_start = format(new Date(startDate), 'yyyy-MM-dd');
    data.date_end = format(new Date(endDate), 'yyyy-MM-dd');
    dispatch(goalSaveRequest(data));
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingUser(true);
      try {
        const response = await api.get('user/registers/all');
        setUser(response.data);
        setIsLoadingUser(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de meta</li>
            </ul>
            <h1>Cadastro de meta</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/goal/list">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuConfig />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Tipo da meta
                  </label>
                  <select name="name" ref={register()} className="select" onChange={typeGoalChange}>
                    <option className="placeholder">Selecione um tipo de meta</option>
                    <option value="0">AP</option>
                    <option value="1">Máquinas</option>
                  </select>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Meta para representante
                  </label>
                  <select name="user_id" ref={register()} className="select">
                    <option value="" className="placeholder">Selecione um representante</option>
                    { isLoadingUser ? (
                      <option>Carregando</option>
                    ) : (
                      user.map((value, index) => {
                        return <option value={value.id} key={index}>{value.name}</option>
                      })
                    )}
                  </select>
                </div>
              </div>

              <Columns className="no-padding">
                <Columns.Column size={6}>
                  <div className="field">
                    <label htmlFor="date_start" className="label">
                      Data de início
                    </label>
                    <div className="control">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        locale="pt"
                        selected={startDate}
                        onChange={date_start => setStartDate(date_start)}
                        className="input"
                        name="date_start"
                      />
                    </div>
                  </div>
                </Columns.Column>
                <Columns.Column size={6}>
                  <div className="field">
                    <label htmlFor="date_end" className="label">
                      Data fim
                    </label>
                    <div className="control">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        locale="pt"
                        selected={endDate}
                        onChange={date_end => setEndDate(date_end)}
                        className="input"
                        name="date_end"
                      />
                    </div>
                  </div>
                </Columns.Column>
              </Columns>

              {/* {typeGoal === '1' ? */}
                <div className="field">
                  <label htmlFor="value" className="label">
                    Valor em R$
                  </label>
                  <div className="control">
                    <NumberFormat
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator="."
                      prefix="R$ "
                      placeholder="R$ 100.000,00"
                      className="input"
                      getInputRef={(props) => (
                        <input name="value" type="text" ref={register} />
                      )}
                      onValueChange={(values) => {
                        const { value } = values;
                        setGoal({
                          ...goal,
                          value: value,
                        });
                      }}
                    />
                  </div>
                </div>
              {/* :
                <div className="field">
                  <label htmlFor="name" className="label">
                    Quantidade de hectares
                  </label>
                  <div className="control">
                    <input
                      name="qtde_hectares"
                      type="text"
                      ref={register({ required: true })}
                      placeholder="Informe a quantidade de hectares"
                      className="input"
                    />
                    {errors.qtde_hectares && errors.qtde_hectares.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
              } */}

              <div className="field">
                <div className="control">
                  <div className="checkbox-button">
                    <label className="checkbox">
                      <input
                        name="active"
                        ref={register()}
                        type="checkbox"
                      /> <span>Meta ativa</span></label>
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="name" className="label">
                  Detalhes da meta
                </label>
                <div className="control">
                  <textarea
                    name="description"
                    ref={register}
                    placeholder="Informações sobre a meta"
                    className="textarea"
                  />
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
