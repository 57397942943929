// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdHome, MdAddCircleOutline, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import Pagination from "react-js-pagination";

import api from '~/services/api';
import MenuMaps from '~/components/MenuMaps';

export default function MapsList() {
  const [maps, setMaps] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`maps?page=${pageNumber}`);
      setMaps(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const setSearch = e => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  async function handleSearch(){
    setIsLoading(true);
    if (searchInput !== '') {
      try {
        const response = await api.get(`maps/search/${searchInput}`);
        setMaps(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    } else {
      fetchData();
    }
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Lista de mapas</li>
            </ul>
            <h1>Lista de mapas</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/maps/create">
            <Button className="button is-primary">
              <MdAddCircleOutline
                size={15}
                color="#FFFFFF"
                className="icon-button-back"
              />
              Upload de mapa
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuMaps />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <Columns>
              <Columns.Column size={3} className="search-table">
                <div className="field has-addons">
                  <div className="control">
                    <input
                      className="input search"
                      type="text"
                      placeholder="Pesquisar"
                      autoComplete="off"
                      onChange={setSearch}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="control">
                    <a
                      className="button is-info"
                      onClick={handleSearch}
                      >
                      <MdSearch
                        size={20}
                        color="#a9a9a9"
                      />
                    </a>
                  </div>
                </div>
              </Columns.Column>
              <Columns.Column size={9} className="has-text-right">
                <span className="total-register">Total de registros: <strong>{maps.total}</strong></span>
              </Columns.Column>
            </Columns>
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <>
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th width="20" className="has-text-centered">#</th>
                      <th>Cliente</th>
                      <th>Talhão(ões)</th>
                      <th>Ano</th>
                      <th>Localização</th>
                      <th width="180" className="has-text-centered">Ações</th>
                    </tr>
                  </thead>
                  {maps.data.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="6" className="has-text-centered">Nenhum Registro encontrado :(</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {maps.data.map((value, index) => (
                        <tr key={value.id}>
                          {/* <td key={value.id} className="has-text-centered">{value.id}</td> */}
                          <td></td>
                          <td>{value.customer_name}</td>
                          <td>{value.fields_list}</td>
                          <td>{value.year}</td>
                          <td>{value.city_name} - {value.state_name}</td>
                          <td width="180" className="has-text-centered">
                            <div className="button-action">
                              <a href={`${value.maps_files_pdf}`} target="_blank"
                                className="button is-info"
                              >
                                Ver mapa talhão
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                <Pagination
                  activePage={maps.current_page}
                  itemsCountPerPage={maps.per_page}
                  totalItemsCount={maps.total}
                  onChange={(pageNumber) => fetchData(pageNumber)}
                  prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                  firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                  nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                  lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
                />
              </>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
