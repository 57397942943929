import React from 'react';
import { Columns } from 'react-bulma-components';
import { PieChart } from 'bizcharts';
import { ClientsMoreVisitsContainer } from './styles';

export default function ClientsMoreVisits({ data }) {

  const visits = [];
  {data.map((value, index) => (
    visits.push({
      'type': value.customer_name,
      'value': value.count_customer
    })
  ))}

  return (
    <ClientsMoreVisitsContainer>
      <Columns>
        <Columns.Column size={12}>
          <PieChart
            data={visits}
            radius={0.8}
            angleField='value'
            colorField='type'
            label={{
              visible: true,
              type: 'outer',
              offset: 20,
            }}
          />
        </Columns.Column>
      </Columns>
    </ClientsMoreVisitsContainer>
  );
}
