import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { productApplicationUpdateSuccess } from './actions';

export function* productApplicationSave({ payload }) {
  try {
    const response = yield call(api.post, 'product-application', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* productApplicationUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `product-application/${payload.id}`, payload.data);
    toast.success('Produto aplicação atualizado com sucesso!');
    yield put(productApplicationUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o produto aplicação.');
  }
}

export function* productApplicationDelete({ payload }) {
  try {
    yield call(api.delete, `product-application/${payload.product_application_id}`);
    toast.success('Produto aplicação excluído com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar o produto aplicação.');
  }
}

export default all([
  takeLatest('@productApplication/PRODUCT_APPLICATION_SAVE_REQUEST', productApplicationSave),
  takeLatest('@productApplication/PRODUCT_APPLICATION_UPDATE_REQUEST', productApplicationUpdate),
  takeLatest('@productApplication/DELETE_PRODUCT_APPLICATION_REQUEST', productApplicationDelete),
]);
