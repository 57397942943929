// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { MdHome, MdAddCircleOutline, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { RiFilterOffFill, RiFilterFill } from 'react-icons/ri';
import Pagination from "react-js-pagination";
import { format } from 'date-fns'

import api from '~/services/api';

// Kanban
import Board, { moveCard } from '@lourenci/react-kanban'
import { updateStatusSaveRequest } from '~/store/modules/sales/actions';

import DatePicker from "react-datepicker";

export default function SalesList() {
  const inputRefUser = useRef();
  const inputRefProduct = useRef();

  const inputRefStatus = useRef();
  const inputRefCustomer = useRef();
  const inputRefProductFilter = useRef();

  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  const [sales, setSales] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSalesList, setIsLoadingSalesList] = useState(true);
  const [isLoadingSelects, setIsLoadingSelects] = useState(true);
  const [board, setBoard] = useState([]);
  const [products, setProducts] = useState([]);

  const [filter, setFilter] = useState({});
  const [user, setUser] = useState([]);

  const [customers, setCustomers] = useState([]);

  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(board, source, destination);
    setBoard(updatedBoard);
    dispatch(updateStatusSaveRequest(_card.id, destination.toColumnId));
  }

  function convertToReal(number, options = {}) {
    const { moneySign = true } = options;
    if(Number.isNaN(number) || !number) return "need a number as the first parameter";
    if(typeof number === "string") {
      number = Number(number);
    }
    let res;
    const config = moneySign ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};
    moneySign
    ? res = number.toLocaleString('pt-BR', config)
    : res = number.toLocaleString('pt-BR', config)
    const needComma = number => number <= 1000;
    if(needComma(number)) {
      res = res.toString().replace(".", ",");
    }
    return res;
  }

  const fetchDataSalesList = async (pageNumber = 1) => {
    setIsLoadingSalesList(true);
    try {
      const response = await api.get(`sales?page=${pageNumber}`);
      setSalesList(response.data);
      setIsLoadingSalesList(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };
  useEffect(() => {
    fetchDataSalesList();
  }, []);

  const fetchDataUser = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/user/registers/all');
      setUser(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  const fetchDataProduct = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/product/registers/all');
      setProducts(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
    

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const vendas = await api.get('sales/status_type/0/0');
      const coletas = await api.get('sales/status_type/1/0');
      const laraborarios = await api.get('sales/status_type/2/0');
      const mapas_desenvolvimentos = await api.get('sales/status_type/3/0');
      const mapas_disponiveis = await api.get('sales/status_type/4/0');
      const mapas_nao_aceitos = await api.get('sales/status_type/5/0');

      setBoard({
        columns: [
          {
            id: 0,
            title: 'Venda',
            cards: vendas.data.data.map(venda => ({
              id: venda.id.toString(),
              title:  <div className="title-card">
                  <span>{venda.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${venda.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
                <div dangerouslySetInnerHTML={ {__html:
                  '<strong>Código:</strong> '+ venda.id +' <br /> '+
                  '<strong>Produto:</strong> '+ venda.product_name +' <br /> '+
                  '<strong>Valor:</strong> '+ convertToReal(venda.total) +' | <strong>Qtde Hect.:</strong> '+ venda.qtde_hectares +' <br /> '+
                  '<strong>Consultor:</strong> '+ venda.user_name
                } } />
            }))
          },
          {
            id: 1,
            title: 'Coleta',
            cards: coletas.data.data.map(coleta => ({
              id: coleta.id.toString(),
              title:  <div className="title-card">
                  <span>{coleta.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${coleta.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ coleta.id +' <br /> '+
                '<strong>Produto:</strong> '+ coleta.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(coleta.total) +' | <strong>Qtde Hect.:</strong> '+ coleta.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ coleta.user_name
              } } />
            }))
          },
          {
            id: 2,
            title: 'Laboratório',
            cards: laraborarios.data.data.map(laraborario => ({
              id: laraborario.id.toString(),
              title:  <div className="title-card">
                  <span>{laraborario.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${laraborario.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ laraborario.id +' <br /> '+
                '<strong>Produto:</strong> '+ laraborario.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(laraborario.total) +' | <strong>Qtde Hect.:</strong> '+ laraborario.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ laraborario.user_name
              } } />
            }))
          },
          {
            id: 3,
            title: 'Mapa em Desenvolvimento',
            cards: mapas_desenvolvimentos.data.data.map(mapas_desenvolvimento => ({
              id: mapas_desenvolvimento.id.toString(),
              title:  <div className="title-card">
                  <span>{mapas_desenvolvimento.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${mapas_desenvolvimento.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ mapas_desenvolvimento.id +' <br /> '+
                '<strong>Produto:</strong> '+ mapas_desenvolvimento.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(mapas_desenvolvimento.total) +' | <strong>Qtde Hect.:</strong> '+ mapas_desenvolvimento.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ mapas_desenvolvimento.user_name
              } } />
            }))
          },
          {
            id: 4,
            title: 'Mapa Disponível',
            cards: mapas_disponiveis.data.data.map(mapa_disponivel => ({
              id: mapa_disponivel.id.toString(),
              title:  <div className="title-card">
                  <span>{mapa_disponivel.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${mapa_disponivel.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ mapa_disponivel.id +' <br /> '+
                '<strong>Produto:</strong> '+ mapa_disponivel.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(mapa_disponivel.total) +' | <strong>Qtde Hect.:</strong> '+ mapa_disponivel.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ mapa_disponivel.user_name
              } } />
            }))
          },
          {
            id: 5,
            title: 'Mapa não Aceito',
            cards: mapas_nao_aceitos.data.data.map(mapas_nao_aceito => ({
              id: mapas_nao_aceito.id.toString(),
              title:  <div className="title-card">
                  <span>{mapas_nao_aceito.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${mapas_nao_aceito.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ mapas_nao_aceito.id +' <br /> '+
                '<strong>Produto:</strong> '+ mapas_nao_aceito.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(mapas_nao_aceito.total) +' | <strong>Qtde Hect.:</strong> '+ mapas_nao_aceito.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ mapas_nao_aceito.user_name
              } } />
            }))
          }
        ]
      });

      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  const fetchDataCustomer = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/customer/registers/all');
      setCustomers(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  useEffect(() => {
    fetchDataCustomer();
    fetchDataUser();
    fetchDataProduct();
    fetchData();
  }, []);

  function cleanInputsFilter() {
    if (admin) {
      inputRefUser.current.value = '';
      inputRefProduct.current.value = '';
    }
    setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    fetchData();
  }

  const handleSubmit = async (event)=> {
    event.preventDefault();
    setIsLoading(true);
    var refUser = '';
    var refProduct = '';
    if (admin) {
      refUser = inputRefUser.current.value;
      refProduct = inputRefProduct.current.value;
    } else {
      refUser = inputRefUser.current.value;
      refProduct = inputRefProduct.current.value;
    }
    setFilter({
      params: {
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        user_id: refUser,
        product_id: refProduct,
      }
    });

    try {
      const vendas = await api.get('sales/status_type/0/0', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
          product_id: refProduct,
        }
      });
      const coletas = await api.get('sales/status_type/1/0', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
          product_id: refProduct,
        }
      });
      const laraborarios = await api.get('sales/status_type/2/0', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
          product_id: refProduct,
        }
      });
      const mapas_desenvolvimentos = await api.get('sales/status_type/3/0', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
          product_id: refProduct,
        }
      });
      const mapas_disponiveis = await api.get('sales/status_type/4/0', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
          product_id: refProduct,
        }
      });
      const mapas_nao_aceitos = await api.get('sales/status_type/5/0', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
          product_id: refProduct,
        }
      });

      setBoard({
        columns: [
          {
            id: 0,
            title: 'Venda',
            cards: vendas.data.data.map(venda => ({
              id: venda.id.toString(),
              title:  <div className="title-card">
                  <span>{venda.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${venda.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
                <div dangerouslySetInnerHTML={ {__html:
                  '<strong>Código:</strong> '+ venda.id +' <br /> '+
                  '<strong>Produto:</strong> '+ venda.product_name +' <br /> '+
                  '<strong>Valor:</strong> '+ convertToReal(venda.total) +' | <strong>Qtde Hect.:</strong> '+ venda.qtde_hectares +' <br /> '+
                  '<strong>Consultor:</strong> '+ venda.user_name
                } } />
            }))
          },
          {
            id: 1,
            title: 'Coleta',
            cards: coletas.data.data.map(coleta => ({
              id: coleta.id.toString(),
              title:  <div className="title-card">
                  <span>{coleta.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${coleta.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ coleta.id +' <br /> '+
                '<strong>Produto:</strong> '+ coleta.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(coleta.total) +' | <strong>Qtde Hect.:</strong> '+ coleta.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ coleta.user_name
              } } />
            }))
          },
          {
            id: 2,
            title: 'Laboratório',
            cards: laraborarios.data.data.map(laraborario => ({
              id: laraborario.id.toString(),
              title:  <div className="title-card">
                  <span>{laraborario.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${laraborario.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ laraborario.id +' <br /> '+
                '<strong>Produto:</strong> '+ laraborario.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(laraborario.total) +' | <strong>Qtde Hect.:</strong> '+ laraborario.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ laraborario.user_name
              } } />
            }))
          },
          {
            id: 3,
            title: 'Mapa em Desenvolvimento',
            cards: mapas_desenvolvimentos.data.data.map(mapas_desenvolvimento => ({
              id: mapas_desenvolvimento.id.toString(),
              title:  <div className="title-card">
                  <span>{mapas_desenvolvimento.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${mapas_desenvolvimento.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ mapas_desenvolvimento.id +' <br /> '+
                '<strong>Produto:</strong> '+ mapas_desenvolvimento.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(mapas_desenvolvimento.total) +' | <strong>Qtde Hect.:</strong> '+ mapas_desenvolvimento.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ mapas_desenvolvimento.user_name
              } } />
            }))
          },
          {
            id: 4,
            title: 'Mapa Disponível',
            cards: mapas_disponiveis.data.data.map(mapa_disponivel => ({
              id: mapa_disponivel.id.toString(),
              title:  <div className="title-card">
                  <span>{mapa_disponivel.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${mapa_disponivel.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ mapa_disponivel.id +' <br /> '+
                '<strong>Produto:</strong> '+ mapa_disponivel.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(mapa_disponivel.total) +' | <strong>Qtde Hect.:</strong> '+ mapa_disponivel.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ mapa_disponivel.user_name
              } } />
            }))
          },
          {
            id: 5,
            title: 'Mapa não Aceito',
            cards: mapas_nao_aceitos.data.data.map(mapas_nao_aceito => ({
              id: mapas_nao_aceito.id.toString(),
              title:  <div className="title-card">
                  <span>{mapas_nao_aceito.customer_name}</span>
                  <span>
                    <Link to={`/sales/ap/edit/${mapas_nao_aceito.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ mapas_nao_aceito.id +' <br /> '+
                '<strong>Produto:</strong> '+ mapas_nao_aceito.product_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(mapas_nao_aceito.total) +' | <strong>Qtde Hect.:</strong> '+ mapas_nao_aceito.qtde_hectares +' <br /> '+
                '<strong>Consultor:</strong> '+ mapas_nao_aceito.user_name
              } } />
            }))
          }
        ]
      });

      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  }


  function cleanInputsFilterMachine() {
    inputRefCustomer.current.value = '';
    inputRefProductFilter.current.value = '';
    inputRefStatus.current.value = '';
    
    setStartDate(new Date(date.getFullYear(), 0, 1));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    fetchDataSalesList();
  }

  const handleSubmitMachine = event => {
    event.preventDefault();
    setIsLoading(true);
    var refCustomer = '';
    var refProduct = '';
    var refStatus = '';
    if (inputRefCustomer.current.value !== undefined) {
      refCustomer = inputRefCustomer.current.value;
    } else {
      refCustomer = '';
    }
    if (inputRefProductFilter.current.value !== undefined) {
      refProduct = inputRefProductFilter.current.value;
    } else {
      refProduct = '';
    }
    if (inputRefStatus.current.value !== undefined) {
      refStatus = inputRefStatus.current.value;
    } else {
      refStatus = '';
    }

    setFilter({
      params: {
        customer_id: refCustomer,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        product_id: refProduct,
        status: refStatus,
      }
    });
    api.get(`/sales/search_status_type_rep/0`, {
      params: {
        customer_id: refCustomer,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        product_id: refProduct,
        status: refStatus,
      },
    }).then(response => {
      setSalesList(response.data);
      setIsLoading(false);
    });

  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Lista de vendas - Agricultura de Precisão</li>
            </ul>
            <h1>Lista de vendas - Agricultura de Precisão</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/sales/ap/create">
            <Button className="button is-primary">
              <MdAddCircleOutline
                size={15}
                color="#FFFFFF"
                className="icon-button-back"
              />
              Cadastrar venda
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      {(admin || profile.roles[0].name === 'escritorio') ?
        <Columns>
          <Columns.Column>
            <Columns>
              <form className="manager filter">
                <Columns>
                  <Columns.Column>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de responsável
                        </label>
                        <select name="user_id" ref={inputRefUser} className="select">
                          <option value="" className="placeholder">Responsável</option>
                          { isLoadingSelects ? (
                            <option>Carregando</option>
                          ) : (
                            user.map((value, index) => {
                              return <option value={value.id} key={index}>{value.name}</option>
                            })
                          )}
                        </select>
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column size={admin ? 2 : 4}>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de produtos
                        </label>
                        <select name="product_id" ref={inputRefProduct} className="select">
                          <option value="" className="placeholder">Produto</option>
                          { isLoadingSelects ? (
                            <option>Carregando</option>
                          ) : (
                            products.map((value, index) => {
                              return <option value={value.id} key={index}>{value.name}</option>
                            })
                          )}
                        </select>
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column size={admin ? 2 : 4}>
                    <label htmlFor="name" className="label">
                      Filtro entre data
                    </label>
                    <div className="field inline">
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="input"
                            popperPlacement="top-right"
                          />
                        </div>
                      </div>
                      <span className="between-dates">a</span>
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className="input"
                            popperPlacement="top-right"
                          />
                        </div>
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column size={admin ? '' : 2}>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control has-text-right">
                            <button onClick={handleSubmit} className="button is-info">
                              <RiFilterFill
                                size={15}
                                color="#FFFFFF"
                                className="icon-button-back"
                              />
                              Filtrar dados
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <div className="button-clean" onClick={cleanInputsFilter} >
                      <RiFilterOffFill
                        size={15}
                        color="#FFFFFF"
                        className="icon-button-back"
                      /> Limpar filtros
                    </div>
                  </Columns.Column>
                </Columns>
              </form>
            </Columns>
          </Columns.Column>
        </Columns>
      : (
        <Columns>
          <Columns.Column>
            <Columns>
              <form className="manager filter">
                <Columns>
                  <Columns.Column size={2}>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de status
                        </label>
                        <select name="user_id" ref={inputRefStatus} className="select">
                          <option value="" className="placeholder">Status</option>
                          <option value="0" className="placeholder">Venda</option>
                          <option value="1" className="placeholder">Coleta</option>
                          <option value="2" className="placeholder">Laboratório</option>
                          <option value="3" className="placeholder">Mapa em Desenvolvimento</option>
                          <option value="4" className="placeholder">Mapa Disponível</option>
                          <option value="5" className="placeholder">Mapa não Aceito</option>
                        </select>
                      </div>
                    </div>
                  </Columns.Column>
                  
                  <Columns.Column size={2}>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de cliente
                        </label>
                        <select name="user_id" ref={inputRefCustomer} className="select">
                          <option value="" className="placeholder">Cliente</option>
                          { isLoadingSelects ? (
                            <option>Carregando</option>
                          ) : (
                            customers.map((value, index) => {
                              return <option value={value.id} key={index}>{value.name}</option>
                            })
                          )}
                        </select>
                      </div>
                    </div>
                  </Columns.Column>

                  <Columns.Column size={2}>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de produto
                        </label>
                        <select name="user_id" ref={inputRefProductFilter} className="select">
                          <option value="" className="placeholder">Produto</option>
                          { isLoadingSelects ? (
                            <option>Carregando</option>
                          ) : (
                            products.map((value, index) => {
                              return <option value={value.id} key={index}>{value.name}</option>
                            })
                          )}
                        </select>
                      </div>
                    </div>
                  </Columns.Column>

                  <Columns.Column size={4}>
                    <label htmlFor="name" className="label">
                      Filtro entre data
                    </label>
                    <div className="field inline">
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="input"
                            popperPlacement="top-right"
                          />
                        </div>
                      </div>
                      <span className="between-dates">a</span>
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className="input"
                            popperPlacement="top-right"
                          />
                        </div>
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column size={admin ? '' : 2}>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control has-text-right">
                            <button onClick={handleSubmitMachine} className="button is-info">
                              <RiFilterFill
                                size={15}
                                color="#FFFFFF"
                                className="icon-button-back"
                              />
                              Filtrar dados
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <div className="button-clean" onClick={cleanInputsFilterMachine} >
                      <RiFilterOffFill
                        size={15}
                        color="#FFFFFF"
                        className="icon-button-back"
                      /> Limpar filtros
                    </div>
                  </Columns.Column>
                </Columns>
              </form>
            </Columns>
          </Columns.Column>
        </Columns>
      )}

      <div className="content">
        { isLoading ? (
          <div className="loading">
            <ImSpinner11
              size={24}
              color="#2a775a"
              className="fa-spin"
            /> Carregando ...
          </div>
        ) : (
          <>
            {(profile.roles[0].name === 'admin' || profile.roles[0].name === 'escritorio') ? (
              <Board
                onCardDragEnd={handleCardMove}
                disableColumnDrag
              >
                {board}
              </Board>
            ) : (
              <Columns.Column className="bg-content">
                <Columns>
                  <Columns.Column size={3} className="search-table">                    
                  </Columns.Column>
                  <Columns.Column size={9} className="has-text-right">
                    <span className="total-register">Total de registros: <strong>{salesList.total}</strong></span>
                  </Columns.Column>
                </Columns>
                {isLoading ? (
                  <div className="loading">
                  <ImSpinner11
                    size={24}
                    color="#2a775a"
                    className="fa-spin"
                  /> Carregando ...</div>
                ) : (
                  <>
                    <table className="table is-striped">
                      <thead>
                        <tr>
                          <th width="80" className="has-text-centered">Código</th>
                          <th width="80" className="has-text-centered">Cliente</th>
                          <th width="80" className="has-text-centered">Total da venda</th>
                          <th width="80" className="has-text-centered">Produto</th>
                          <th width="80" className="has-text-centered">Status</th>
                          <th width="80" className="has-text-centered">Ações</th>
                        </tr>
                      </thead>
                      {salesList.data.length === 0 ? (
                        <tbody>
                          <tr>
                            <td colSpan="6" className="has-text-centered">Nenhum Registro encontrado :(</td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {salesList.data.map((value, index) => (
                            <tr key={value.id}>
                              <td key={value.id} className="has-text-centered">{value.id}</td>
                              <td className="has-text-centered">{value.customer_name}</td>
                              <td className="has-text-centered">{convertToReal(value.total)}</td>
                              <td className="has-text-centered">{value.product_name}</td>
                              <td className="has-text-centered">
                                <div className="button-action status-sales">
                                  {value.status === "0" ? (
                                    <span>Venda</span>
                                  ) : ('')}
                                  {value.status === "1" ? (
                                    <span>Coleta</span>
                                  ) : ('')}
                                  {value.status === "2" ? (
                                    <span>Laboratório</span>
                                  ) : ('')}
                                  {value.status === "3" ? (
                                    <span>Mapa em Desenvolvimento</span>
                                  ) : ('')}
                                  {value.status === "4" ? (
                                    <span>Mapa Disponível</span>
                                  ) : ('')}
                                  {value.status === "5" ? (
                                    <span>Mapa não Aceito</span>
                                  ) : ('')}
                                </div>
                              </td>
                              <td width="180" className="has-text-centered">
                                <div className="button-action">
                                  {value.status === "0" ? (
                                    <Link
                                      to={`/sales/ap/edit/${value.id}`}
                                      className="button is-info"
                                    >
                                      Editar
                                    </Link>
                                  ) : (
                                    ''
                                  )}
                                  {value.status === "1" ? (
                                    <Link
                                      to={`/sales/ap/show/${value.id}`}
                                      className="button is-info"
                                    >
                                      Visualizar
                                    </Link>
                                  ) : (
                                    ''
                                  )}
                                  {value.status === "2" ? (
                                    <Link
                                      to={`/sales/ap/show/${value.id}`}
                                      className="button is-info"
                                    >
                                      Visualizar
                                    </Link>
                                  ) : (
                                    ''
                                  )}
                                  {value.status === "3" ? (
                                    <Link
                                      to={`/sales/ap/show/${value.id}`}
                                      className="button is-success"
                                    >
                                      Visualizar
                                    </Link>
                                  ) : ('')}
                                  {value.status === "4" ? (
                                    <Link
                                      to={`/sales/ap/show/${value.id}`}
                                      className="button is-info"
                                    >
                                      Visualizar
                                    </Link>
                                  ) : ('')}
                                  {value.status === "5" ? (
                                    <Link
                                      to={`/sales/ap/show/${value.id}`}
                                      className="button is-info"
                                    >
                                      Visualizar
                                    </Link>
                                  ) : ('')}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                    <Pagination
                      activePage={salesList.current_page}
                      itemsCountPerPage={salesList.per_page}
                      totalItemsCount={salesList.total}
                      onChange={(pageNumber) => fetchDataSalesList(pageNumber)}
                      prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                      firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                      nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                      lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
                    />
                  </>
                )}
              </Columns.Column>
            )}
          </>
        )}
      </div>
    </div>
  );
}
