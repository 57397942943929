export function settingUpdateRequest(data) {
  return {
    type: '@setting/SETTING_UPDATE_REQUEST',
    payload: { data },
  };
}

export function settingUpdateSuccess() {
  return {
    type: '@setting/SETTING_UPDATE_SUCCESS',
  };
}
