import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 0 15px;
  -webkit-box-shadow: 0px 7px 12px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 7px 12px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 12px 1px rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
      padding-right: 20px;
    }

    a.link-header {
      font-weight: bold;
      color: #a3aeb7;
      padding: 0 15px;
      font-size: 14px;
      line-height: 100px;

      &.menu-icon{
        position: relative;
        top: 8px;
      }

      &:hover,
      &:focus,
      &:active,
      &.active {
        color: #2a775a;
      }
    }
  }

  aside {
    display: flex;
    align-items: center;
  }

  .navbar-dropdown {
    min-width: 220px;
    line-height: 50px;
    padding: 10px 20px;

    a {
      display: inline-block;
      width: 100%;
      color: #a3aeb7;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  margin-right: 20px;

  .icon-header-profile {
    text-align: right;
    padding: 0 12px;

    span {
      font-size: 12px;
      color: #a3aeb7;
      font-weight: 600;
      vertical-align: middle;
      position: relative;
      top: -7px;
      left: 8px;
    }
  }

  img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }
`;
