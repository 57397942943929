// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck, MdAddCircleOutline } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import MenuVisit from '~/components/MenuVisit';
import { visitUpdateRequest } from '~/store/modules/visit/actions';

export default function VisitEdit() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const [reason, setReason] = useState([]);
  const [formService, setFormService] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [visit, setVisit] = useState([]);

  const EditVisit = () => {
    const { id } = useParams();
    return id;
  };
  const visit_id = EditVisit();

  const onChange = e => {
    setVisit({...visit,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    dispatch(visitUpdateRequest(visit_id, data));
  }

  useEffect(() => {
    const fetchDataVisit = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`/visit/${visit_id}`);
        setVisit(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataVisit();
  }, []);

  useEffect(() => {
    const fetchDataCustomer = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('customer/registers/all');
        setCustomer(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataCustomer();
  }, []);

  useEffect(() => {
    const fetchDataFormService = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('form-service/registers/all');
        setFormService(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataFormService();
  }, []);

  useEffect(() => {
    const fetchDataReason = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('reason/registers/all');
        setReason(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataReason();
  }, []);

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de cliente</li>
            </ul>
            <h1>Cadastro de cliente</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/visits/list">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuVisit />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <form className="manager" onSubmit={handleSubmit(onSubmit)}>
                <input name="user_id" type="hidden" ref={register} defaultValue={visit.user_id}/>
                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Cliente
                    </label>
                    <select name="customer_id" ref={register({ required: true })} defaultValue={customer.customer_id} className="select" onChange={onChange}>
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        customer.map((value, index) => {
                          return <option value={value.id} key={index} selected={visit.customer_id === value.id} >{value.name}</option>
                        })
                      )}
                    </select>
                    {errors.customer_id && errors.customer_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Forma de atendimento
                    </label>
                    <div className="radio-button">
                      { isLoading ? (
                        <span>Carregando</span>
                      ) : (
                        formService.map((value, index) => {
                          return <label htmlFor={`form_service_id_${value.id}`} className="radio" key={index}>
                            <input type="radio" id={`form_service_id_${value.id}`} ref={register({ required: true })} name="form_service_id" value={value.id} defaultChecked={visit.form_service_id === value.id} />
                            <span>{value.name}</span>
                          </label>
                        })
                      )}
                    </div>
                    {errors.form_service_id && errors.form_service_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Motivo da visita
                    </label>
                    <select name="reason_id" ref={register({ required: true })} defaultValue={reason.reason_id} className="select" onChange={onChange}>
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        reason.map((value, index) => {
                          return <option value={value.id} key={index} selected={visit.reason_id === value.id} >{value.name}</option>
                        })
                      )}
                    </select>
                    {errors.reason_id && errors.reason_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="car" className="label">
                    Carro
                  </label>
                  <div className="control">
                    <input
                      name="car"
                      type="text"
                      ref={register()}
                      placeholder="Informe o automóvel da viagem"
                      className="input"
                      defaultValue={visit.car}
                    />
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="km_inicial" className="label">
                    KM Inicial
                  </label>
                  <div className="control">
                    <input
                      name="km_inicial"
                      type="text"
                      ref={register()}
                      placeholder="Informe o KM inicial"
                      className="input"
                      defaultValue={visit.km_inicial}
                    />
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="description" className="label">
                    Descrição da visita
                  </label>
                  <div className="control">
                    <textarea
                      name="description"
                      ref={register}
                      placeholder="Informe os dados da visita"
                      className="textarea"
                      defaultValue={visit.description}
                    />
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control has-text-right">
                        <button type="submit" className="button is-primary">
                          <MdCheck
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          />
                          Salvar dados
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
