import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { signOut } from '~/store/modules/auth/actions';

export default function Logout() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(signOut());
    }, []);


    return (
        <h3>
            Redirecionando
        </h3>
    )

}