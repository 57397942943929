// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { default as NumberFormat } from 'react-number-format';
import { useForm } from 'react-hook-form';
import { format, addDays } from 'date-fns'

import MenuConfig from '~/components/MenuConfig';
import api from '~/services/api';
import { goalUpdateRequest } from '~/store/modules/goal/actions';

import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
registerLocale("pt", pt);

export default function GoalEdit() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [goal, setGoal] = useState();
  const [user, setUser] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 60));

  const EditGoal = () => {
    const { id } = useParams();
    return id;
  };
  const goal_id = EditGoal();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/goal/${goal_id}`);
        setGoal(response.data);
        var formatDateStart = format(new Date(response.data.date_start), 'MM/dd/yyyy');
        var formatDateEnd = format(new Date(response.data.date_end), 'MM/dd/yyyy');
        setStartDate(new Date(formatDateStart))
        setEndDate(new Date(formatDateEnd))
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingUser(true);
      try {
        const response = await api.get('user/registers/all');
        setUser(response.data);
        setIsLoadingUser(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  const onChange = e => {
    setGoal({...goal,
      [e.target.name]: e.target.value}
    );
  };


  function onSubmit(data) {
    data.value = goal.value;
    data.date_start = format(new Date(startDate), 'yyyy-MM-dd');
    data.date_end = format(new Date(endDate), 'yyyy-MM-dd');
    dispatch(goalUpdateRequest(goal_id, data));
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de meta</li>
            </ul>
            <h1>Cadastro de meta</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/goal/list">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuConfig />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <form className="manager" onSubmit={handleSubmit(onSubmit)}>

                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Tipo da meta
                    </label>
                    <select name="name" ref={register()} defaultValue={goal.name} className="select" onChange={onChange}>
                      <option className="placeholder">Selecione um tipo de meta</option>
                      <option value="0">AP</option>
                      <option value="1">Máquinas</option>
                    </select>
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Meta para representante
                    </label>
                    <select name="user_id" ref={register()} defaultValue={goal.user_id} className="select" onChange={onChange} >
                      <option value="" className="placeholder">Selecione um representante</option>
                      { isLoadingUser ? (
                        <option>Carregando</option>
                      ) : (
                        user.map((value, index) => {
                          return <option value={value.id} key={index} selected={goal.user_id === value.id} >{value.name}</option>
                        })
                      )}
                    </select>
                  </div>
                </div>

                <Columns className="no-padding">
                  <Columns.Column size={6}>
                    <div className="field">
                      <label htmlFor="date_start" className="label">
                        Data de início
                      </label>
                      <div className="control">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          locale="pt"
                          selected={startDate}
                          onChange={date_start => setStartDate(date_start)}
                          className="input"
                          name="date_start"
                        />
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column size={6}>
                    <div className="field">
                      <label htmlFor="date_end" className="label">
                        Data fim
                      </label>
                      <div className="control">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          locale="pt"
                          selected={endDate}
                          onChange={date_end => setEndDate(date_end)}
                          className="input"
                          name="date_end"
                        />
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>

                {/* {goal.name === '1' ? */}
                  <div className="field">
                    <label htmlFor="value" className="label">
                    Valor em R$
                    </label>
                    <div className="control">
                      <NumberFormat
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={"."}
                        isNumericString={true}
                        prefix={'R$ '}
                        placeholder="Ex.: R$ 0,00"
                        className="input"
                        value={goal.value}
                        onValueChange={(values) => {
                          const { value } = values;
                          setGoal({...goal, value: value})}
                        }
                        getInputRef={(props) =>
                          <input name="value" type="text" value={goal.value} ref={register}/>
                        }
                      />
                    </div>
                  </div>
                {/* :
                  <div className="field">
                    <label htmlFor="name" className="label">
                    Quantidade de hectares
                    </label>
                    <div className="control">
                      <input
                        name="qtde_hectares"
                        type="text"
                        ref={register({ required: true })}
                        placeholder="Informe a quantidade de hectares"
                        className="input"
                        defaultValue={goal.qtde_hectares}
                      />
                      {errors.qtde_hectares && errors.qtde_hectares.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                } */}

                <div className="field">
                  <div className="control">
                    <div className="checkbox-button">
                      <label className="checkbox">
                        <input
                          name="active"
                          ref={register()}
                          type="checkbox"
                          defaultChecked={goal.active === "1" ? true : false}
                        /> <span>Meta ativa</span></label>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="name" className="label">
                    Detalhes da meta
                  </label>
                  <div className="control">
                    <textarea
                      name="description"
                      ref={register}
                      placeholder="Informações sobre a meta"
                      className="textarea"
                      defaultValue={goal.description}
                    />
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control has-text-right">
                        <button type="submit" className="button is-primary">
                          <MdCheck
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          />
                          Salvar dados
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
