import React, { useEffect } from 'react';

const ModalReportsAgentTypeServices = props => {
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return (
    <>
      <div className={props.show ? 'report modal is-active' : 'report modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Relatório de serviços oferecidos</p>
            <button
              onClick={props.onClose}
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <form action="">

            </form>
          </section>
        </div>
      </div>
    </>
  )
}

export default ModalReportsAgentTypeServices;
