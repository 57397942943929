import styled from 'styled-components';

export const Imagem = styled.div`
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`;

export const FormAuth = styled.div`
  display: flex;
  flex-wrap: wrap; // Quebra a linha
  flex-direction: row; //Direção que você deseja
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  .form-manager {
    width: 500px;
    display: block;
  }

  .logo-form {
    width: 100%;
    margin-bottom: 20%;

    img {
      max-width: 350px;
      margin: 0 auto;
      display: block;
    }
  }

  .form {
    width: 100%;

    input {
      margin-bottom: 5px;
    }

    .forgot-password a,
    label.checkbox {
      font-weight: 400;
      color: #0d593f;
      font-size: 14px !important;
    }
    .forgot-password {
      width: 100%;
      text-align: right;
    }

    .button {
      width: 100%;
      font-weight: bold;
      margin-top: 10px;
    }
  }
`;
