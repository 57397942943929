import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { formApplicationSaveSuccess } from './actions';

export function* formApplicationSave({ payload }) {
  try {
    const response = yield call(api.post, 'form-application', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* formApplicationUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `form-application/${payload.id}`, payload.data);
    toast.success('Forma de aplicação atualizada com sucesso!');
    yield put(formApplicationSaveSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a forma de aplicação.');
  }
}

export function* formApplicationDelete({ payload }) {
  try {
    yield call(api.delete, `form-application/${payload.form_application_id}`);
    toast.success('Forma de aplicação excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a forma de aplicação.');
  }
}

export default all([
  takeLatest('@formApplication/FORM_APPLICATION_SAVE_REQUEST', formApplicationSave),
  takeLatest('@formApplication/FORM_APPLICATION_UPDATE_REQUEST', formApplicationUpdate),
  takeLatest('@formApplication/DELETE_FORM_APPLICATION_REQUEST', formApplicationDelete),
]);
