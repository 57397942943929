// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck, MdAddCircleOutline, MdZoomIn } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { default as NumberFormat } from 'react-number-format';
import { format } from 'date-fns'

import api from '~/services/api';
import { salesSaveRequest } from '~/store/modules/sales/actions';

import imageDefault from '../../assets/no-photo.svg';
import DatePicker, { registerLocale }  from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
registerLocale("pt", pt); // register it with the name you want



export default function SalesAdd() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingField, setIsLoadingField] = useState(true);
  const [sales, setSales] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [fields, setFields] = useState([]);
  const [products, setProducts] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [productOpt, setProductOpt] = useState(0);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [registrationOk, setRegistrationOk] = useState(false);
  const [modalErroPedido, setModalErroPedido] = useState("");
  const [modalRegistrationOk, setModalRegistrationOk] = useState("");
  const [modalUpdateRegistration, setModalUpdateRegistration] = useState();

  const handleChangeImage = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  async function onSubmit(data) {
    if(image.preview) {
      setModalErroPedido('');
      const formData = new FormData();
      formData.append("image", image.raw);

      const response = await api.post('files', formData);
      const { id, path } = response.data;

      data.file_id = id;

      data.delivery = format(new Date(startDate), 'yyyy-MM-dd');
      data.total = sales.total;
      dispatch(salesSaveRequest(data));
    } else {
      setModalErroPedido("is-active");
    }
  }

  const setProductSelect = e => {
    setProductOpt(e.target.value);
  }


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/customer/registers/all');
      setCustomers(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const fetchDataField = async (e) => {
  //   setIsLoadingField(true);
  //   if (e.target.value !== '') {
  //     try {
  //       const response = await api.get(`field/find-by-customer/${e.target.value}`);
  //       setFields(response.data);
  //       setIsLoadingField(false);
  //     } catch (error) {
  //       console.log('Error: '+error);
  //     }
  //   }
  // };

  const handleClickCloseModal = e => {
    setRegistrationOk(false);
    setModalRegistrationOk('');
    setValue('customer_id', '');
  };
  const handleClickCloseModalErroFoto = e => {
    setModalErroPedido('');
  };

  const fetchDataCompleteRegistration = async (e) => {
    setRegistrationOk(false);
    if (e.target.value !== '') {
      setModalUpdateRegistration(e.target.value);
      try {
        const response = await api.get(`/customer/check-registration-complete/${e.target.value}`);
        if (response.data.success === false) {
          setModalRegistrationOk("is-active");
          setRegistrationOk(true);
        }
      } catch (error) {
        console.log('Error: '+error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/product/registers/all');
        setProducts(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);



  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de Venda - Agricultura de Precisão</li>
            </ul>
            <h1>Cadastro de Venda - Agricultura de Precisão</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/sales/ap/list">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <div className={`modal ${modalRegistrationOk}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Atenção</p>
                <button
                  onClick={handleClickCloseModal}
                  className="delete"
                  aria-label="close"
                />
              </header>
              <section className="modal-card-body">
                <h2>Cadastro de cliente selecionado está incompleto, favor verificar!</h2>
                <Link
                  to={`/registrations/customer-edit/${modalUpdateRegistration}`}
                  className="button is-primary button"
                >
                  Atualizar cadastro do cliente
                </Link>
              </section>
            </div>
          </div>

          <div className={`modal ${modalErroPedido}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Atenção</p>
                <button
                  onClick={handleClickCloseModalErroFoto}
                  className="delete"
                  aria-label="close"
                />
              </header>
              <section className="modal-card-body">
                <h2>É obrigatório o envio da foto/imagem do pedido!</h2>
              </section>
            </div>
          </div>

          <Columns.Column size={12} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>

              <input name="user_id" type="hidden" ref={register} defaultValue={profile.id}/>
              <input name="type" type="hidden" ref={register} value="0"/>

              <div className="field">
                <label htmlFor="devilery" className="label">
                  Data do pedido
                </label>
                <div className="control">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    className="input"
                    name="delivery"
                  />
                </div>
              </div>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Cliente
                      </label>
                      {/* <select name="customer_id" ref={register({ required: true })} className="select" onBlur={fetchDataField} onChange={fetchDataCompleteRegistration}> */}
                      <select name="customer_id" ref={register({ required: true })} className="select" onChange={fetchDataCompleteRegistration}>
                        <option value="" className="placeholder" selected disabled>Selecione um cliente</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          customers.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.customer_id && errors.customer_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                {profile.roles[0].name === 'admin' ? (
                  <Columns.Column size={1}>
                    <Link to="/registrations/customer-create" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Cliente
                    </Link>
                  </Columns.Column>
                ) : (
                  ''
                )}
              </Columns>


              {/* <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Talhão
                      </label>
                      <select name="field_id" ref={register({ required: true })} className="select">
                        <option value="" className="placeholder" selected disabled>Selecione um talhão</option>
                        { isLoadingField ? (
                          <option>Carregando</option>
                        ) : (
                          fields.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.field_id && errors.field_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                {profile.roles[0].name === 'admin' ? (
                  <Columns.Column size={1}>
                    <Link to="/registrations/field" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Talhão
                    </Link>
                  </Columns.Column>
                ) : (
                  ''
                )}
              </Columns> */}

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Serviço vendido
                      </label>
                      <select name="product_id" ref={register({ required: true })} className="select" onChange={setProductSelect} >
                        <option value="" className="placeholder" selected disabled>Selecione um produto</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          products.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.product_id && errors.product_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                {profile.roles[0].name === 'admin' ? (
                  <Columns.Column size={1}>
                    <Link to="/registrations/product" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Produto
                    </Link>
                  </Columns.Column>
                ) : (
                  ''
                )}
              </Columns>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Área
                      </label>
                      <div className="control">
                        <input
                          name="qtde_hectares"
                          type="text"
                          ref={register()}
                          placeholder="Informe a quantidade hectáres"
                          className="input"
                        />
                      </div>
                    </div>
                    {errors.qtde_hectares && errors.qtde_hectares.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </Columns.Column>
              </Columns>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column>
                  <div className="field">
                    <label htmlFor="total" className="label">
                      Total venda
                    </label>
                    <div className="control">
                      <NumberFormat
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          thousandSeparator="."
                          prefix="R$ "
                          placeholder="R$ 0,00"
                          className="input"
                          getInputRef={(props) => (
                            <input name="total" type="text" ref={register} />
                          )}
                          onValueChange={(values) => {
                            const { value } = values;
                            setSales({
                              ...sales,
                              total: value,
                            });
                          }}
                        />
                    </div>
                  </div>
                </Columns.Column>
              </Columns>

              <div className="icon-zoom-div">
                <label htmlFor="name" className="label">
                  Foto/Imagem do Pedido
                </label>
                <label htmlFor="upload-button" className="imagem-sales-upload-label">
                  {image.preview ? (
                    <img src={image.preview} alt="" className="imagem-sales-upload uploaded"/>
                  ) : (
                    <img src={imageDefault} alt="" className="imagem-sales-upload"/>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChangeImage}
                />
                <div className="icon-zoom">
                  <a href={image.preview} target="blank">
                    <MdZoomIn size={20} color="#A3AEB7" />
                  </a>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
