// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns'

import api from '~/services/api';
import MenuVisit from '~/components/MenuVisit';
import { visitSaveRequest } from '~/store/modules/visit/actions';

import DatePicker, { registerLocale }  from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
registerLocale("pt", pt); // register it with the name you want

export default function VisitAdd() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const [reason, setReason] = useState([]);
  const [formService, setFormService] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  function onSubmit(data) {
    data.created_at = format(new Date(startDate), 'yyyy-MM-dd');
    dispatch(visitSaveRequest(data));
  }

  useEffect(() => {
    const fetchDataCustomer = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('customer/registers/all');
        setCustomer(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataCustomer();
  }, []);

  useEffect(() => {
    const fetchDataFormService = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('form-service/registers/all');
        setFormService(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataFormService();
  }, []);

  useEffect(() => {
    const fetchDataReason = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('reason/registers/all');
        setReason(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataReason();
  }, []);

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de cliente</li>
            </ul>
            <h1>Cadastro de cliente</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/visits/list">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuVisit />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>

              <input name="user_id" type="hidden" ref={register} defaultValue={profile.id}/>

              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Cliente
                  </label>
                  <select name="customer_id" ref={register({ required: true })} className="select">
                    <option value="" className="placeholder" selected disabled>Selecione um cliente</option>
                    { isLoading ? (
                      <option>Carregando</option>
                    ) : (
                      customer.map((value, index) => {
                        return <option value={value.id} key={index}>{value.name}</option>
                      })
                    )}
                  </select>
                  {errors.customer_id && errors.customer_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Forma de atendimento
                  </label>
                  <div className="radio-button">
                    { isLoading ? (
                      <span>Carregando</span>
                    ) : (
                      formService.map((value, index) => {
                        return <label for={`form_service_id_${value.id}`} className="radio"><input type="radio" id={`form_service_id_${value.id}`} ref={register({ required: true })} name="form_service_id" value={value.id} /><span>{value.name}</span></label>
                      })
                    )}
                  </div>
                  {errors.form_service_id && errors.form_service_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Motivo da visita
                  </label>
                  <select name="reason_id" ref={register({ required: true })} className="select">
                    <option value="" className="placeholder" selected disabled>Selecione o motivo da visita</option>
                    { isLoading ? (
                      <option>Carregando</option>
                    ) : (
                      reason.map((value, index) => {
                        return <option value={value.id} key={index}>{value.name}</option>
                      })
                    )}
                  </select>
                  {errors.reason_id && errors.reason_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              {/* <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Cliente estava interessado em
                  </label>
                  <select name="product_id" ref={register({ required: true })} className="select">
                    <option value="" className="placeholder" selected disabled>Selecione um produto/serviço</option>
                    { isLoading ? (
                      <option>Carregando</option>
                    ) : (
                      product.map((value, index) => {
                        return <option value={value.id} key={index}>{value.name}</option>
                      })
                    )}
                  </select>
                  {errors.product_id && errors.product_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div> */}

              <div className="field">
                <label htmlFor="car" className="label">
                  Carro
                </label>
                <div className="control">
                  <input
                    name="car"
                    type="text"
                    ref={register()}
                    placeholder="Informe o automóvel da viagem"
                    className="input"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="km_inicial" className="label">
                  KM chegada no cliente
                </label>
                <div className="control">
                  <input
                    name="km_inicial"
                    type="text"
                    ref={register()}
                    placeholder="Informe o KM de chegada no cliente"
                    className="input"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="description" className="label">
                  Descrição da visita
                </label>
                <div className="control">
                  <textarea
                    name="description"
                    ref={register}
                    placeholder="Informe os dados da visita"
                    className="textarea"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="devilery" className="label">
                  Data da visita
                </label>
                <div className="control">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    className="input"
                    name="created_at"
                  />
                </div>
              </div>


              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
