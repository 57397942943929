import styled from 'styled-components';

export const Menu = styled.div`
  padding: 0;
  margin: 0;
  list-style: none;

  a {
    display: block;
    margin: 10px 0;
    color: #a3aeb7;
    font-weight: 700;
    letter-spacing: -0.3px;

    &.selected{
      color: #0D593F;
    }
  }

  .column{
    padding: 0 !important;
  }
`;
