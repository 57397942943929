export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signUpRequest(name, email, password) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { name, email, password },
  };
}

export function forgotRequest(email) {
  return {
    type: '@auth/FORGOT_REQUEST',
    payload: { email },
  };
}

export function forgotSuccess() {
  return {
    type: '@auth/FORGOT_SUCCESS',
  };
}

export function changePassRequest(data) {
  return {
    type: '@auth/CHANGE_PASS_REQUEST',
    payload: { data },
  };
}

export function changePassSuccess() {
  return {
    type: '@auth/CHANGE_PASS_SUCCESS',
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_IN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
