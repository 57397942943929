// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdAddCircleOutline, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { FiTrash, FiX } from 'react-icons/fi';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";

import api from '~/services/api';
import MenuRegistrations from '~/components/MenuRegistrations';
import { segmentDeleteRequest } from '~/store/modules/segment/actions';

export default function SegmentList() {
  const dispatch = useDispatch();
  const [segment, setSegment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`collections/ap?page=${pageNumber}`);
      setSegment(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Coletas</li>
            </ul>
            <h1>Lista de coletas em aberto</h1>
          </div>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={12} className="bg-content">
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <>
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th width="150" className="has-text-centered">Código da venda</th>
                      <th>Data de venda</th>
                      <th>Cliente</th>
                      <th>Cidade / Estado</th>
                      <th>Área</th>
                    </tr>
                  </thead>
                  {segment.data.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="5" className="has-text-centered">Nenhum Registro encontrado :(</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {segment.data.map((value, index) => (
                        <tr key={value.id}>
                          <td key={value.id} className="has-text-centered">{value.id}</td>
                          <td>{value.formatted_delivery}</td>
                          <td>{value.customer_name}</td>
                          <td>{value.city_name} / {value.state_name}</td>
                          <td>{value.qtde_hectares} ha</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
