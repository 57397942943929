// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck, MdAddCircleOutline } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import MenuRegistrations from '~/components/MenuRegistrations';
import { farmSaveRequest } from '~/store/modules/farm/actions';

export default function FarmAdd() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  function onSubmit(data) {
    dispatch(farmSaveRequest(data));
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('customer/registers/all');
        setCustomer(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('states');
        setStates(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  const findyCity = e => {
    const fetchCities = async () => {
      if (e.target.value !== '') {
        try {
          const response = await api.get(`cities/${e.target.value}`);
          setCities(response.data);
        } catch (error) {
          console.log('Error: '+error);
        }
      }
    };
    fetchCities();
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de fazenda</li>
            </ul>
            <h1>Cadastro de fazenda</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/farm">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <label htmlFor="name" className="label">
                  Nome da fazenda
                </label>
                <div className="control">
                  <input
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o nome da fazenda"
                    className="input"
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={11}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Cliente
                      </label>
                      <select name="customer_id" ref={register({ required: true })} className="select">
                        <option value="" className="placeholder" selected disabled>Selecione um cliente</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          customer.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.customer_id && errors.customer_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                <Columns.Column size={1}>
                  <Link to="/registrations/customer-create" className="link-add">
                    <MdAddCircleOutline
                      size={24}
                      color="#3273dc"
                    />
                    Cliente
                  </Link>
                </Columns.Column>
              </Columns>


              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Estado da fazenda
                  </label>
                  <select name="state_id" ref={register({ required: true })} className="select" onBlur={findyCity}>
                    <option value="" className="placeholder" selected disabled>Selecione um estado</option>
                    { isLoading ? (
                      <option>Carregando</option>
                    ) : (
                      states.map((value, index) => {
                        return <option value={value.id} key={index}>{value.title}</option>
                      })
                    )}
                  </select>
                  {errors.state_id && errors.state_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label htmlFor="name" className="label">
                    Cidade da fazenda
                  </label>
                  <select name="city_id" ref={register({ required: true })} className="select">
                    <option value="" className="placeholder" selected disabled>Selecione uma cidade</option>
                    { isLoading ? (
                      <option>Carregando</option>
                    ) : (
                      cities.map((value, index) => {
                        return <option value={value.id} key={index}>{value.title}</option>
                      })
                    )}
                  </select>
                  {errors.city_id && errors.city_id.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
