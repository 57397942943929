export function updateProfileRequest(id, data) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: { id, data },
  };
}

export function updateProfileSuccess(profile) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  };
}

export function updateProfilefailure() {
  return {
    type: '@user/UPDATE_PROFILE_FAILURE',
  };
}


// Manutenções de Usuários
export function userSaveRequest(data) {
  return {
    type: '@user/USER_SAVE_REQUEST',
    payload: { data },
  };
}

export function userSaveSuccess(data) {
  return {
    type: '@user/USER_SAVE_SUCCESS',
    payload: { data },
  };
}

export function userUpdateRequest(id, data) {
  return {
    type: '@user/USER_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function userUpdateSuccess() {
  return {
    type: '@user/USER_UPDATE_SUCCESS',
  };
}

export function userDeleteRequest(user_id) {
  return {
    type: '@user/DELETE_USER_REQUEST',
    payload: { user_id },
  };
}

export function userActiveRequest(user_id) {
  return {
    type: '@user/ACTIVE_USER_REQUEST',
    payload: { user_id },
  };
}
