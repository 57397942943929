import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { reasonUpdateSuccess } from './actions';

export function* reasonSave({ payload }) {
  try {
    const response = yield call(api.post, 'reason', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* reasonUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `reason/${payload.id}`, payload.data);
    toast.success('Motivo atualizado com sucesso!');
    yield put(reasonUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o motivo.');
  }
}

export function* reasonDelete({ payload }) {
  try {
    yield call(api.delete, `reason/${payload.reason_id}`);
    toast.success('Motivo excluído com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar o motivo.');
  }
}

export default all([
  takeLatest('@reason/REASON_SAVE_REQUEST', reasonSave),
  takeLatest('@reason/REASON_UPDATE_REQUEST', reasonUpdate),
  takeLatest('@reason/DELETE_REASON_REQUEST', reasonDelete),
]);
