import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Columns, Button } from 'react-bulma-components';
import { ImSpinner11 } from 'react-icons/im';

import api from '~/services/api';
import { signOut } from '~/store/modules/auth/actions';
import { updateProfileRequest } from '~/store/modules/user/actions';

import userDefault from '../../assets/user-default.svg';

export default function Profile() {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState({ preview: "", raw: "" });

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await api.get(`/user/${profile.id}`);
        const responseFile = await api.get(`/files/${response.data.file_id}`);
        setImage(image => ({
          ...image,
          preview: responseFile.data.path
        }));
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };
    fetchData();
  }, []);

  async function onSubmit(data) {

    if(image.raw) {
      const formData = new FormData();
      formData.append("image", image.raw);

      const response = await api.post('files', formData);
      const { id, path } = response.data;

      data.file_id = id;
    }

    dispatch(updateProfileRequest(profile.id, data));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column size={3}>
        </Columns.Column>
        <Columns.Column size={6}>
          {isLoading ? (
            <div className="loading">
            <ImSpinner11
              size={24}
              color="#2a775a"
              className="fa-spin"
            /> Carregando ...</div>
          ) : (
            <form className="manager profile" onSubmit={handleSubmit(onSubmit)}>
              <center>
                <label htmlFor="upload-button" className="imagem-avatar-upload-label">
                  {image.preview ? (
                    <img src={image.preview} alt="" className="imagem-avatar-upload"/>
                  ) : (
                    <img src={userDefault} alt="" className="imagem-avatar-upload"/>
                  )}
                  <span>Imagem de 150x150 pixels</span>
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </center>
              <div className="field">
                <label htmlFor="name" className="label">
                  Nome completo
                </label>
                <div className="control">
                  <input
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o seu nome completo"
                    className="input"
                    defaultValue={profile.name}
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <label htmlFor="email" className="label">
                  E-mail
                </label>
                <div className="control">
                  <input
                    name="email"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o seu email"
                    className="input"
                    defaultValue={profile.email}
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <hr />

              <div className="field">
                <label htmlFor="old_password" className="label">
                  Senha atual
                </label>
                <div className="control">
                  <input
                    name="old_password"
                    type="password"
                    ref={register()}
                    className="input"
                    placeholder="********"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="password" className="label">
                  Nova senha
                </label>
                <div className="control">
                  <input
                    name="password"
                    type="password"
                    ref={register()}
                    className="input"
                    placeholder="********"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="password_confirmation" className="label">
                  Confirmação de senha
                </label>
                <div className="control">
                  <input
                    name="password_confirmation"
                    type="password"
                    ref={register()}
                    className="input"
                    placeholder="********"
                  />
                </div>
              </div>

              <Button className="button is-primary" type="submit">Atualizar perfil</Button>
              <Button className="button is-danger" type="submit" onClick={handleSignOut}>
                Sair
              </Button>
            </form>
          )}
        </Columns.Column>
        <Columns.Column size={3}>
        </Columns.Column>
      </Columns>
    </div>
  );
}
