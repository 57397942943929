export function applicationSaveRequest(data) {
  return {
    type: '@application/APPLICATION_SAVE_REQUEST',
    payload: { data },
  };
}

export function applicationSaveSuccess(data) {
  return {
    type: '@application/APPLICATION_SAVE_SUCCESS',
    payload: { data },
  };
}

export function applicationUpdateRequest(id, data) {
  return {
    type: '@application/APPLICATION_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function applicationUpdateSuccess() {
  return {
    type: '@application/APPLICATION_UPDATE_SUCCESS',
  };
}

export function applicationDeleteRequest(application_id) {
  return {
    type: '@application/DELETE_APPLICATION_REQUEST',
    payload: { application_id },
  };
}
