export function salesSaveRequest(data) {
  return {
    type: '@sales/SALES_SAVE_REQUEST',
    payload: { data },
  };
}

export function salesSaveSuccess(data) {
  return {
    type: '@sales/SALES_SAVE_SUCCESS',
    payload: { data },
  };
}

export function salesUpdateRequest(id, data) {
  return {
    type: '@sales/SALES_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function salesUpdateSuccess() {
  return {
    type: '@sales/SALES_UPDATE_SUCCESS',
  };
}

export function updateStatusSaveRequest(sales_id, status) {
  return {
    type: '@sales/SALES_STATUS_UPDATE_REQUEST',
    payload: { sales_id, status },
  };
}

export function salesDeleteRequest(sales_id) {
  return {
    type: '@sales/DELETE_SALES_REQUEST',
    payload: { sales_id },
  };
}
