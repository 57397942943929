import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Menu } from './styles';

export default function MenuMaps() {

  const profile = useSelector((state) => state.user.profile);
  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  const isActive = {
    fontWeight: "bold",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  };

  return (
    <Menu>
      { (admin || profile.roles[0].name === 'escritorio') ?
        <>
          <NavLink to="/maps/list" activeClassName="selected">Lista de mapas</NavLink>
          <NavLink to="/maps/create" activeClassName="selected">Cadastro de mapa</NavLink>
        </>
      :
        <NavLink to="/maps/list" activeClassName="selected">Lista de mapas</NavLink>
      }

    </Menu>
  );
}
