export function customerSaveRequest(data) {
  return {
    type: '@customer/CUSTOMER_SAVE_REQUEST',
    payload: { data },
  };
}

export function customerSaveSuccess(data) {
  return {
    type: '@customer/CUSTOMER_SAVE_SUCCESS',
    payload: { data },
  };
}

export function customerUpdateRequest(id, data) {
  return {
    type: '@customer/CUSTOMER_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function customerUpdateSuccess() {
  return {
    type: '@customer/CUSTOMER_UPDATE_SUCCESS',
  };
}

export function customerDeleteRequest(customer_id) {
  return {
    type: '@customer/DELETE_CUSTOMER_REQUEST',
    payload: { customer_id },
  };
}
