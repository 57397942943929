// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck, MdAddCircleOutline, MdZoomIn } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { default as NumberFormat } from 'react-number-format';
import { format } from 'date-fns'

import api from '~/services/api';
import { salesUpdateRequest } from '~/store/modules/sales/actions';

import imageDefault from '../../assets/no-photo.svg';
import imageDefaultColeta from '../../assets/no-photo.svg';
import DatePicker, { registerLocale }  from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
registerLocale("pt", pt); // register it with the name you want

export default function SalesAdd() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingField, setIsLoadingField] = useState(true);
  const [sales, setSales] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [fields, setFields] = useState([]);
  const [products, setProducts] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [count, setCount] = useState(0);
  const [productOpt, setProductOpt] = useState(0);
  const [image, setImage] = useState({ id: "", preview: "", raw: "" });
  const [imageColeta, setImageColeta] = useState({ id: "", preview: "", raw: "" });
  const [fieldsSales, setFieldsSales] = useState([{ field_id:null }]);
  const [fieldsSalesSave, setFieldsSalesSave] = useState([]);

  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  const EditSales = () => {
    const { id } = useParams();
    return id;
  };
  const sales_id = EditSales();


  // Add fields dynamics
  function handleAdd() {
    const values = [...fieldsSales];
    values.push({ field_id:null });
    setFieldsSales(values);
  }
  function handleRemove(i, field_sale_id) {
    if (field_sale_id) {
      const values = [...fieldsSales];
      values.splice(i, 1);
      setFieldsSales(values);
      api.put(`sales/delete-field/${sales_id}/${field_sale_id}`);
    } else {
      const values = [...fieldsSales];
      values.splice(i, 1);
      setFieldsSales(values);
    }

  }
  const handleChangeFields = (i, event) => {
    const values = [...fieldsSales];
    values[i].field_id = event.target.value;
    setFieldsSales(values);
    setFieldsSalesSave({...fieldsSalesSave,
      fields: values
    });
  }

  function handleChangeImage(e, type) {
    if (e.target.files.length) {
      if (type === 'pedido') {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0]
        });
      } else {
        setImageColeta({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0]
        });
      }
    }
  };

  async function onSubmit(data) {
    if(image.raw) {
      const formData = new FormData();
      formData.append("image", image.raw);

      const response = await api.post('files', formData);
      const { id, path } = response.data;

      data.file_id = id;
    } else {
      data.file_id = image.id;
    }

    if(imageColeta.raw) {
      const formDataColeta = new FormData();
      formDataColeta.append("image", imageColeta.raw);

      const responseColeta = await api.post('files', formDataColeta);
      const { id, path } = responseColeta.data;

      data.collection_form_file_id = id;
    } else {
      data.collection_form_file_id = imageColeta.id;
    }
    data.delivery = format(new Date(startDate), 'yyyy-MM-dd');
    data.total = sales.total;
    data.fields = fieldsSalesSave.fields;
    dispatch(salesUpdateRequest(sales_id, data));
  }

  const setProductSelect = e => {
    setProductOpt(e.target.value);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/sales/${sales_id}`);
        setSales(response.data);
        var formatDate = format(new Date(response.data.delivery), 'MM/dd/yyyy');
        const responseFile = await api.get(`/files/${response.data.file_id}`);
        const responseFileColeta = await api.get(`/files/${response.data.collection_form_file_id}`);
        setImage(image => ({
          ...image,
          id: response.data.file_id,
          preview: responseFile.data.path
        }));
        setImageColeta(image => ({
          ...image,
          id: response.data.collection_form_file_id,
          preview: responseFileColeta.data.path
        }));
        setStartDate(new Date(formatDate))
        const response_field_initial_fields = await api.get(`/sales/fields/${response.data.id}`);
        setFieldsSales(response_field_initial_fields.data.data);
        if (response_field_initial_fields.data.total === 0){
          const response_field_initial = await api.get(`field/find-by-customer/${response.data.customer_id}`);
          setFieldsSales(response_field_initial.data);
          setFields(response_field_initial.data);
        }
        fetchDataField(response.data.customer_id, 'effect');

        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/customer/registers/all');
        setCustomers(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingField(true);
      try {
        const response = await api.get('/field/registers/all');
        setFields(response.data);
        setIsLoadingField(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  const fetchDataField = async (e, type) => {
    let valueCustomer = '';
    if (type === 'effect'){
      valueCustomer = e;
    } else {
      valueCustomer = e.target.value;
    }
    setIsLoadingField(true);
    try {
      const response = await api.get(`field/find-by-customer/${valueCustomer}`);
      setFields(response.data);
      setIsLoadingField(false);
      fieldsSales([{ field_id:null }]);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/product/registers/all');
        setProducts(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);



  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Atualizar Venda - Agricultura de Precisão</li>
            </ul>
            <h1>Atualizar Venda - Agricultura de Precisão</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/sales/ap/list">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={12} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>

              <input name="user_id" type="hidden" ref={register} defaultValue={sales.user_id}/>
              <input name="type" type="hidden" ref={register} value="0"/>

              <div className="field">
                <label htmlFor="devilery" className="label">
                  Data do pedido
                </label>
                <div className="control">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    className="input"
                    name="delivery"
                  />
                </div>
              </div>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Cliente
                      </label>
                      <select name="customer_id" ref={register({ required: true })} defaultValue={sales.customer_id} className="select" onChange={fetchDataField}>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          customers.map((value, index) => {
                            return <option value={value.id} key={index} selected={sales.customer_id === value.id} >{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.customer_id && errors.customer_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                {profile.roles[0].name === 'admin' ? (
                  <Columns.Column size={1}>
                    <Link to="/registrations/customer-create" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Cliente
                    </Link>
                  </Columns.Column>
                ) : (
                  ''
                )}
              </Columns>

              {sales.status >= '2' ? (
                <Columns className="is-gapless no-margin-bottom">
                  <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                    {fieldsSales.map((field, idx) => {
                      return (
                        <Columns className="no-padding" key={`${field}-${idx}`}>
                          <Columns.Column size={12} className="no-padding">
                            <div className="field">
                              <div className="control">
                                <label htmlFor="name" className="label">
                                  Talhão
                                </label>
                                <select name={`field_id-${idx}`} ref={register({ required: true })} className="select" onBlur={e => handleChangeFields(idx, e)} >
                                  <option defaultValue="" className="placeholder">Selecione o talhão</option>
                                  { isLoadingField ? (
                                    <option>Carregando</option>
                                  ) : (
                                    <>
                                      {(fieldsSales.length > 0) ? (
                                        fields.map((value, index) => {
                                          return <option value={value.id} key={index} selected={field.id === value.id} >{value.name}</option>
                                        })
                                      ) : (
                                        fields.map((value, index) => {
                                          return <option value={value.id} key={index} selected={sales.field_id === value.id} >{value.name}</option>
                                        })
                                      )}
                                    </>
                                  )}
                                </select>
                                {errors.field_id && errors.field_id.type === 'required' && (
                                  <span className="error-inputs">
                                    Este campo é obrigatório
                                  </span>
                                )}
                              </div>
                            </div>
                          </Columns.Column>
                          <div className="action">
                            <button type="button" className="button is-danger no-padding" onClick={() => handleRemove(idx, field.field_id)}>Remover talhão</button>
                          </div>
                        </Columns>
                      );
                    })}
                    <Columns className="no-padding">
                      <Columns.Column size={12} className="no-padding">
                        <div className="action-right">
                          <button type="button" className="button is-primary no-padding" onClick={() => handleAdd()}>Adicionar campos</button>
                        </div>
                      </Columns.Column>
                    </Columns>
                  </Columns.Column>
                  {profile.roles[0].name === 'admin' ? (
                    <Columns.Column size={1}>
                      <Link to="/registrations/field" className="link-add">
                        <MdAddCircleOutline
                          size={24}
                          color="#3273dc"
                        />
                        Talhão
                      </Link>
                    </Columns.Column>
                  ) : (
                    ''
                  )}
                </Columns>
              ) : ('')}

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Serviço vendido
                      </label>
                      <select name="product_id" ref={register({ required: true })} defaultValue={sales.product_id} className="select" onChange={setProductSelect} >
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          products.map((value, index) => {
                            return <option value={value.id} key={index} selected={sales.product_id === value.id} >{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.product_id && errors.product_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                {profile.roles[0].name === 'admin' ? (
                  <Columns.Column size={1}>
                    <Link to="/registrations/product" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Produto
                    </Link>
                  </Columns.Column>
                ) : (
                  ''
                )}
              </Columns>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Área
                      </label>
                      <div className="control">
                        <input
                          name="qtde_hectares"
                          type="text"
                          ref={register()}
                          placeholder="Informe a quantidade hectáres"
                          className="input"
                          defaultValue={sales.qtde_hectares}
                        />
                      </div>
                    </div>
                    {errors.qtde_hectares && errors.qtde_hectares.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </Columns.Column>
              </Columns>

              {(sales.status >= "1") ? (
                <>
                  <div className="field">
                    <label htmlFor="name" className="label">
                      Área da coleta
                    </label>
                    <div className="control">
                      <input
                        name="qtde_hectares_coleta"
                        type="text"
                        ref={register()}
                        placeholder="Informe a área total da coleta"
                        className="input"
                        defaultValue={sales.qtde_hectares_coleta}
                      />
                    </div>
                  </div>
                </>
              ) : ('')}

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column>
                  <div className="field">
                    <label htmlFor="price" className="label">
                      Total venda
                    </label>
                    <div className="control">
                      <NumberFormat
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={"."}
                        isNumericString={true}
                        prefix={'R$ '}
                        placeholder="Ex.: R$ 0,00"
                        className="input"
                        value={sales.total}
                        onValueChange={(values) => {
                          const { value } = values;
                          setSales({...sales, total: value})}
                        }
                        getInputRef={(props) =>
                          <input name="total" type="text" value={sales.total} ref={register}/>
                        }
                      />
                    </div>
                  </div>
                </Columns.Column>
              </Columns>

              {(sales.status >= "2") && (admin) ? (
                <>
                  <div className="field">
                    <label htmlFor="name" className="label">
                      Para quem faturar?
                    </label>
                    <div className="control">
                      <input
                        name="invoice"
                        type="text"
                        ref={register({ required: true })}
                        placeholder="Para quem faturar?"
                        className="input"
                        defaultValue={sales.invoice}
                      />
                    </div>
                    {errors.invoice && errors.invoice.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>

                  <div className="field">
                    <label htmlFor="name" className="label">
                      Qual modelo?
                    </label>
                    <div className="control">
                      <input
                        name="model"
                        type="text"
                        ref={register({ required: true })}
                        placeholder="Qual modelo?"
                        className="input"
                        defaultValue={sales.model}
                      />
                    </div>
                    {errors.model && errors.model.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </>
              ) : ('')}


              {(sales.status >= "5") ? (
                <>
                  <div className="field">
                    <label htmlFor="name" className="label">
                      Motivo do mapa não ser aceito
                    </label>
                    <div className="control">
                      <textarea
                        name="reason_rejection"
                        ref={register}
                        placeholder="Informe o motivo pelo qual o mapa não foi aceito..."
                        className="textarea"
                        defaultValue={sales.reason_rejection}
                      />
                    </div>
                  </div>
                </>
              ) : ('')}

              <div className="icon-zoom-div">
                <label htmlFor="name" className="label">
                  Foto/Imagem do Pedido
                </label>
                <label htmlFor="upload-button-pedido" className="imagem-sales-upload-label">
                  {image.preview ? (
                    <img src={image.preview} alt="" className="imagem-sales-upload uploaded"/>
                  ) : (
                    <img src={imageDefault} alt="" className="imagem-sales-upload"/>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-button-pedido"
                  style={{ display: "none" }}
                  name="file_id"
                  onChange={(e) => handleChangeImage(e, 'pedido')}
                  ref={register}
                />
                <div className="icon-zoom">
                  <a href={image.preview} target="blank">
                    <MdZoomIn size={20} color="#A3AEB7" />
                  </a>
                </div>
              </div>

              {(sales.status >= "1") ? (
                <>
                  <br />
                  <label htmlFor="name" className="label">
                    Foto/Imagem da ficha de coleta
                  </label>
                  <div className="icon-zoom-div">
                    <label htmlFor="upload-button-ficha" className="imagem-sales-upload-label">
                      {imageColeta.preview ? (
                        <img src={imageColeta.preview} alt="" className="imagem-sales-upload uploaded" ref={register}/>
                      ) : (
                        <img src={imageDefaultColeta} alt="" className="imagem-sales-upload" ref={register}/>
                      )}
                    </label>
                    <input
                      type="file"
                      name="collection_form_file_id"
                      id="upload-button-ficha"
                      style={{ display: "none" }}
                      onChange={(e) => handleChangeImage(e, 'ficha')}
                      ref={register}
                    />
                    <div className="icon-zoom">
                      <a href={imageColeta.preview} target="blank">
                        <MdZoomIn size={20} color="#A3AEB7" />
                      </a>
                    </div>
                  </div>
                </>
              ) : ('')}



              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
