import React from 'react';
import { Columns } from 'react-bulma-components';
import { PieChart } from 'bizcharts';
import { ReasinVisitContainer } from './styles';

export default function ReasonVisit({ data }) {
  const reasons = [];
  if(data.length === 0){
    reasons.push({
      'value': 0
    })
  } else {
    {data.map((value, index) => (
      reasons.push({
        'type': value.reasons_name,
        'value': value.count_reason
      })
    ))}
  }

  return (
    <ReasinVisitContainer>
      <Columns>
        <Columns.Column size={12}>
          <PieChart
            data={reasons}
            radius={0.8}
            angleField='value'
            colorField='type'
            label={{
              visible: true,
              type: 'outer',
              offset: 20,
            }}
          />
        </Columns.Column>
      </Columns>
    </ReasinVisitContainer>
  );
}
