export function goalSaveRequest(data) {
  return {
    type: '@goal/GOAL_SAVE_REQUEST',
    payload: { data },
  };
}

export function goalSaveSuccess(data) {
  return {
    type: '@goal/GOAL_SAVE_SUCCESS',
    payload: { data },
  };
}

export function goalUpdateRequest(id, data) {
  return {
    type: '@goal/GOAL_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function goalUpdateSuccess() {
  return {
    type: '@goal/GOAL_UPDATE_SUCCESS',
  };
}

export function goalDeleteRequest(goal_id) {
  return {
    type: '@goal/DELETE_GOAL_REQUEST',
    payload: { goal_id },
  };
}
