// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdAddCircleOutline, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { FiTrash, FiX } from 'react-icons/fi';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";

import api from '~/services/api';
import MenuRegistrations from '~/components/MenuRegistrations';
import { productDeleteRequest } from '~/store/modules/product/actions';

export default function ProductList() {
  const dispatch = useDispatch();
  const [product, setProduct] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`product?page=${pageNumber}`);
      setProduct(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const setSearch = e => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  async function handleSearch(){
    setIsLoading(true);
    if (searchInput !== '') {
      try {
        const response = await api.get(`product/search/${searchInput}`);
        setProduct(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    } else {
      fetchData();
    }
  }

  function deleteProduct(product_id, name) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-confirm-delete">
            <div className="title">
              Confirma a exclusão do produto/serviço: <strong>{name}</strong>?{' '}
            </div>
            <button onClick={onClose}>
              <FiX size="18px" /> Cancelar exclusão
            </button>
            <button
              className="yes"
              onClick={() => {
                handleClickDelete(product_id);
                onClose();
                fetchData();
              }}
            >
              <FiTrash size="18px" /> Sim, pode excluir!
            </button>
          </div>
        );
      },
    });
  }

  function handleClickDelete(product_id) {
    dispatch(productDeleteRequest(product_id));
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Lista de produtos</li>
            </ul>
            <h1>Lista de produtos</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/product-create">
            <Button className="button is-primary">
              <MdAddCircleOutline
                size={15}
                color="#FFFFFF"
                className="icon-button-back"
              />
              Cadastrar produto
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <Columns>
              <Columns.Column size={3} className="search-table">
                <div className="field has-addons">
                  <div className="control">
                    <input
                      className="input search"
                      type="text"
                      placeholder="Pesquisar"
                      autoComplete="off"
                      onChange={setSearch}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <div className="control">
                    <a
                      className="button is-info"
                      onClick={handleSearch}
                      >
                      <MdSearch
                        size={20}
                        color="#a9a9a9"
                      />
                    </a>
                  </div>
                </div>
              </Columns.Column>
              <Columns.Column size={9} className="has-text-right">
                <span className="total-register">Total de registros: <strong>{product.total}</strong></span>
              </Columns.Column>
            </Columns>
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <>
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th width="80" className="has-text-centered">Código</th>
                      <th>Nome do produto/serviço</th>
                      {/* <th>Valor do produto/serviço</th> */}
                      <th>Cor</th>
                      <th width="180" className="has-text-centered">Ações</th>
                    </tr>
                  </thead>
                  {product.data.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="5" className="has-text-centered">Nenhum Registro encontrado :(</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {product.data.map((value, index) => (
                        <tr key={value.id}>
                          <td key={value.id} className="has-text-centered">{value.id}</td>
                          <td>{value.name}</td>
                          <td>
                            <div className="bg-table-product" style={{backgroundColor: value.color}}></div>
                          </td>
                          <td width="180" className="has-text-centered">
                            <div className="button-action">
                              <Link
                                to={`/registrations/product-edit/${value.id}`}
                                className="button is-info"
                              >
                                Editar
                              </Link>
                              <Button
                                className="button is-danger"
                                onClick={() => deleteProduct(value.id, value.name)}
                              >
                                Excluir
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                <Pagination
                  activePage={product.current_page}
                  itemsCountPerPage={product.per_page}
                  totalItemsCount={product.total}
                  onChange={(pageNumber) => fetchData(pageNumber)}
                  prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                  firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                  nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                  lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
                />
              </>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
