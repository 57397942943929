import styled from 'styled-components'

export const StyledDropzoneContainer = styled.div`
  margin: 20px 0 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;

  h4{
    font-size: 14px !important;
    margin: 10px 0;
  }
`;
export const StyledDropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;
