import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ChangePassword from '../pages/ForgotPassword/change_password';
import Error_404 from '../pages/404';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';

// Customer
import Customer from '../pages/Customer';
import CreateCustomer from '../pages/Customer/add';
import EditCustomer from '../pages/Customer/edit';
// Farm
import Farm from '../pages/Farm';
import CreateFarm from '../pages/Farm/add';
import EditFarm from '../pages/Farm/edit';
// Field
import Field from '../pages/Field';
import CreateField from '../pages/Field/add';
import EditField from '../pages/Field/edit';
// FormService
import FormService from '../pages/FormService';
import CreateFormService from '../pages/FormService/add';
import EditFormService from '../pages/FormService/edit';
// Product
import Product from '../pages/Product';
import CreateProduct from '../pages/Product/add';
import EditProduct from '../pages/Product/edit';
// Product Application
import ProductApplication from '../pages/ProductApplication';
import CreateProductApplication from '../pages/ProductApplication/add';
import EditProductApplication from '../pages/ProductApplication/edit';
// Form Application
import FormApplication from '../pages/FormApplication';
import CreateFormApplication from '../pages/FormApplication/add';
import EditFormApplication from '../pages/FormApplication/edit';
// Reason
import Reason from '../pages/Reason';
import CreateReason from '../pages/Reason/add';
import EditReason from '../pages/Reason/edit';
// Segment
import Segment from '../pages/Segment';
import CreateSegment from '../pages/Segment/add';
import EditSegment from '../pages/Segment/edit';
// Culture
import Culture from '../pages/Culture';
import CreateCulture from '../pages/Culture/add';
import EditCulture from '../pages/Culture/edit';
// Brand
import Brand from '../pages/Brand';
import CreateBrand from '../pages/Brand/add';
import EditBrand from '../pages/Brand/edit';
// User
import User from '../pages/User';
import CreateUser from '../pages/User/add';
import EditUser from '../pages/User/edit';
// Delivery
import Delivery from '../pages/Delivery';
import CreateDelivery from '../pages/Delivery/add';
import EditDelivery from '../pages/Delivery/edit';
// Visits
import Visit from '../pages/Visit';
import CreateVisit from '../pages/Visit/add';
import EditVisit from '../pages/Visit/edit';
// Application
import Application from '../pages/Application';
import CreateApplication from '../pages/Application/add';
import EditApplication from '../pages/Application/edit';
import ViewApplication from '../pages/Application/view';
// Settings
import Settings from '../pages/Settings';
// Settings >> Goal
import Goal from '../pages/Settings/Goal';
import CreateGoal from '../pages/Settings/Goal/add';
import EditGoal from '../pages/Settings/Goal/edit';
// Upload Mapas
import Maps from '../pages/Maps';
import CreateMaps from '../pages/Maps/add';
// Reports
import ReportsDashboard from '../pages/Reports/';
// Sales AP
import Sales from '../pages/Sales';
import CreateSales from '../pages/Sales/add';
import EditSales from '../pages/Sales/edit';
import ShowSales from '../pages/Sales/show';
// Sales Machine
import SalesMachine from '../pages/Sales/machine';
import CreateSalesMachine from '../pages/Sales/machine/add';
import EditSalesMachine from '../pages/Sales/machine/edit';
// Sales Collections
import SalesCollections from '../pages/Sales/collections';
// Logout
import Logout from '../pages/Logout';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/change-password/:token" component={ChangePassword} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/settings/edit" component={Settings} isPrivate />

      <Route path="/goal/list" component={Goal} isPrivate />
      <Route path="/goal/create" component={CreateGoal} isPrivate />
      <Route path="/goal/edit/:id" component={EditGoal} isPrivate />

      <Route path="/registrations/customer" component={Customer} isPrivate />
      <Route path="/registrations/customer-create" component={CreateCustomer} isPrivate />
      <Route path="/registrations/customer-edit/:id" component={EditCustomer} isPrivate />

      <Route path="/registrations/farm" component={Farm} isPrivate />
      <Route path="/registrations/farm-create" component={CreateFarm} isPrivate />
      <Route path="/registrations/farm-edit/:id" component={EditFarm} isPrivate />

      <Route path="/registrations/field" component={Field} isPrivate />
      <Route path="/registrations/field-create" component={CreateField} isPrivate />
      <Route path="/registrations/field-edit/:id" component={EditField} isPrivate />

      <Route path="/registrations/form-service" component={FormService} isPrivate />
      <Route
        path="/registrations/form-service-create"
        component={CreateFormService}
        isPrivate
      />
      <Route
        path="/registrations/form-service-edit/:id"
        component={EditFormService}
        isPrivate
      />

      <Route path="/registrations/product" component={Product} isPrivate />
      <Route path="/registrations/product-create" component={CreateProduct} isPrivate />
      <Route path="/registrations/product-edit/:id" component={EditProduct} isPrivate />

      <Route path="/registrations/product-application" component={ProductApplication} isPrivate />
      <Route path="/registrations/product-application-create" component={CreateProductApplication} isPrivate />
      <Route path="/registrations/product-application-edit/:id" component={EditProductApplication} isPrivate />

      <Route path="/registrations/form-application" component={FormApplication} isPrivate />
      <Route path="/registrations/form-application-create" component={CreateFormApplication} isPrivate />
      <Route path="/registrations/form-application-edit/:id" component={EditFormApplication} isPrivate />

      <Route path="/registrations/culture" component={Culture} isPrivate />
      <Route path="/registrations/culture-create" component={CreateCulture} isPrivate />
      <Route path="/registrations/culture-edit/:id" component={EditCulture} isPrivate />

      <Route path="/registrations/reason" component={Reason} isPrivate />
      <Route path="/registrations/reason-create" component={CreateReason} isPrivate />
      <Route path="/registrations/reason-edit/:id" component={EditReason} isPrivate />

      <Route path="/registrations/segment" component={Segment} isPrivate />
      <Route path="/registrations/segment-create" component={CreateSegment} isPrivate />
      <Route path="/registrations/segment-edit/:id" component={EditSegment} isPrivate />

      <Route path="/registrations/brand" component={Brand} isPrivate />
      <Route path="/registrations/brand-create" component={CreateBrand} isPrivate />
      <Route path="/registrations/brand-edit/:id" component={EditBrand} isPrivate />

      <Route path="/registrations/user" component={User} isPrivate />
      <Route path="/registrations/user-create" component={CreateUser} isPrivate />
      <Route path="/registrations/user-edit/:id" component={EditUser} isPrivate />

      <Route path="/registrations/delivery" component={Delivery} isPrivate />
      <Route path="/registrations/delivery-create" component={CreateDelivery} isPrivate />
      <Route path="/registrations/delivery-edit/:id" component={EditDelivery} isPrivate />

      <Route path="/visits/list" component={Visit} isPrivate />
      <Route path="/visits/create" component={CreateVisit} isPrivate />
      <Route path="/visits/edit/:id" component={EditVisit} isPrivate />

      <Route path="/application/list" component={Application} isPrivate />
      <Route path="/application/create" component={CreateApplication} isPrivate />
      <Route path="/application/edit/:id" component={EditApplication} isPrivate />
      <Route path="/application/view/:id" component={ViewApplication} isPrivate />

      <Route path="/maps/list" component={Maps} isPrivate />
      <Route path="/maps/create" component={CreateMaps} isPrivate />

      <Route path="/sales/ap/list" component={Sales} isPrivate />
      <Route path="/sales/ap/create" component={CreateSales} isPrivate />
      <Route path="/sales/ap/edit/:id" component={EditSales} isPrivate />
      <Route path="/sales/ap/show/:id" component={ShowSales} isPrivate />

      <Route path="/sales/machine/list" component={SalesMachine} isPrivate />
      <Route path="/sales/machine/create" component={CreateSalesMachine} isPrivate />
      <Route path="/sales/machine/edit/:id" component={EditSalesMachine} isPrivate />

      <Route path="/collections/ap" component={SalesCollections} isPrivate />

      {/* Reports */}
      <Route path="/reports" component={ReportsDashboard} isPrivate />
      
      {/* Logout */}
      <Route path="/logout" component={Logout} isPrivate />

      <Route path="/" component={Error_404} />
    </Switch>
  );
}
