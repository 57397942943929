import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { cultureUpdateSuccess } from './actions';

export function* cultureSave({ payload }) {
  try {
    const response = yield call(api.post, 'culture', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* cultureUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `culture/${payload.id}`, payload.data);
    toast.success('Cultura atualizada com sucesso!');
    yield put(cultureUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a cultura.');
  }
}

export function* cultureDelete({ payload }) {
  try {
    yield call(api.delete, `culture/${payload.culture_id}`);
    toast.success('Cultura excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a cultura.');
  }
}

export default all([
  takeLatest('@culture/CULTURE_SAVE_REQUEST', cultureSave),
  takeLatest('@culture/CULTURE_UPDATE_REQUEST', cultureUpdate),
  takeLatest('@culture/DELETE_CULTURE_REQUEST', cultureDelete),
]);
