export function deliverySaveRequest(data) {
  return {
    type: '@delivery/DELIVERY_SAVE_REQUEST',
    payload: { data },
  };
}

export function deliverySaveSuccess(data) {
  return {
    type: '@delivery/DELIVERY_SAVE_SUCCESS',
    payload: { data },
  };
}

export function deliveryUpdateRequest(id, data) {
  return {
    type: '@delivery/DELIVERY_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function deliveryUpdateSuccess() {
  return {
    type: '@delivery/DELIVERY_UPDATE_SUCCESS',
  };
}

export function deliveryDeleteRequest(delivery_id) {
  return {
    type: '@delivery/DELETE_DELIVERY_REQUEST',
    payload: { delivery_id },
  };
}
