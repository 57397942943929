export function cultureSaveRequest(data) {
  return {
    type: '@culture/CULTURE_SAVE_REQUEST',
    payload: { data },
  };
}

export function cultureSaveSuccess(data) {
  return {
    type: '@culture/CULTURE_SAVE_SUCCESS',
    payload: { data },
  };
}

export function cultureUpdateRequest(id, data) {
  return {
    type: '@culture/CULTURE_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function cultureUpdateSuccess() {
  return {
    type: '@culture/CULTURE_UPDATE_SUCCESS',
  };
}

export function cultureDeleteRequest(culture_id) {
  return {
    type: '@culture/DELETE_CULTURE_REQUEST',
    payload: { culture_id },
  };
}
