import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { goalUpdateSuccess } from './actions';

export function* goalSave({ payload }) {
  try {
    const response = yield call(api.post, 'goal', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* goalUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `goal/${payload.id}`, payload.data);
    toast.success('Meta atualizada com sucesso!');
    yield put(goalUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a meta.');
  }
}

export function* goalDelete({ payload }) {
  try {
    yield call(api.delete, `goal/${payload.goal_id}`);
    toast.success('Meta excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a meta.');
  }
}

export default all([
  takeLatest('@goal/GOAL_SAVE_REQUEST', goalSave),
  takeLatest('@goal/GOAL_UPDATE_REQUEST', goalUpdate),
  takeLatest('@goal/DELETE_GOAL_REQUEST', goalDelete),
]);
