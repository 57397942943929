import React, { useState, useEffect, useRef } from 'react';
import { Columns } from 'react-bulma-components';
import Select from 'react-select'
import { RiFilterOffFill } from 'react-icons/ri';
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";
import { format } from 'date-fns'

import api from '~/services/api';
import DatePicker from "react-datepicker";

const ModalReportsManagerTarget = props => {
  const inputRefCustomer = useRef();
  const inputRefReason = useRef();
  const inputRefFormServices = useRef();
  // const inputRefUser = useRef();
  // const inputRefDivergencia = useRef();
  const inputRefTotalVisits = useRef();
  const inputRefKmInicial = useRef();

  const indicatorSeparatorStyle = {
    width: 0,
  };
  
  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };

  const onChangeRefSelect = option => (
    setInputRefUser(option)
  );
  
  const [isLoadingPDF, setIsLoadingPDF] = useState(true);
  const [isLoadingXLS, setIsLoadingXLS] = useState(true);
  const [isLoadingSelects, setIsLoadingSelects] = useState(true);

  const [filter, setFilter] = useState({});
  const [customer, setCustomer] = useState([]);
  const [formService, setFormService] = useState([]);
  const [reason, setReason] = useState([]);
  const [user, setUser] = useState([]);
  const [inputRefUser, setInputRefUser] = useState([]);
  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  const fetchDataCustomer = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('customer/registers/all');
      setCustomer(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchDataFormService = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('form-service/registers/all');
      setFormService(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchDataReason = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('reason/registers/all');
      setReason(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchDataUser = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/user/registers/all');
      const arrayUser = [];
      response.data.map((value, index) => {
        arrayUser.push({
          value: value.id, 
          label: value.name
        })
      })
      setUser(arrayUser);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  useEffect(() => {
    fetchDataCustomer();
    fetchDataFormService();
    fetchDataReason();
    fetchDataUser();
  }, []);

  function cleanInputsFilter() {
    inputRefCustomer.current.value = '';
    inputRefFormServices.current.value = '';
    // inputRefDivergencia.current.value = '';
    inputRefTotalVisits.current.value = '';
    inputRefKmInicial.current.value = '';
    // inputRefUser.current.value = '';
    setInputRefUser('');
    inputRefReason.current.value = '';
    setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  }

  const handleSubmit = (event, type) => {
    if (type === 'pdf'){
      setIsLoadingPDF(false);
    } else {
      setIsLoadingXLS(false);
    }
    event.preventDefault();

    setFilter({
      params: {
        customer_id: inputRefCustomer.current.value,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        form_services_id: inputRefFormServices.current.value,
        // new: inputRefDivergencia.current.checked,
        reason_id: inputRefReason.current.value,
        user_id: inputRefUser,
        total_visits: inputRefTotalVisits.current.checked,
        km_inicial: inputRefKmInicial.current.checked,
      }
    });
    if (type === 'pdf'){
      api.get(`/reports/visits/visitsXclients`, {
        params: {
          customer_id: inputRefCustomer.current.value,
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          form_services_id: inputRefFormServices.current.value,
          // new: inputRefDivergencia.current.checked,
          reason_id: inputRefReason.current.value,
          user_id: inputRefUser,
          total_visits: inputRefTotalVisits.current.checked,
          km_inicial: inputRefKmInicial.current.checked,
          type: type,
        },
        responseType: 'blob'
      }).then(response => {
        setIsLoadingPDF(true);
        const file = new Blob([response.data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    } else {
      api.get(`/reports/visits/visitsXclients`, {
        params: {
          customer_id: inputRefCustomer.current.value,
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          form_services_id: inputRefFormServices.current.value,
          // new: inputRefDivergencia.current.checked,
          reason_id: inputRefReason.current.value,
          user_id: inputRefUser,
          total_visits: inputRefTotalVisits.current.checked,
          km_inicial: inputRefKmInicial.current.checked,
          type: type,
        },
        responseType: 'blob'
      }).then(response => {
        setIsLoadingXLS(true);
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Visitas_X_Clientes.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  }

  return (
    <>
      <div className={props.show ? 'report modal is-active' : 'report modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Relatório de visitas</p>
            <button
              onClick={props.onClose}
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <Columns>
              <Columns.Column>
                <Columns>
                  <form className="manager filter">
                    <Columns>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Filtro de cliente
                            </label>
                            <select name="customer_id" ref={inputRefCustomer} className="select">
                              <option value="" className="placeholder">Todos os Cliente</option>
                              { isLoadingSelects ? (
                                <option>Carregando</option>
                              ) : (
                                customer.map((value, index) => {
                                  return <option value={value.id} key={index}>{value.name}</option>
                                })
                              )}
                            </select>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Filtro de motivo
                            </label>
                            <select name="reason_id" ref={inputRefReason} className="select">
                              <option value="" className="placeholder">Todos os Motivo</option>
                              { isLoadingSelects ? (
                                <option>Carregando</option>
                              ) : (
                                reason.map((value, index) => {
                                  return <option value={value.id} key={index}>{value.name}</option>
                                })
                              )}
                            </select>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Filtro de atendimento
                            </label>
                            <select name="form_services_id" ref={inputRefFormServices} className="select">
                              <option value="" className="placeholder">Todos os Atendimento</option>
                              { isLoadingSelects ? (
                                <span>Carregando</span>
                              ) : (
                                formService.map((value, index) => {
                                  return <option value={value.id} key={index}>{value.name}</option>
                                })
                              )}
                            </select>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="name" className="label">
                              Filtro de responsável
                            </label>
                            <Select
                              placeholder={'Todos os Responsável'}
                              components={{ IndicatorSeparator }}
                              isMulti
                              name="user_id"
                              options={user}
                              onChange={onChangeRefSelect}
                              className="select react-select"
                            />
                          </div>
                        </div>
                      </Columns.Column>

                      <Columns.Column size={12}>
                        <div className="field inline">
                          <div className="field">
                            <div className="control">
                              <label htmlFor="name" className="label">
                                Data Inicial
                              </label>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className="input"
                                popperPlacement="top-right"
                              />
                            </div>
                          </div>
                          <span className="between-dates"></span>
                          <div className="field">
                            <div className="control">
                              <label htmlFor="name" className="label">
                                Data Final
                              </label>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                className="input"
                                popperPlacement="top-right"
                              />
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="new" className="label">
                              Mostrar relatório com totalizador de visitas?
                            </label>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                value="true"
                                ref={inputRefTotalVisits}
                                name="total_visits"
                              /> Sim
                            </label>
                            <div className="totalizador">
                              <em>Totalizador mostrará o relatório agrupando a quantidade de visitas por cliente.</em>
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={12}>
                        <div className="field">
                          <div className="control">
                            <label htmlFor="new" className="label">
                              Mostrar carro e KM inicial?
                            </label>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                value="true"
                                ref={inputRefKmInicial}
                                name="km_inicial"
                              /> Sim
                            </label>
                          </div>
                        </div>
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column>
                        <div className="button-clean" onClick={cleanInputsFilter} >
                          <RiFilterOffFill
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          /> Limpar filtros
                        </div>
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column size={6}>
                        <div className="field is-horizontal">
                          <div className="field-body">
                            <div className="field">
                              <div className="control">
                                <button onClick={e => handleSubmit(e,'xls')} className="button is-info">
                                  <AiOutlineFileExcel
                                    size={15}
                                    color="#FFFFFF"
                                    className="icon-button-back"
                                  />
                                  {isLoadingXLS ? 'Gerar Excel' : 'Carregando, aguarde!'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={6}>
                        <div className="field is-horizontal">
                          <div className="field-body">
                            <div className="field">
                              <div className="control has-text-right">
                                <button onClick={e => handleSubmit(e,'pdf')} className="button is-info">
                                  <AiOutlineFilePdf
                                    size={15}
                                    color="#FFFFFF"
                                    className="icon-button-back"
                                  />
                                  {isLoadingPDF ? 'Gerar PDF' : 'Carregando, aguarde!'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                    </Columns>

                  </form>
                </Columns>
              </Columns.Column>
            </Columns>
          </section>
        </div>
      </div>
    </>
  )
}

export default ModalReportsManagerTarget;
