import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { visitUpdateSuccess } from './actions';

export function* visitSave({ payload }) {
  try {
    const response = yield call(api.post, 'visit', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* visitUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `visit/${payload.id}`, payload.data);
    toast.success('Visita atualizada com sucesso!');
    yield put(visitUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a visita.');
  }
}

export function* visitDelete({ payload }) {
  try {
    yield call(api.delete, `visit/${payload.visit_id}`);
    toast.success('Visita excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a visita.');
  }
}

export default all([
  takeLatest('@visit/VISIT_SAVE_REQUEST', visitSave),
  takeLatest('@visit/VISIT_UPDATE_REQUEST', visitUpdate),
  takeLatest('@visit/DELETE_VISIT_REQUEST', visitDelete),
]);
