import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { farmUpdateSuccess } from './actions';

export function* farmSave({ payload }) {
  try {
    console.log(payload.data);
    const response = yield call(api.post, 'farm', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* farmUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `farm/${payload.id}`, payload.data);
    toast.success('Fazenda atualizada com sucesso!');
    yield put(farmUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a fazenda.');
  }
}

export function* farmDelete({ payload }) {
  try {
    yield call(api.delete, `farm/${payload.farm_id}`);
    toast.success('Fazenda excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a fazenda.');
  }
}

export default all([
  takeLatest('@farm/FARM_SAVE_REQUEST', farmSave),
  takeLatest('@farm/FARM_UPDATE_REQUEST', farmUpdate),
  takeLatest('@farm/DELETE_FARM_REQUEST', farmDelete),
]);
