export function formApplicationSaveRequest(data) {
  return {
    type: '@formApplication/FORM_APPLICATION_SAVE_REQUEST',
    payload: { data },
  };
}

export function formApplicationSaveSuccess(data) {
  return {
    type: '@formApplication/FORM_APPLICATION_SAVE_SUCCESS',
    payload: { data },
  };
}

export function formApplicationUpdateRequest(id, data) {
  return {
    type: '@formApplication/FORM_APPLICATION_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function formApplicationUpdateSuccess() {
  return {
    type: '@formApplication/FORM_APPLICATION_UPDATE_SUCCESS',
  };
}

export function formApplicationDeleteRequest(form_application_id) {
  return {
    type: '@formApplication/DELETE_FORM_APPLICATION_REQUEST',
    payload: { form_application_id },
  };
}
