export function fieldSaveRequest(data) {
  return {
    type: '@field/FIELD_SAVE_REQUEST',
    payload: { data },
  };
}

export function fieldSaveSuccess(data) {
  return {
    type: '@field/FIELD_SAVE_SUCCESS',
    payload: { data },
  };
}

export function fieldUpdateRequest(id, data) {
  return {
    type: '@field/FIELD_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function fieldUpdateSuccess() {
  return {
    type: '@field/FIELD_UPDATE_SUCCESS',
  };
}

export function fieldDeleteRequest(field_id) {
  return {
    type: '@field/DELETE_FIELD_REQUEST',
    payload: { field_id },
  };
}
