import React from 'react';
import { NavLink } from 'react-router-dom';

import { Menu } from './styles';

export default function MenuVisits() {
  const isActive = {
    fontWeight: "bold",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  };

  return (
    <Menu>
      <NavLink to="/visits/list" activeClassName="selected">Lista de visitas</NavLink>
      <NavLink to="/visits/create" activeClassName="selected">Cadastrar visita</NavLink>
    </Menu>
  );
}
