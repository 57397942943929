// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect, useRef  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { FiTrash, FiX } from 'react-icons/fi';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { MdHome, MdAddCircleOutline, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { RiFilterOffFill, RiFilterFill } from 'react-icons/ri';
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';
import { format } from 'date-fns'

import api from '~/services/api';
import { salesDeleteRequest } from '~/store/modules/sales/actions';

// Kanban
import Board, { moveCard } from '@lourenci/react-kanban'
import { updateStatusSaveRequest } from '~/store/modules/sales/actions';

import DatePicker from "react-datepicker";

export default function SalesList() {
  const dispatch = useDispatch();
  const inputRefUser = useRef();

  const inputRefStatus = useRef();
  const inputRefCustomer = useRef();
  const inputRefBrand = useRef();

  const profile = useSelector(state => state.user.profile);
  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  const [sales, setSales] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSalesList, setIsLoadingSalesList] = useState(true);
  const [isLoadingSelects, setIsLoadingSelects] = useState(true);
  const [board, setBoard] = useState([]);

  const [filter, setFilter] = useState({});
  const [user, setUser] = useState([]);

  const [customers, setCustomers] = useState([]);
  const [brands, setBrands] = useState([]);

  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(board, source, destination);
    setBoard(updatedBoard);
    dispatch(updateStatusSaveRequest(_card.id, destination.toColumnId));
  }

  function convertToReal(numberUsed, numberNew, options = {}) {
    var number;
    if (numberUsed !== "0.00" ) {
      number = numberUsed;
    } else {
      number = numberNew;
    }
    const { moneySign = true } = options;
    if(Number.isNaN(number) || !number) return "need a number as the first parameter";
    if(typeof number === "string") {
      number = Number(number);
    }
    let res;
    const config = moneySign ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};
    moneySign
    ? res = number.toLocaleString('pt-BR', config)
    : res = number.toLocaleString('pt-BR', config)
    const needComma = number => number <= 1000;
    if(needComma(number)) {
      res = res.toString().replace(".", ",");
    }
    return res;
  }

  const fetchDataSalesList = async (pageNumber = 1) => {
    setIsLoadingSalesList(true);
    try {
      const response = await api.get(`/sales/machine/index-all?page=${pageNumber}`);
      setSalesList(response.data);
      setIsLoadingSalesList(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };
  useEffect(() => {
    fetchDataSalesList();
  }, []);

  const fetchDataUser = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/user/registers/all');
      setUser(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  const fetchDataCustomer = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/customer/registers/all');
      setCustomers(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  const fetchDataBrand = async () => {
    setIsLoadingSelects(true);
    try {
      const response = await api.get('/brand/registers/all');
      setBrands(response.data);
      setIsLoadingSelects(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  function deleteSalesMachine(sales_id, name) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-confirm-delete">
            <div className="title">
              Confirma a exclusão da venda: <strong>{name}</strong>?{' '}
            </div>
            <button onClick={onClose}>
              <FiX size="18px" /> Cancelar exclusão
            </button>
            <button
              className="yes"
              onClick={() => {
                handleClickDelete(sales_id);
                onClose();
              }}
            >
              <FiTrash size="18px" /> Sim, pode excluir!
            </button>
          </div>
        );
      },
    });
  }

  async function handleClickDelete(sales_id) {
    await Promise.all([
      dispatch(salesDeleteRequest(sales_id))
    ]);
    await window.location.reload();
  }


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const apresentacoes = await api.get('sales/status_type/0/1');
      const orcamentos = await api.get('sales/status_type/1/1');
      const negociacoes = await api.get('sales/status_type/2/1');
      const concluidas = await api.get('sales/status_type/3/1');
      const perdidas = await api.get('sales/status_type/4/1');

      setBoard({
        columns: [
          {
            id: 0,
            title: 'Venda',
            cards: apresentacoes.data.data.map(apresentacao => ({
              id: apresentacao.id.toString(),
              title:  <div className="title-card">
                  <span>{apresentacao.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${apresentacao.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ apresentacao.id +' <br /> '+
                '<strong>Marca:</strong> '+ apresentacao.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(apresentacao.sale_total) +' <br /> '+
                '<strong>Consultor:</strong> '+ apresentacao.user_name
              } } />
            }))
          },
          {
            id: 1,
            title: 'Pedido na Fábrica',
            cards: orcamentos.data.data.map(orcamento => ({
              id: orcamento.id.toString(),
              title:  <div className="title-card">
                  <span>{orcamento.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${orcamento.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ orcamento.id +' <br /> '+
                '<strong>Marca:</strong> '+ orcamento.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(orcamento.sale_total) +' <br /> '+
                '<strong>Consultor:</strong> '+ orcamento.user_name
              } } />
            }))
          },
          {
            id: 2,
            title: 'Disponível para Entrega',
            cards: negociacoes.data.data.map(negociacao => ({
              id: negociacao.id.toString(),
              title:  <div className="title-card">
                  <span>{negociacao.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${negociacao.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ negociacao.id +' <br /> '+
                '<strong>Marca:</strong> '+ negociacao.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(negociacao.sale_total) +' <br /> '+
                '<strong>Consultor:</strong> '+ negociacao.user_name
              } } />
            }))
          },
          {
            id: 3,
            title: 'Entregue',
            cards: concluidas.data.data.map(concluida => ({
              id: concluida.id.toString(),
              title:  <div className="title-card">
                  <span>{concluida.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${concluida.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ concluida.id +' <br /> '+
                '<strong>Marca:</strong> '+ concluida.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(concluida.sale_total) +' <br /> '+
                '<strong>Consultor:</strong> '+ concluida.user_name
              } } />
            }))
          },
          {
            id: 4,
            title: 'Reprovada',
            cards: perdidas.data.data.map(perdida => ({
              id: perdida.id.toString(),
              title:  <div className="title-card">
                  <span>{perdida.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${perdida.id}`}>
                    <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ perdida.id +' <br /> '+
                '<strong>Marca:</strong> '+ perdida.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(perdida.sale_total) +' <br /> '+
                '<strong>Consultor:</strong> '+ perdida.user_name
              } } />
            }))
          }
        ]
      });

      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  useEffect(() => {
    fetchDataUser();
    fetchData();
    fetchDataCustomer();
    fetchDataBrand();
  }, []);

  function cleanInputsFilter() {
    if (admin) {
      inputRefUser.current.value = '';
    }
    setStartDate(new Date(date.getFullYear(), 0, 1));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    fetchData();
  }

  const handleSubmit = async (event)=> {
    event.preventDefault();
    setIsLoading(true);
    var refUser = '';
    if (admin) {
      refUser = inputRefUser.current.value;
    } else {
      refUser = inputRefUser.current.value;
    }
    setFilter({
      params: {
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        user_id: refUser,
      }
    });

    try {
      const apresentacoes = await api.get('sales/status_type/0/1', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
        }
      });
      const orcamentos = await api.get('sales/status_type/1/1', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
        }
      });
      const negociacoes = await api.get('sales/status_type/2/1', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
        }
      });
      const concluidas = await api.get('sales/status_type/3/1', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
        }
      });
      const perdidas = await api.get('sales/status_type/4/1', {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          user_id: refUser,
        }
      });

      setBoard({
        columns: [
          {
            id: 0,
            title: 'Venda',
            cards: apresentacoes.data.data.map(apresentacao => ({
              id: apresentacao.id.toString(),
              title:  <div className="title-card">
                  <span>{apresentacao.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${apresentacao.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ apresentacao.id +' <br /> '+
                '<strong>Marca:</strong> '+ apresentacao.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(apresentacao.total_machine_used, apresentacao.total_machine_new) +' <br /> '+
                '<strong>Consultor:</strong> '+ apresentacao.user_name
              } } />
            }))
          },
          {
            id: 1,
            title: 'Pedido na Fábrica',
            cards: orcamentos.data.data.map(orcamento => ({
              id: orcamento.id.toString(),
              title:  <div className="title-card">
                  <span>{orcamento.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${orcamento.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ orcamento.id +' <br /> '+
                '<strong>Marca:</strong> '+ orcamento.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(orcamento.total_machine_used, orcamento.total_machine_new) +' <br /> '+
                '<strong>Consultor:</strong> '+ orcamento.user_name
              } } />
            }))
          },
          {
            id: 2,
            title: 'Disponível para Entrega',
            cards: negociacoes.data.data.map(negociacao => ({
              id: negociacao.id.toString(),
              title:  <div className="title-card">
                  <span>{negociacao.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${negociacao.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ negociacao.id +' <br /> '+
                '<strong>Marca:</strong> '+ negociacao.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(negociacao.total_machine_used, negociacao.total_machine_new) +' <br /> '+
                '<strong>Consultor:</strong> '+ negociacao.user_name
              } } />
            }))
          },
          {
            id: 3,
            title: 'Entregue',
            cards: concluidas.data.data.map(concluida => ({
              id: concluida.id.toString(),
              title:  <div className="title-card">
                  <span>{concluida.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${concluida.id}`}>
                      <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ concluida.id +' <br /> '+
                '<strong>Marca:</strong> '+ concluida.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(concluida.total_machine_used, concluida.total_machine_new) +' <br /> '+
                '<strong>Consultor:</strong> '+ concluida.user_name
              } } />
            }))
          },
          {
            id: 4,
            title: 'Reprovada',
            cards: perdidas.data.data.map(perdida => ({
              id: perdida.id.toString(),
              title:  <div className="title-card">
                  <span>{perdida.customer_name}</span>
                  <span>
                    <Link to={`/sales/machine/edit/${perdida.id}`}>
                    <FaEdit size={15} color="#4a4a4a" />
                    </Link>
                  </span>
              </div>,
              description:
              <div dangerouslySetInnerHTML={ {__html:
                '<strong>Código:</strong> '+ perdida.id +' <br /> '+
                '<strong>Marca:</strong> '+ perdida.brand_name +' <br /> '+
                '<strong>Valor:</strong> '+ convertToReal(perdida.total_machine_used, perdida.total_machine_new) +' <br /> '+
                '<strong>Consultor:</strong> '+ perdida.user_name
              } } />
            }))
          }
        ]
      });

      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  }

  function cleanInputsFilterMachine() {
    inputRefCustomer.current.value = '';
    inputRefBrand.current.value = '';
    inputRefStatus.current.value = '';
    
    setStartDate(new Date(date.getFullYear(), 0, 1));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    fetchDataSalesList();
  }

  const handleSubmitMachine = event => {
    event.preventDefault();
    setIsLoading(true);
    var refCustomer = '';
    var refBrand = '';
    var refStatus = '';
    if (inputRefCustomer.current.value !== undefined) {
      refCustomer = inputRefCustomer.current.value;
    } else {
      refCustomer = '';
    }
    if (inputRefBrand.current.value !== undefined) {
      refBrand = inputRefBrand.current.value;
    } else {
      refBrand = '';
    }
    if (inputRefStatus.current.value !== undefined) {
      refStatus = inputRefStatus.current.value;
    } else {
      refStatus = '';
    }

    setFilter({
      params: {
        customer_id: refCustomer,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        brand_id: refBrand,
        status: refStatus,
      }
    });
    api.get(`/sales/search_status_type_rep/1`, {
      params: {
        customer_id: refCustomer,
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        brand_id: refBrand,
        status: refStatus,
      },
    }).then(response => {
      setSalesList(response.data);
      setIsLoading(false);
    });

  }


  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Lista de vendas - Máquinas</li>
            </ul>
            <h1>Lista de vendas - Máquinas</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/sales/machine/create">
            <Button className="button is-primary">
              <MdAddCircleOutline
                size={15}
                color="#FFFFFF"
                className="icon-button-back"
              />
              Cadastrar venda
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      {(admin || profile.roles[0].name === 'escritorio') ?
        <Columns>
          <Columns.Column>
            <Columns>
              <form className="manager filter">
                <Columns>
                  <Columns.Column>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de responsável
                        </label>
                        <select name="user_id" ref={inputRefUser} className="select">
                          <option value="" className="placeholder">Responsável</option>
                          { isLoadingSelects ? (
                            <option>Carregando</option>
                          ) : (
                            user.map((value, index) => {
                              return <option value={value.id} key={index}>{value.name}</option>
                            })
                          )}
                        </select>
                      </div>
                    </div>
                  </Columns.Column>

                  <Columns.Column size={admin ? 2 : 4}>
                    <label htmlFor="name" className="label">
                      Filtro entre data
                    </label>
                    <div className="field inline">
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="input"
                            popperPlacement="top-right"
                          />
                        </div>
                      </div>
                      <span className="between-dates">a</span>
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className="input"
                            popperPlacement="top-right"
                          />
                        </div>
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column size={admin ? '' : 2}>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control has-text-right">
                            <button onClick={handleSubmit} className="button is-info">
                              <RiFilterFill
                                size={15}
                                color="#FFFFFF"
                                className="icon-button-back"
                              />
                              Filtrar dados
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <div className="button-clean" onClick={cleanInputsFilter} >
                      <RiFilterOffFill
                        size={15}
                        color="#FFFFFF"
                        className="icon-button-back"
                      /> Limpar filtros
                    </div>
                  </Columns.Column>
                </Columns>
              </form>
            </Columns>
          </Columns.Column>
        </Columns>
      : (
        <Columns>
          <Columns.Column>
            <Columns>
              <form className="manager filter">
                <Columns>
                  <Columns.Column size={2}>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de status
                        </label>
                        <select name="user_id" ref={inputRefStatus} className="select">
                          <option value="" className="placeholder">Status</option>
                          <option value="0" className="placeholder">Venda</option>
                          <option value="1" className="placeholder">Pedido na Fábrica</option>
                          <option value="2" className="placeholder">Disponível para Entrega</option>
                          <option value="3" className="placeholder">Entregue</option>
                          <option value="4" className="placeholder">Reprovada</option>
                        </select>
                      </div>
                    </div>
                  </Columns.Column>
                  
                  <Columns.Column size={2}>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de cliente
                        </label>
                        <select name="user_id" ref={inputRefCustomer} className="select">
                          <option value="" className="placeholder">Cliente</option>
                          { isLoadingSelects ? (
                            <option>Carregando</option>
                          ) : (
                            customers.map((value, index) => {
                              return <option value={value.id} key={index}>{value.name}</option>
                            })
                          )}
                        </select>
                      </div>
                    </div>
                  </Columns.Column>

                  <Columns.Column size={2}>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Filtro de marca
                        </label>
                        <select name="user_id" ref={inputRefBrand} className="select">
                          <option value="" className="placeholder">Marca</option>
                          { isLoadingSelects ? (
                            <option>Carregando</option>
                          ) : (
                            brands.map((value, index) => {
                              return <option value={value.id} key={index}>{value.name}</option>
                            })
                          )}
                        </select>
                      </div>
                    </div>
                  </Columns.Column>

                  <Columns.Column size={4}>
                    <label htmlFor="name" className="label">
                      Filtro entre data
                    </label>
                    <div className="field inline">
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="input"
                            popperPlacement="top-right"
                          />
                        </div>
                      </div>
                      <span className="between-dates">a</span>
                      <div className="field">
                        <div className="control">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className="input"
                            popperPlacement="top-right"
                          />
                        </div>
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column size={admin ? '' : 2}>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control has-text-right">
                            <button onClick={handleSubmitMachine} className="button is-info">
                              <RiFilterFill
                                size={15}
                                color="#FFFFFF"
                                className="icon-button-back"
                              />
                              Filtrar dados
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <div className="button-clean" onClick={cleanInputsFilterMachine} >
                      <RiFilterOffFill
                        size={15}
                        color="#FFFFFF"
                        className="icon-button-back"
                      /> Limpar filtros
                    </div>
                  </Columns.Column>
                </Columns>
              </form>
            </Columns>
          </Columns.Column>
        </Columns>
      )}

      <div className="content sales-machine">
        { isLoading ? (
          <div className="loading">
            <ImSpinner11
              size={24}
              color="#2a775a"
              className="fa-spin"
            /> Carregando ...
          </div>
        ) : (
          <>
            {(profile.roles[0].name === 'admin' || profile.roles[0].name === 'escritorio') ? (
              <Board
              onCardDragEnd={handleCardMove}
              disableColumnDrag
              >
                {board}
              </Board>
            ) : (
              <Columns.Column className="bg-content">
                <Columns>
                  <Columns.Column size={3} className="search-table">
                    {/* <div className="field has-addons">
                      <div className="control">
                        <input
                          className="input search"
                          type="text"
                          placeholder="Pesquisar"
                          autoComplete="off"
                          onChange={setSearch}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <div className="control">
                        <a
                          className="button is-info"
                          onClick={handleSearch}
                          >
                          <MdSearch
                            size={20}
                            color="#a9a9a9"
                          />
                        </a>
                      </div>
                    </div> */}
                  </Columns.Column>
                  <Columns.Column size={9} className="has-text-right">
                    <span className="total-register">Total de registros: <strong>{salesList.total}</strong></span>
                  </Columns.Column>
                </Columns>
                {isLoading ? (
                  <div className="loading">
                  <ImSpinner11
                    size={24}
                    color="#2a775a"
                    className="fa-spin"
                  /> Carregando ...</div>
                ) : (
                  <>
                    <table className="table is-striped">
                      <thead>
                        <tr>
                          <th width="80" className="has-text-centered">Código</th>
                          <th width="80" className="has-text-centered">Cliente</th>
                          <th width="80" className="has-text-centered">Máquina Usada?</th>
                          <th width="80" className="has-text-centered">Pega máquina no negócio?</th>
                          <th width="80" className="has-text-centered">Marca</th>
                          <th width="80" className="has-text-centered">Valor</th>
                          <th width="80" className="has-text-centered">Status</th>
                          <th width="80" className="has-text-centered">Ações</th>
                        </tr>
                      </thead>
                      {salesList.data.length === 0 ? (
                        <tbody>
                          <tr>
                            <td colSpan="8" className="has-text-centered">Nenhum Registro encontrado :(</td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {salesList.data.map((value, index) => (
                            <tr key={value.id}>
                              <td key={value.id} className="has-text-centered">{value.id}</td>
                              <td className="has-text-centered">{value.customer_name}</td>
                              <td className="has-text-centered">
                                {value.machine_used === "true" ? (
                                  <span>Sim</span>
                                ) : (
                                  <span>Não</span>
                                )}
                              </td>
                              <td className="has-text-centered">
                                {value.business_used_machine === "true" ? (
                                  <span>Sim</span>
                                ) : (
                                  <span>Não</span>
                                )}
                              </td>
                              <td className="has-text-centered">{value.brand_name}</td>
                              <td className="has-text-centered">
                                  <span>{convertToReal(value.total_machine_used, value.total_machine_new)}</span>
                              </td>
                              <td className="has-text-centered">
                                <div className="button-action status-sales">
                                  {value.status === "0" ? (
                                    <span>Venda</span>
                                  ) : ('')}
                                  {value.status === "1" ? (
                                    <span>Pedido na Fábrica</span>
                                  ) : ('')}
                                  {value.status === "2" ? (
                                    <span>Disponível para Entrega</span>
                                  ) : ('')}
                                  {value.status === "3" ? (
                                    <span>Entregue</span>
                                  ) : ('')}
                                  {value.status === "4" ? (
                                    <span>Reprovada</span>
                                  ) : ('')}
                                </div>
                              </td>
                              <td width="180" className="has-text-centered">
                                <div className="button-action">
                                  <Link
                                    to={`/sales/machine/edit/${value.id}`}
                                    className="button is-info"
                                  >
                                    Editar
                                  </Link>
                                  <Button
                                    className="button is-danger"
                                    onClick={() => deleteSalesMachine(value.id, value.customer_name)}
                                  >
                                    Excluir
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                    <Pagination
                      activePage={salesList.current_page}
                      itemsCountPerPage={salesList.per_page}
                      totalItemsCount={salesList.total}
                      onChange={(pageNumber) => fetchDataSalesList(pageNumber)}
                      prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                      firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                      nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                      lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
                    />
                  </>
                )}
              </Columns.Column>
            )}
          </>
        )}
      </div>
    </div>
  );
}
