import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { customerUpdateSuccess } from './actions';

export function* customerSave({ payload }) {
  try {
    const response = yield call(api.post, 'customer', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* customerUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `customer/${payload.id}`, payload.data);
    toast.success('Cliente atualizado com sucesso!');
    yield put(customerUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o cliente.');
  }
}

export function* customerDelete({ payload }) {
  try {
    yield call(api.delete, `customer/${payload.customer_id}`);
    toast.success('Cliente excluído com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar o cliente.');
  }
}

export default all([
  takeLatest('@customer/CUSTOMER_SAVE_REQUEST', customerSave),
  takeLatest('@customer/CUSTOMER_UPDATE_REQUEST', customerUpdate),
  takeLatest('@customer/DELETE_CUSTOMER_REQUEST', customerDelete),
]);
