import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { formserviceUpdateSuccess } from './actions';

export function* formserviceSave({ payload }) {
  try {
    const response = yield call(api.post, 'form-service', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* formserviceUpdate({ id, payload }) {
  try {
    const response = yield call(
      api.put,
      `form-service/${payload.id}`,
      payload.data
    );
    toast.success('Forma de atendimento atualizada com sucesso!');
    yield put(formserviceUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o motivo.');
  }
}

export function* formserviceDelete({ payload }) {
  try {
    yield call(api.delete, `form-service/${payload.formservice_id}`);
    toast.success('Forma de atendimento excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar o motivo.');
  }
}

export default all([
  takeLatest('@formservice/FORMSERVICE_SAVE_REQUEST', formserviceSave),
  takeLatest('@formservice/FORMSERVICE_UPDATE_REQUEST', formserviceUpdate),
  takeLatest('@formservice/DELETE_FORMSERVICE_REQUEST', formserviceDelete),
]);
