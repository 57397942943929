import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { brandUpdateSuccess } from './actions';

export function* brandSave({ payload }) {
  try {
    const response = yield call(api.post, 'brand', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* brandUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `brand/${payload.id}`, payload.data);
    toast.success('Marca atualizado com sucesso!');
    yield put(brandUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a marca.');
  }
}

export function* brandDelete({ payload }) {
  try {
    yield call(api.delete, `brand/${payload.brand_id}`);
    toast.success('Marca excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a marca.');
  }
}

export default all([
  takeLatest('@brand/BRAND_SAVE_REQUEST', brandSave),
  takeLatest('@brand/BRAND_UPDATE_REQUEST', brandUpdate),
  takeLatest('@brand/DELETE_BRAND_REQUEST', brandDelete),
]);
