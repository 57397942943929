import styled from 'styled-components';

export const GraphicWorkDone = styled.div`
  margin: 40px 0;

  .align-column-center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h3{
    color: #43425D !important;
    margin-top: 5px;
    position: relative;

    .color{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      left: -20px;
      top: 5px;
    }
  }

  .value{
    color: rgba(77,79,91,0.4) !important;
    font-size: 14px !important;
  }
`;
