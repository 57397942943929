import React, { useState } from 'react';
import { Columns } from 'react-bulma-components';
import { RiFilterOffFill } from 'react-icons/ri';
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";
import { format } from 'date-fns'

import api from '~/services/api';
import DatePicker from "react-datepicker";

const ModalReportsManagerHecByServices = props => {

  const [isLoadingPDF, setIsLoadingPDF] = useState(true);
  const [isLoadingXLS, setIsLoadingXLS] = useState(true);
  const [isLoadingSelects, setIsLoadingSelects] = useState(true);

  const [filter, setFilter] = useState({});
  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  function cleanInputsFilter() {
    setStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  }

  const handleSubmit = (event, type) => {
    if (type === 'pdf'){
      setIsLoadingPDF(false);
    } else {
      setIsLoadingXLS(false);
    }
    event.preventDefault();

    setFilter({
      params: {
        startDate: format(new Date(startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(endDate), 'yyyy-MM-dd'),
      }
    });
    if (type === 'pdf'){
      api.get(`/reports/sales/hectares-by-services`, {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          type: type,
        },
        responseType: 'blob'
      }).then(response => {
        setIsLoadingPDF(true);
        const file = new Blob([response.data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    } else {
      api.get(`/reports/sales/hectares-by-services`, {
        params: {
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          type: type,
        },
        responseType: 'blob'
      }).then(response => {
        setIsLoadingXLS(true);
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Hectares_Servicos.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  }

  return (
    <>
      <div className={props.show ? 'report modal is-active' : 'report modal'}>
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Relatório de hectares por serviços</p>
            <button
              onClick={props.onClose}
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <Columns>
              <Columns.Column>
                <Columns>
                  <form className="manager filter">
                    <Columns>
                      <Columns.Column size={12}>
                        <div className="field inline">
                          <div className="field">
                            <div className="control">
                              <label htmlFor="name" className="label">
                                Data Inicial
                              </label>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className="input"
                                popperPlacement="bottom-right"
                              />
                            </div>
                          </div>
                          <span className="between-dates"></span>
                          <div className="field">
                            <div className="control">
                              <label htmlFor="name" className="label">
                                Data Final
                              </label>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                className="input"
                                popperPlacement="bottom-right"
                              />
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column>
                        <div className="button-clean" onClick={cleanInputsFilter} >
                          <RiFilterOffFill
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          /> Limpar filtros
                        </div>
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column size={6}>
                        <div className="field is-horizontal">
                          <div className="field-body">
                            <div className="field">
                              <div className="control">
                                <button onClick={e => handleSubmit(e,'xls')} className="button is-info">
                                  <AiOutlineFileExcel
                                    size={15}
                                    color="#FFFFFF"
                                    className="icon-button-back"
                                  />
                                  {isLoadingXLS ? 'Gerar Excel' : 'Carregando, aguarde!'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                      <Columns.Column size={6}>
                        <div className="field is-horizontal">
                          <div className="field-body">
                            <div className="field">
                              <div className="control has-text-right">
                                <button onClick={e => handleSubmit(e,'pdf')} className="button is-info">
                                  <AiOutlineFilePdf
                                    size={15}
                                    color="#FFFFFF"
                                    className="icon-button-back"
                                  />
                                  {isLoadingPDF ? 'Gerar PDF' : 'Carregando, aguarde!'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Columns.Column>
                    </Columns>
                    <br />
                    <br />
                    <br />
                  </form>
                </Columns>
              </Columns.Column>
            </Columns>
          </section>
        </div>
      </div>
    </>
  )
}

export default ModalReportsManagerHecByServices;
