// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck, MdAddCircleOutline } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import DateApplication from "../../components/DateApplication";

import api from '~/services/api';
import { applicationUpdateRequest } from '~/store/modules/application/actions';

import { format } from 'date-fns'
import { registerLocale }  from "react-datepicker";
import pt from "date-fns/locale/pt";
registerLocale("pt", pt);

export default function ApplicationEdit() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(true);
  const [isLoadingCulture, setIsLoadingCulture] = useState(true);
  const [isLoadingField, setIsLoadingField] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const [product, setProduct] = useState([]);
  const [formApplication, setFormApplication] = useState([]);
  const [field, setField] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [culture, setCulture] = useState([]);
  const [application, setApplication] = useState([]);
  const [applicationEdit, setApplicationEdit] = useState([]);
  const [user, setUser] = useState([]);
  const [fieldInput, setFieldInput] = useState('');
  const [fields, setFields] = useState([{
    product_applications_id:null,
    form_applications_id: null,
    culture_id: null,
    type: null,
    percentage: null,
    info: null,
    date_application: null,
  }]);

  const EditApplication = () => {
    const { id } = useParams();
    return id;
  };
  const application_id = EditApplication();

  // Duplicar campos
  function handleAdd() {
    const values = [...fields];
    values.push({
      product_applications_id:null,
      form_applications_id: null,
      culture_id: null,
      type: null,
      percentage: null,
      info: null,
      date_application: null
    });
    setFields(values);
  }

  const handleChangeProduct = (idx, value) => {
    let ProductApplicationID = [...applicationEdit];
    ProductApplicationID[idx].product_applications_id = value.target.value;
    setApplicationEdit(ProductApplicationID);
  }

  const handleChangeFormApplication = (idx, value) => {
    let FormApplicationID = [...applicationEdit];
    FormApplicationID[idx].form_applications_id = value.target.value;
    setApplicationEdit(FormApplicationID);
  }

  const handleChangeCulture = (idx, value) => {
    let FormApplicationID = [...applicationEdit];
    FormApplicationID[idx].culture_id = value.target.value;
    setApplicationEdit(FormApplicationID);
  }

  const handleChangePercentage = (idx, value) => {
    let Percentage = [...applicationEdit];
    Percentage[idx].percentage = value.target.value;
    setApplicationEdit(Percentage);
  }

  const onChangeType = (idx, value) => {
    if(value.target.value !== undefined){
      let Type = [...applicationEdit];
      Type[idx].type = value.target.value;
      setApplicationEdit(Type);
    }    
  }
  
  const onChangeInfo = (idx, value) => {
    let Info = [...applicationEdit];
    Info[idx].info = value.target.value;
    setApplicationEdit(Info);
  }

  const onChangeDateApplication = (idx, value) => {   
    let DateApplication = [...applicationEdit];
    DateApplication[idx].date_application = format(new Date(value), 'yyyy-MM-dd');
    setApplicationEdit(DateApplication); 
  }
  
  function handleRemove(i, application_products_id) {
    if (application_products_id) {
      const values = [...fields];
      values.splice(i, 1);
      setFields(values);
      api.put(`application/delete-application-products/${application_products_id}`);
    } else {
      const values = [...fields];
      values.splice(i, 1);
      setFields(values);
    }
  }

  const onChange = e => {
    setApplication({...application,
      [e.target.name]: e.target.value}
    );
    if (e.target.name === 'field_id'){
      fetchDataFieldQtdeHectares(e.target.value)
    }    
  };

  function onSubmit(data) {
    data.customer_id = application.customer_id;
    data.field_id = application.field_id;
    data.products = applicationEdit;
    dispatch(applicationUpdateRequest(application_id, data));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/application/${application_id}`);
        setApplication(response.data.application);
        fetchDataField(response.data.application.customer_id, 'effect')
        
        setFields(response.data.applications_products);

        var values = [];
        response.data.applications_products.map((value, index) => {   
          let type = '';
          if ((value.type_correction === '1') && (value.type_maintenance === '0') ){
            type = 'correcao'
          }       
          if ((value.type_correction === '0') && (value.type_maintenance === '1') ){
            type = 'manutencao'
          }       
          if ((value.type_correction === '1') && (value.type_maintenance === '1') ){
            type = 'all'
          }       
          values.push({
            application_products_id: value.application_products_id, 
            product_applications_id: value.product_applications_id, 
            form_applications_id: value.form_applications_id,
            culture_id: value.culture_id,
            type: type,
            percentage: value.percentage,
            date_application: format(new Date(value.date_application), 'yyyy-MM-dd'),
            info: value.info
          });
          setApplicationEdit(values);          
        })

        setFieldInput(response.data.application.qtde_hectares);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataCustomer = async () => {
      setIsLoadingCustomer(true);
      try {
        const response = await api.get('/customer/registers/all');
        setCustomer(response.data);
        setIsLoadingCustomer(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataCustomer();
  }, []);

  useEffect(() => {
    const fetchDataCulture = async () => {
      setIsLoadingCulture(true);
      try {
        const response = await api.get('/culture/registers/all');
        setCulture(response.data);
        setIsLoadingCulture(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataCulture();
  }, []);

  const fetchDataField = async (e, type) => {
    let valueCustomer = '';
    if (type === 'effect'){
      valueCustomer = e;
    } else {
      valueCustomer = e.target.value;
    }
    setIsLoadingField(true);
    try {
      const response = await api.get(`field/find-by-customer/${valueCustomer}`);
      setField(response.data);
      setIsLoadingField(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };


  useEffect(() => {
    const fetchDataUser = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/user/registers/all');
        setUser(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataUser();
  }, []);

  useEffect(() => {
    const fetchDataProduct = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('product-application/registers/all');
        setProduct(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataProduct();
  }, []);

  useEffect(() => {
    const fetchDataFormApplication = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('form-application/registers/all');
        setFormApplication(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataFormApplication();
  }, []);

  const fetchDataFieldQtdeHectares = async (field_id) => {
    try {
      const response = await api.get(`field/${field_id}`);
      setFieldInput(response.data.qtde_hectares);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Atualizar aplicação</li>
            </ul>
            <h1>Atualizar aplicação</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/application/list">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={12} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>

            <input name="user_id" type="hidden" ref={register} defaultValue={profile.id}/>

            <Columns className="no-padding">
              <Columns.Column size={12} className="no-padding">
                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Cliente
                    </label>
                    <select name="customer_id" ref={register({ required: true })}  defaultValue={application.customer_id}  className="select" onChange={onChange} onBlur={fetchDataField}>
                      <option value="" className="placeholder">Selecione um cliente</option>
                      { isLoadingCustomer ? (
                        <option>Carregando</option>
                      ) : (
                        customer.map((value, index) => {
                          return <option value={value.id} key={index} selected={application.customer_id === value.id} >{value.name}</option>
                        })
                      )}
                    </select>
                    {errors.customer_id && errors.customer_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
              </Columns.Column>
            </Columns>

            <Columns className="no-padding">
              <Columns.Column size={6} className="no-padding">
                <Columns className="is-gapless no-margin-bottom">
                  <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Talhão
                        </label>
                        <select name="field_id" ref={register({ required: true })} defaultValue={application.field_id}  className="select" onChange={onChange} >
                          <option value="" className="placeholder">Selecione o talhão</option>
                          { isLoadingField ? (
                            <option>Carregando</option>
                          ) : (
                            field.map((value, index) => {
                              return <option value={value.id} key={index} selected={application.field_id === value.id} >{value.name}</option>
                            })
                          )}
                        </select>
                        {errors.field_id && errors.field_id.type === 'required' && (
                          <span className="error-inputs">
                            Este campo é obrigatório
                          </span>
                        )}
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
              <Columns.Column size={6} className="no-padding">
                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Quantidade de HA
                    </label>
                    <div className="control">
                      <input
                        name="qtde_hectares"
                        type="text"
                        ref={register()}
                        placeholder="Informe a quantidade hectáres aplicado"
                        className="input"
                        defaultValue={fieldInput}
                      />
                    </div>
                  </div>
                  {errors.qtde_hectares && errors.qtde_hectares.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </Columns.Column>
            </Columns>
            <br />


            {/* Código de replicação */}
            <>
              {fields.map((field, idx) => {
                return (
                  <Columns className="no-padding" key={`${field}-${idx}`}>

                    <Columns.Column size={12} className="no-padding">
                      <div className="field">
                        <div className="control" onClick={e => onChangeType(idx, e)}>
                          <label className="radio">
                            <input
                              type="radio"
                              value="correcao"
                              name={`type-${idx}`}
                              defaultChecked={(field.type_correction === '0') ? false : true}
                            /> Correção
                          </label>
                          <label className="radio">
                            <input
                              type="radio"
                              value="manutencao"
                              name={`type-${idx}`}
                              defaultChecked={(field.type_maintenance === '1') ? true : false}
                            /> Manutenção
                          </label>
                          <label className="radio">
                            <input
                              type="radio"
                              value="all"
                              name={`type-${idx}`}
                              defaultChecked={((field.type_maintenance === '1') && (field.type_correction === '1')) ? true : false}
                            /> Correção e Manutenção
                          </label>
                        </div>
                      </div>
                    </Columns.Column>

                    <Columns.Column size={4} className="no-padding">
                      <div className="field">
                        <div className="control">
                          <label htmlFor="name" className="label">
                            Produto / Serviço
                          </label>
                          <select name={`product_applications_id-${idx}`} ref={register({ required: true })} defaultValue={field.product_applications_id} className="select" onBlur={e => handleChangeProduct(idx, e)} >
                            <option value="" className="placeholder">Selecione um produto/serviço</option>
                            { isLoading ? (
                              <option>Carregando</option>
                            ) : (
                              product.map((value, index) => {
                                return <option value={value.id} key={index} selected={field.product_applications_id === value.id} >{value.name}</option>
                              })
                            )}
                          </select>
                          {errors.product_applications_id && errors.product_applications_id.type === 'required' && (
                            <span className="error-inputs">
                              Este campo é obrigatório
                            </span>
                          )}
                        </div>
                      </div>
                    </Columns.Column>
                    <Columns.Column size={4} className="no-padding">
                      <div className="field">
                        <div className="control">
                          <label htmlFor="name" className="label">
                            Forma de aplicação
                          </label>
                          <select name={`form_applications_id-${idx}`} ref={register({ required: true })} defaultValue={field.form_applications_id} className="select" onBlur={e => handleChangeFormApplication(idx, e)} >
                            <option value="" className="placeholder">Selecione uma forma de aplicação</option>
                            { isLoading ? (
                              <option>Carregando</option>
                            ) : (
                              formApplication.map((value, index) => {
                                return <option value={value.id} key={index} selected={field.form_applications_id === value.id} >{value.name}</option>
                              })
                            )}
                          </select>
                          {errors.form_applications_id && errors.form_applications_id.type === 'required' && (
                            <span className="error-inputs">
                              Este campo é obrigatório
                            </span>
                          )}
                        </div>
                      </div>
                    </Columns.Column>
                    <Columns.Column size={4} className="no-padding">
                      <div className="field">
                        <div className="control">
                          <label htmlFor="name" className="label">
                            Cultura
                          </label>
                          <select name={`culture_id-${idx}`} ref={register({ required: true })} className="select" defaultValue={field.culture_id} onBlur={e => handleChangeCulture(idx, e)} >
                            <option value="" className="placeholder">Selecione uma cultura</option>
                            { isLoadingCulture ? (
                              <option>Carregando</option>
                            ) : (
                              culture.map((value, index) => {
                                return <option value={value.id} key={index} selected={field.culture_id === value.id} >{value.name}</option>
                              })
                            )}
                          </select>
                          {errors.culture_id && errors.culture_id.type === 'required' && (
                            <span className="error-inputs">
                              Este campo é obrigatório
                            </span>
                          )}
                        </div>
                      </div>
                    </Columns.Column>

                    <Columns.Column size={6} className="no-padding">
                      <div className="field">
                        <div className="control">
                          <label htmlFor={`percentage-${idx}`} className="label">
                            Porcentagem de correção
                          </label>
                          <input
                            name={`percentage-${idx}`}
                            type="text"
                            placeholder="% de aplicação, informe somente o valor ex.: 75"
                            onBlur={e => handleChangePercentage(idx, e)}
                            className="input"
                            defaultValue={field.percentage}
                          />
                        </div>
                      </div>
                    </Columns.Column>

                    <Columns.Column size={6} className="no-padding">
                      <div className="field">
                        <div className="control">
                          <label htmlFor="name" className="label">
                            Data
                          </label>
                          <div className="control">
                            <DateApplication 
                              setDefaultDateApplication={(field.date_application === '0000-00-00 00:00:00') ? 
                                (field.created_at) : 
                                (field.date_application)
                              }
                              onChange={e => onChangeDateApplication(idx, e)}
                            />
                          </div>
                        </div>
                      </div>
                    </Columns.Column>

                    <Columns.Column size={12} className="no-padding">
                      <div className="field">
                        <div className="control">
                          <label htmlFor={`info-${idx}`} className="label">
                            Informações sobre a correção ou manutenção
                          </label>
                          <textarea
                            name={`info-${idx}`}
                            placeholder="Informações sobre a correção ou manutenção"
                            className="textarea"
                            onBlur={e => onChangeInfo(idx, e)}
                            className="input"
                            defaultValue={field.info}
                          />
                        </div>
                      </div>
                    </Columns.Column>
                    <div className="action">
                      <button type="button" className="button is-danger no-padding" onClick={() => handleRemove(idx, field.application_products_id)}>Remover Produto</button>
                    </div>
                  </Columns>
                );
              })}
              <Columns className="no-padding">
                <Columns.Column size={12} className="no-padding">
                  <div className="action-right">
                    <button type="button" className="button is-primary no-padding" onClick={() => handleAdd()}>Adicionar campos</button>
                  </div>
                </Columns.Column>
              </Columns>
            </>
            <br />

            <Columns className="no-padding">
              <Columns.Column size={12} className="no-padding">
                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Responsável pela aplicação
                    </label>
                    <select name="user_application_id" ref={register()} defaultValue={application.user_application_id} className="select">
                      <option value="" className="placeholder">Selecione o responsável</option>
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        user.map((value, index) => {
                          return <option value={value.id} key={index} selected={application.user_application_id === value.id} >{value.name}</option>
                        })
                      )}
                    </select>
                  </div>
                </div>
              </Columns.Column>
            </Columns>


              <div className="field">
                <label htmlFor="description" className="label">
                  Descrição da aplicação
                </label>
                <div className="control">
                  <textarea
                    name="description"
                    ref={register}
                    placeholder="Informações sobre a aplicação"
                    className="textarea"
                    defaultValue={application.description}
                  />
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
