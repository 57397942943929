import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import segment from './segment/sagas';
import reason from './reason/sagas';
import formservice from './formservice/sagas';
import product from './product/sagas';
import customer from './customer/sagas';
import farm from './farm/sagas';
import field from './field/sagas';
import delivery from './delivery/sagas';
import visit from './visit/sagas';
import application from './application/sagas';
import setting from './setting/sagas';
import goal from './goal/sagas';
import maps from './maps/sagas';
import sales from './sales/sagas';
import brand from './brand/sagas';
import formapplication from './formapplication/sagas';
import productapplication from './productapplication/sagas';
import culture from './culture/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    segment,
    reason,
    formservice,
    product,
    customer,
    farm,
    field,
    delivery,
    visit,
    application,
    setting,
    goal,
    maps,
    sales,
    brand,
    formapplication,
    productapplication,
    culture,
  ]);
}
