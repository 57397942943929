// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck, MdAddCircleOutline } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { default as NumberFormat } from 'react-number-format';

import MenuRegistrations from '~/components/MenuRegistrations';
import api from '~/services/api';
import { farmUpdateRequest } from '~/store/modules/farm/actions';

export default function CustomerEdit() {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCidade, setIsLoadingCidade] = useState(true);
  const [farm, setFarm] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const EditFarm = () => {
    const { id } = useParams();
    return id;
  };
  const farm_id = EditFarm();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`farm/${farm_id}`);
        setFarm(response.data);
        loadCity(response.data.state_id)
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('customer/registers/all');
        setCustomer(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('states');
        setStates(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  async function loadCity(state_id) {
    try {
      const response = await api.get(`cities/${state_id}`);
      setCities(response.data);
    } catch (error) {
      console.log('Error: '+error);
    }
  }

  const findyCity = e => {
    const fetchCities = async () => {
      if (e.target.value !== '') {
        setIsLoadingCidade(true);
        try {
          const response = await api.get(`cities/${e.target.value}`);
          setCities(response.data);
          setIsLoadingCidade(false);
        } catch (error) {
          console.log('Error: '+error);
        }
      }
    };
    fetchCities();
  }

  const onChange = e => {
    setFarm({...farm,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    dispatch(farmUpdateRequest(farm_id, data));
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Atualizar fazenda</li>
            </ul>
            <h1>Atualizar fazenda</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/farm">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <form className="manager" onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <label htmlFor="name" className="label">
                    Nome da fazenda
                  </label>
                  <div className="control">
                    <input
                      name="name"
                      type="text"
                      ref={register({ required: true })}
                      placeholder="Informe o nome da fazenda"
                      className="input"
                      defaultValue={farm.name}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                <Columns className="is-gapless no-margin-bottom">
                  <Columns.Column size={11}>
                    <div className="field">
                      <div className="control">
                        <label htmlFor="name" className="label">
                          Cliente
                        </label>
                        <select name="customer_id" ref={register({ required: true })} value={farm.customer_id} className="select" onChange={onChange}>
                          { isLoading ? (
                            <option>Carregando</option>
                          ) : (
                            customer.map((value, index) => {
                              return <option value={value.id} key={index}>{value.name}</option>
                            })
                          )}
                        </select>
                        {errors.customer_id && errors.customer_id.type === 'required' && (
                          <span className="error-inputs">
                            Este campo é obrigatório
                          </span>
                        )}
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column size={1}>
                    <Link to="/registrations/customer-create" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Cliente
                    </Link>
                  </Columns.Column>
                </Columns>


                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Estado da fazenda
                    </label>
                    <select name="state_id" ref={register({ required: true })} value={farm.state_id} className="select" onBlur={findyCity} onChange={onChange}>
                      <option value="">Selecione um estado</option>
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        states.map((value, index) => {
                          return <option value={value.id} key={index}>{value.title}</option>
                        })
                      )}
                    </select>
                    {errors.state_id && errors.state_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Cidade da fazenda
                    </label>
                    <select name="city_id" ref={register({ required: true })} value={farm.city_id} className="select" onChange={onChange}>
                      <option value="">Selecione uma cidade</option>
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        cities.map((value, index) => {
                          return <option value={value.id} key={index}>{value.title}</option>
                        })
                      )}
                    </select>
                    {errors.city_id && errors.city_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control has-text-right">
                        <button type="submit" className="button is-primary">
                          <MdCheck
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          />
                          Salvar dados
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
