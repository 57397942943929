import React from 'react';
import { MarkerDiv } from './styles';

export default function Marker(props) {
  const { color, text } = props;
  return (
    <>
      <MarkerDiv style={{ backgroundColor: color, cursor: 'pointer'}} >
        {text}
      </MarkerDiv>
    </>
  );
};
