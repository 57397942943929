// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck, MdAddCircleOutline } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { default as NumberFormat } from 'react-number-format';
import { format } from 'date-fns'

import MenuRegistrations from '~/components/MenuRegistrations';
import api from '~/services/api';
import { customerUpdateRequest } from '~/store/modules/customer/actions';

import DatePicker, { registerLocale }  from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
registerLocale("pt", pt); // register it with the name you want

export default function CustomerEdit() {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCidade, setIsLoadingCidade] = useState(true);
  const [product, setProduct] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [user, setUser] = useState([]);
  const [checked, setChecked] = useState();
  const [typeCustomer, setTypeCustomer] = useState('0');
  const [typeSex, setTypeSex] = useState('o');

  const EditCustomer = () => {
    const { id } = useParams();
    return id;
  };
  const customer_id = EditCustomer();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/customer/${customer_id}`);
        setCustomer(response.data);
        loadCity(response.data.state_id)
        if(response.data.type_customer === '1'){
          setTypeCustomer('1')
        }
        setTypeSex(response.data.sex)
        var formatDate = format(new Date(response.data.date_birth), 'MM/dd/yyyy');
        setStartDate(new Date(formatDate))
        if(response.data.new === '0'){
          setChecked(false);
        } else {
          setChecked(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('states');
        setStates(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  async function loadCity(state_id) {
    try {
      const response = await api.get(`cities/${state_id}`);
      setCities(response.data);
    } catch (error) {
      console.log('Error: '+error);
    }
  }

  const findyCity = e => {
    const fetchCities = async () => {
      if (e.target.value !== '') {
        setIsLoadingCidade(true);
        try {
          const response = await api.get(`cities/${e.target.value}`);
          setCities(response.data);
          setIsLoadingCidade(false);
        } catch (error) {
          console.log('Error: '+error);
        }
      }
    };
    fetchCities();
  }

  useEffect(() => {
    const fetchDataUser = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/user/registers/all');
        setUser(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchDataUser();
  }, []);

  const onChange = e => {
    setCustomer({...customer,
      [e.target.name]: e.target.value}
    );
  };

  const onChangeChecked = e => {
    setChecked(e.target.checked);
  };

  const onChangeType = (e) => {
    setTypeCustomer(e.target.value.toString());
  }

  const onChangeSex = (e) => {
    setTypeSex(e.target.value.toString());
  }

  function onSubmit(data) {
    data.cpf = customer.cpf;
    data.phone = customer.phone;
    data.new = checked;
    data.type_customer = typeCustomer;
    data.sex = typeSex;
    data.date_birth = format(new Date(startDate), 'yyyy-MM-dd');
    dispatch(customerUpdateRequest(customer_id, data));
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Atualizar cliente</li>
            </ul>
            <h1>Atualizar cliente</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/customer">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <form className="manager" onSubmit={handleSubmit(onSubmit)}>
                {!customer.user_id? (
                  <input name="user_id" type="hidden" ref={register} defaultValue={profile.id}/>
                ) : ('')}

                <div className="field">
                  <label htmlFor="name" className="label">
                    Nome do cliente
                  </label>
                  <div className="control">
                    <input
                      name="name"
                      type="text"
                      ref={register({ required: true })}
                      placeholder="Informe o nome do cliente"
                      className="input"
                      defaultValue={customer.name}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                
                <div className="field">
                  <label htmlFor="name" className="label">
                    Tipo de cliente
                  </label>
                  <div className="control">
                    <label htmlFor="customer_f" className="radio">
                      <input
                        id="customer_f"
                        type="radio"
                        value="0"
                        name="type_customer"
                        checked={typeCustomer == "0"}
                        onClick={onChangeType}
                      /> <span> Física </span>
                    </label>
                    <label htmlFor="customer_j" className="radio">
                      <input
                        id="customer_j"
                        type="radio"
                        value="1"
                        name="type_customer"
                        checked={typeCustomer == "1"}
                        onClick={onChangeType}
                      /> <span> Jurídica </span>
                    </label>
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="name" className="label">
                    Sexo
                  </label>
                  <div className="control">
                    <label htmlFor="sex_m" className="radio">
                      <input
                        id="sex_m"
                        type="radio"
                        value="m"
                        name="sex"
                        checked={typeSex == "m"}
                        onClick={onChangeSex}
                      /> <span> Masculino </span>
                    </label>
                    <label htmlFor="sex_f" className="radio">
                      <input
                        id="sex_f"
                        type="radio"
                        value="f"
                        name="sex"
                        checked={typeSex == "f"}
                        onClick={onChangeSex}
                      /> <span> Feminino </span>
                    </label>
                    <label htmlFor="sex_o" className="radio">
                      <input
                        id="sex_o"
                        type="radio"
                        value="o"
                        name="sex"
                        checked={typeSex == "o" || typeSex == ""}
                        onClick={onChangeSex}
                      /> <span> Outros </span>
                    </label>
                  </div>
                </div>
                
                {typeCustomer === '0' ? (
                  <div className="field">
                    <label htmlFor="cpf" className="label">
                      CPF
                    </label>
                    <div className="control">
                      <NumberFormat
                        className="input"
                        placeholder="Informe o cpf do cliente"
                        mask="_"
                        format={"###.###.###-##"}
                        name="cpf"
                        onChange={onChange}
                        value={customer.cpf}
                        onValueChange={(values) => {
                          const {value} = values;
                          setCustomer({...customer, cpf: value})}
                        }
                        getInputRef={(props) =>
                          <input name="cpf" type="text" ref={register({ required: true })} value={customer.cpf}/>
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="field">
                    <label htmlFor="cpf" className="label">
                      CNPJ
                    </label>
                    <div className="control">
                      <NumberFormat
                        className="input"
                        placeholder="Informe o cnpj do cliente"
                        mask="_"
                        format={"##.###.###/####-##"}
                        name="cpf"
                        onChange={onChange}
                        value={customer.cpf}
                        onValueChange={(values) => {
                          const {value} = values;
                          setCustomer({...customer, cpf: value})}
                        }
                        getInputRef={(props) =>
                          <input name="cpf" type="text" ref={register({ required: true })} value={customer.cpf}/>
                        }
                      />
                    </div>
                  </div>
                )}

                <div className="field">
                  <label htmlFor="phone" className="label">
                    Telefone
                  </label>
                  <div className="control">
                    <NumberFormat
                      className="input"
                      placeholder="Informe o telefone de contato"
                      mask="_"
                      format={"(##) #####-####"}
                      name="phone"
                      onChange={onChange}
                      value={customer.phone}
                      onValueChange={(values) => {
                        const {value} = values;
                        setCustomer({...customer, phone: value})}
                      }
                      getInputRef={(props) =>
                        <input name="phone" type="text" ref={register({ required: true })} value={customer.phone}/>
                      }
                    />
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="date_birth" className="label">
                    Data de nascimento
                  </label>
                  <div className="control">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      locale="pt"
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      className="input"
                      name="date_birth"
                    />
                  </div>
                </div>
                
                <div className="field">
                  <label htmlFor="hectares" className="label">
                    Área total (hectáres)
                  </label>
                  <div className="control">
                    <input
                      name="hectares"
                      type="text"
                      ref={register()}
                      placeholder="Informe a quantidade de hectáres do cliente"
                      className="input"
                      defaultValue={customer.hectares}
                    />
                    {errors.hectares && errors.hectares.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                
                <div className="field">
                  <label htmlFor="hectares_ap" className="label">
                    Área total de Agricultura de Precisão (hectáres)
                  </label>
                  <div className="control">
                    <input
                      name="hectares_ap"
                      type="text"
                      ref={register({ required: true })}
                      placeholder="Informe a quantidade de hectáres do cliente"
                      className="input"
                      defaultValue={customer.hectares_ap}
                    />
                    {errors.hectares_ap && errors.hectares_ap.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <label htmlFor="tech" className="label">
                      Nível tecnológico do cliente
                    </label>
                    <select name="tech" ref={register({ required: true })} className="select" value={customer.tech} onChange={onChange}>
                      <option value="basico">Básico</option>
                      <option value="intermediario">Intermediário</option>
                      <option value="avancado">Avançado</option>
                    </select>
                    {errors.tech && errors.tech.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Estado
                    </label>
                    <select name="state_id" ref={register({ required: true })} defaultValue={customer.state_id} className="select" onBlur={findyCity} onChange={onChange}>
                      <option value="">Selecione um estado</option>
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        states.map((value, index) => {
                          return <option value={value.id} key={index} selected={customer.state_id === value.id} >{value.title}</option>
                        })
                      )}
                    </select>
                    {errors.state_id && errors.state_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Cidade
                    </label>
                    <select name="city_id" ref={register({ required: true })} defaultValue={customer.city_id} className="select" onChange={onChange}>
                      <option value="">Selecione uma cidade</option>
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        cities.map((value, index) => {
                          return <option value={value.id} key={index} selected={customer.city_id === value.id} >{value.title}</option>
                        })
                      )}
                    </select>
                    {errors.city_id && errors.city_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="name" className="label">
                    Histórico
                  </label>
                  <div className="control">
                    <textarea
                      name="description"
                      ref={register}
                      placeholder="Informe o histórico"
                      className="textarea"
                      defaultValue={customer.description}
                    />
                  </div>
                </div>
                {profile.roles[0].name === 'admin' ? (
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Responsável pelo cliente
                      </label>
                      <select name="user_id" ref={register({ required: true })} defaultValue={customer.user_id }  className="select">
                        <option value="" className="placeholder">Selecione o responsável</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          user.map((value, index) => {
                            return <option value={value.id} key={index} selected={customer.user_id === value.id}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.user_id && errors.user_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <input name="user_id" type="hidden" ref={register} defaultValue={profile.id}/>
                )}

                <div className="field">
                  <label htmlFor="new" className="label">
                    Cliente novo?
                  </label>
                  <div className="control">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={checked}
                        onClick={onChangeChecked}
                        ref={register}
                        name="new"
                      /> Sim
                    </label>
                  </div>
                </div>


                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control has-text-right">
                        <button type="submit" className="button is-primary">
                          <MdCheck
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          />
                          Salvar dados
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
