// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck, MdAddCircleOutline } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns'

import api from '~/services/api';
import MenuRegistrations from '~/components/MenuRegistrations';
import { deliverySaveRequest } from '~/store/modules/delivery/actions';

import DatePicker, { registerLocale }  from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
registerLocale("pt", pt); // register it with the name you want

export default function DeliveryAdd() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState([]);
  const [user, setUser] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  function onSubmit(data) {
    data.delivery = format(new Date(startDate), 'yyyy-MM-dd');
    dispatch(deliverySaveRequest(data));
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('customer/registers/all');
        setCustomer(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('user/registers/all');
        setUser(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de entrega</li>
            </ul>
            <h1>Cadastro de entrega</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/delivery">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <label htmlFor="name" className="label">
                  Produto/Serviço
                </label>
                <div className="control">
                  <input
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o nome do produto ou serviço a ser entregue"
                    className="input"
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Cliente
                      </label>
                      <select name="customer_id" ref={register({ required: true })} className="select">
                        <option value="" className="placeholder" selected disabled>Selecione um cliente</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          customer.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.customer_id && errors.customer_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                {profile.roles[0].name === 'admin' ? (
                  <Columns.Column size={1}>
                    <Link to="/registrations/customer-create" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Cliente
                    </Link>
                  </Columns.Column>
                ) : (
                  ''
                )}
              </Columns>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Responsável
                      </label>
                      <select name="user_id" ref={register({ required: true })} className="select">
                        <option value="" className="placeholder" selected disabled>Selecione o responsável</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          user.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.user_id && errors.user_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                {profile.roles[0].name === 'admin' ? (
                  <Columns.Column size={1}>
                    <Link to="/registrations/user-create" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Usuário
                    </Link>
                  </Columns.Column>
                ) : (
                  ''
                )}
              </Columns>

              <div className="field">
                <label htmlFor="devilery" className="label">
                  Data previsão de entrega
                </label>
                <div className="control">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    className="input"
                    name="delivery"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="name" className="label">
                  Detalhes da entrega
                </label>
                <div className="control">
                  <textarea
                    name="description"
                    ref={register}
                    placeholder="Informe os detalhes da entrega"
                    className="textarea"
                  />
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
