import styled from 'styled-components';

export const Imagem = styled.div`
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`;

export const FormAuth = styled.div`
  display: flex;
  flex-wrap: wrap; // Quebra a linha
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  .form-manager {
    width: 500px;
    display: block;
  }

  .logo-form {
    width: 100%;
    margin-bottom: 5%;

    img {
      max-width: 350px;
      margin: 0 auto;
      display: block;
    }
  }

  .description{
    display: block;
    width: 100%;
    text-align: center;
  }
  p a{
    font-weight: 400;
    color: #0d593f;
    font-size: 14px !important;
    svg{
      position: relative;
      top: 3px;
    }
  }
  h2{
    font-size: 18px;
    margin-bottom: 20px;
  }
  h1{
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 20px;
    color: #0d593f;
  }
`;
