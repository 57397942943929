// eslint-disable-next-line import/no-unresolved
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Columns } from 'react-bulma-components';
// import { Form, Input } from '@rocketseat/unform';
import { useForm } from 'react-hook-form';

import logo from '~/assets/logo.png';
import bg from '~/assets/bg-login.jpg';
import { Imagem, FormAuth } from './styles';

import { signInRequest } from '~/store/modules/auth/actions';

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const { register, handleSubmit, errors } = useForm();

  function onSubmit(data) {
    dispatch(signInRequest(data.email, data.password));
  }

  return (
    <Columns>
      <Columns.Column>
        <Imagem>
          <img src={bg} alt="" />
        </Imagem>
      </Columns.Column>
      <Columns.Column>
        <FormAuth>
          <div className="form-manager">
            <div className="logo-form">
              <img src={logo} alt="Ceres Agricultura de precisão" />
            </div>
            <div className="form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <label htmlFor="email" className="label">
                    E-mail
                  </label>
                  <div className="control">
                    <input
                      name="email"
                      type="email"
                      ref={register({ required: true })}
                      placeholder="Informe seu e-mail"
                      className="input"
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="password" className="label">
                    Senha
                  </label>
                  <div className="control">
                    <input
                      name="password"
                      type="password"
                      ref={register({ required: true })}
                      placeholder="********"
                      className="input"
                    />
                    {errors.password && errors.password.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <button type="submit" className="button is-primary">
                          {loading ? 'Carregando ...' : 'Acessar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <Columns>
                  <Columns.Column>
                    <div className="field">
                      <div className="control">
                        <label className="checkbox">
                          <input
                            name="checkbox"
                            type="checkbox"
                            ref={register}
                          />{' '}
                          Manter conectado
                          {errors.name && <span>{errors.name.message}</span>}
                        </label>
                      </div>
                    </div>
                  </Columns.Column>
                  <Columns.Column>
                    <p className="forgot-password">
                      <Link to="/forgot-password"> Esqueceu sua senha? </Link>
                    </p>
                  </Columns.Column>
                </Columns>
              </form>
            </div>
          </div>
          <div className="copyright">
            Todos os direitos reservados a Ceres Agricultura de Precisão.
          </div>
        </FormAuth>
      </Columns.Column>
    </Columns>
  );
}
