import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { segmentUpdateSuccess } from './actions';

export function* segmentSave({ payload }) {
  try {
    const response = yield call(api.post, 'segment', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* segmentUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `segment/${payload.id}`, payload.data);
    toast.success('Segmento atualizado com sucesso!');
    yield put(segmentUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o segmento.');
  }
}

export function* segmentDelete({ payload }) {
  try {
    yield call(api.delete, `segment/${payload.segment_id}`);
    toast.success('Segmento excluído com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar o segmento.');
  }
}

export default all([
  takeLatest('@segment/SEGMENT_SAVE_REQUEST', segmentSave),
  takeLatest('@segment/SEGMENT_UPDATE_REQUEST', segmentUpdate),
  takeLatest('@segment/DELETE_SEGMENT_REQUEST', segmentDelete),
]);
