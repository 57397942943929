// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck, MdAddCircleOutline, MdZoomIn } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { default as NumberFormat } from 'react-number-format';
import { format } from 'date-fns'

import api from '~/services/api';
import { salesSaveRequest } from '~/store/modules/sales/actions';

import imageDefault from '../../../assets/no-photo.svg';
import DatePicker, { registerLocale }  from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
registerLocale("pt", pt); // register it with the name you want

export default function SalesAdd() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const { register, handleSubmit, errors, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [sales, setSales] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [used, setUsed] = useState(false);
  const [usedPrice, setUsedPrice] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [registrationOk, setRegistrationOk] = useState(false);
  const [modalRegistrationOk, setModalRegistrationOk] = useState("");
  const [modalUpdateRegistration, setModalUpdateRegistration] = useState();
  const [modalErroPedido, setModalErroPedido] = useState("");


  function isEmpty(str) {
    return (!str || 0 === str.length);
  }

  const handleChangeImage = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  async function onSubmit(data) {
    if(image.preview) {
      setModalErroPedido('');
      const formData = new FormData();
      formData.append("image", image.raw);

      const response = await api.post('files', formData);
      const { id, path } = response.data;

      data.file_id = id;

      data.delivery = format(new Date(startDate), 'yyyy-MM-dd');
      data.total_machine_used = sales.total_machine_used;
      data.total_machine_new = sales.total_machine_new;
      data.used_machine_price = sales.used_machine_price;

      if (isEmpty(data.machine_used)) {
        data.machine_used = "false";
      }
      if (isEmpty(data.business_used_machine)) {
        data.business_used_machine = "false";
      }
      dispatch(salesSaveRequest(data));

    } else {
      setModalErroPedido("is-active");
    }
    
  }

  const onChangeType = (e) => {
    setUsed(e.target.value);
  }

  const onChangeTypePrice = (e) => {
    setUsedPrice(e.target.value);
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/customer/registers/all');
        setCustomers(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/brand/registers/all');
        setBrands(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  const handleClickCloseModal = e => {
    setRegistrationOk(false);
    setModalRegistrationOk('');
    setValue('customer_id', '');
  };

  const handleClickCloseModalErroFoto = e => {
    setModalErroPedido('');
  };

  const fetchDataCompleteRegistration = async (e) => {
    setRegistrationOk(false);
    if (e.target.value !== '') {
      setModalUpdateRegistration(e.target.value);
      try {
        const response = await api.get(`/customer/check-registration-complete/${e.target.value}`);
        if (response.data.success === false) {
          setModalRegistrationOk("is-active");
          setRegistrationOk(true);
        }
      } catch (error) {
        console.log('Error: '+error);
      }
    }
  };

  return (
    <div className="w2d-container">
      <Columns>
        <div className={`modal ${modalRegistrationOk}`}>
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Atenção</p>
              <button
                onClick={handleClickCloseModal}
                className="delete"
                aria-label="close"
              />
            </header>
            <section className="modal-card-body">
              <h2>Cadastro de cliente selecionado está incompleto, favor verificar!</h2>
              <Link
                to={`/registrations/customer-edit/${modalUpdateRegistration}`}
                className="button is-primary button"
              >
                Atualizar cadastro do cliente
              </Link>
            </section>
          </div>
        </div>

        <div className={`modal ${modalErroPedido}`}>
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Atenção</p>
              <button
                onClick={handleClickCloseModalErroFoto}
                className="delete"
                aria-label="close"
              />
            </header>
            <section className="modal-card-body">
              <h2>É obrigatório o envio da foto/imagem do pedido!</h2>
            </section>
          </div>
        </div>

        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de Venda - Máquinas</li>
            </ul>
            <h1>Cadastro de Venda - Máquinas</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/sales/machine/list">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={12} className="bg-content">
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>

              <input name="user_id" type="hidden" ref={register} defaultValue={profile.id}/>
              <input name="type" type="hidden" ref={register} value="1"/>

              <div className="field">
                <label htmlFor="name" className="label">
                  Número do pedido
                </label>
                <div className="control">
                  <input
                    name="number_order"
                    type="text"
                    ref={register()}
                    placeholder="Informe o número do pedido"
                    className="input"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="devilery" className="label">
                  Data do pedido
                </label>
                <div className="control">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    className="input"
                    name="delivery"
                  />
                </div>
              </div>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Cliente
                      </label>
                      <select name="customer_id" ref={register({ required: true })} className="select" onChange={fetchDataCompleteRegistration}>
                        <option value="" className="placeholder" selected disabled>Selecione um cliente</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          customers.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.customer_id && errors.customer_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                {profile.roles[0].name === 'admin' ? (
                  <Columns.Column size={1}>
                    <Link to="/registrations/customer-create" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Cliente
                    </Link>
                  </Columns.Column>
                ) : (
                  ''
                )}
              </Columns>

              <Columns className="is-gapless no-margin-bottom">
                <Columns.Column size={profile.roles[0].name === 'admin' ? (11):(12)}>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="name" className="label">
                        Marca
                      </label>
                      <select name="brand_id" ref={register({ required: true })} className="select">
                        <option value="" className="placeholder" selected disabled>Selecione uma marca</option>
                        { isLoading ? (
                          <option>Carregando</option>
                        ) : (
                          brands.map((value, index) => {
                            return <option value={value.id} key={index}>{value.name}</option>
                          })
                        )}
                      </select>
                      {errors.brand_id && errors.brand_id.type === 'required' && (
                        <span className="error-inputs">
                          Este campo é obrigatório
                        </span>
                      )}
                    </div>
                  </div>
                </Columns.Column>
                {profile.roles[0].name === 'admin' ? (
                  <Columns.Column size={1}>
                    <Link to="/registrations/brand-create" className="link-add">
                      <MdAddCircleOutline
                        size={24}
                        color="#3273dc"
                      />
                      Marca
                    </Link>
                  </Columns.Column>
                ) : (
                  ''
                )}
              </Columns>

              <div className="field">
                <label htmlFor="name" className="label">
                  Descrição
                </label>
                <div className="control">
                  <textarea
                    name="description"
                    ref={register}
                    placeholder="Informações do equipamento"
                    className="textarea"
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="name" className="label">
                  Usada?
                </label>
                <div className="control">
                  <label htmlFor="machine_used_true" className="radio">
                    <input
                      id="machine_used_true"
                      type="radio"
                      value="true"
                      name="machine_used"
                      ref={register}
                      onClick={onChangeType}
                    /> <span> Sim </span>
                  </label>
                  <label htmlFor="machine_used_false" className="radio">
                    <input
                      id="machine_used_false"
                      type="radio"
                      value="false"
                      name="machine_used"
                      ref={register}
                      onClick={onChangeType}
                    /> <span> Não </span>
                  </label>
                </div>
              </div>

              {used === 'true' ? (
                <Columns className="is-gapless no-margin-bottom">
                  <Columns.Column>
                    <div className="field">
                      <label htmlFor="total_machine_used" className="label">
                        Valor máquina usada
                      </label>
                      <div className="control">
                        <NumberFormat
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          thousandSeparator="."
                          prefix="R$ "
                          placeholder="R$ 0,00"
                          className="input"
                          getInputRef={(props) => (
                            <input name="total_machine_used" type="text" ref={register} />
                          )}
                          onValueChange={(values) => {
                            const { value } = values;
                            setSales({
                              ...sales,
                              total_machine_used: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>
              ) : (
                <Columns className="is-gapless no-margin-bottom">
                  <Columns.Column>
                    <div className="field">
                      <label htmlFor="total_machine_new" className="label">
                        Valor máquina nova
                      </label>
                      <div className="control">
                        <NumberFormat
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          thousandSeparator="."
                          prefix="R$ "
                          placeholder="R$ 0,00"
                          className="input"
                          getInputRef={(props) => (
                            <input name="total_machine_new" type="text" ref={register} />
                          )}
                          onValueChange={(values) => {
                            const { value } = values;
                            setSales({
                              ...sales,
                              total_machine_new: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Columns.Column>
                </Columns>
              )}

              <div className="field">
                <label htmlFor="name" className="label">
                  Aceita máquina usada no negócio?
                </label>
                <div className="control">
                  <label htmlFor="business_used_machine_true" className="radio">
                    <input
                      id="business_used_machine_true"
                      type="radio"
                      value="true"
                      name="business_used_machine"
                      ref={register}
                      onClick={onChangeTypePrice}
                    /> <span> Sim </span>
                  </label>
                  <label htmlFor="business_used_machine_false" className="radio">
                    <input
                      id="business_used_machine_false"
                      type="radio"
                      value="false"
                      name="business_used_machine"
                      ref={register}
                      onClick={onChangeTypePrice}
                    /> <span> Não </span>
                  </label>
                </div>
              </div>

              {usedPrice === 'true' ? (
                <>
                  <Columns className="is-gapless no-margin-bottom">
                    <Columns.Column>
                      <div className="field">
                        <label htmlFor="used_machine_price" className="label">
                          Valor da máquina usada no negócio
                        </label>
                        <div className="control">
                          <NumberFormat
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator="."
                            prefix="R$ "
                            placeholder="R$ 0,00"
                            className="input"
                            getInputRef={(props) => (
                              <input name="used_machine_price" type="text" ref={register} />
                            )}
                            onValueChange={(values) => {
                              const { value } = values;
                              setSales({
                                ...sales,
                                used_machine_price: value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Columns.Column>
                  </Columns>
                  <div className="field">
                    <label htmlFor="name" className="label">
                      Descrição da máquina usada no negócio
                    </label>
                    <div className="control">
                      <textarea
                        name="description_used_machine"
                        ref={register}
                        placeholder="Informações do equipamento"
                        className="textarea"
                      />
                    </div>
                  </div>
                </>
              ): ('')}

              <div className="icon-zoom-div">
                <label htmlFor="name" className="label">
                  Foto/Imagem do Pedido
                </label>
                <label htmlFor="upload-button" className="imagem-sales-upload-label">
                  {image.preview ? (
                    <img src={image.preview} alt="" className="imagem-sales-upload uploaded"/>
                  ) : (
                    <img src={imageDefault} alt="" className="imagem-sales-upload"/>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleChangeImage}
                />
                <div className="icon-zoom">
                  <a href={image.preview} target="blank">
                    <MdZoomIn size={20} color="#A3AEB7" />
                  </a>
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
