// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdAddCircleOutline, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { FiTrash, FiX } from 'react-icons/fi';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from "react-js-pagination";

import api from '~/services/api';
import MenuRegistrations from '~/components/MenuRegistrations';
import { customerDeleteRequest } from '~/store/modules/customer/actions';

export default function CustomerList() {
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchColumn, setSearchColumn] = useState("");
  const [searchParameter, setSearchParameter] = useState("");
  const [pageNro, setPageNro] = useState(1);
  const [pageFilter, setPageFilter] = useState(false);

  const profile = useSelector((state) => state.user.profile);
  var admin = true;
  if ( profile.roles[0].name !== 'admin' ) {
    admin = false;
  }

  const setColumn = e => {
    setSearchColumn(e.target.value);
  };
  
  const setSearchParameters = e => {
    setSearchParameter(e.target.value);
  };
  
  const handleSearch = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`customer?column=${searchColumn}&filter=${searchParameter}&page=${pageNumber}`);
      setCustomer(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`customer?page=${pageNumber}`);
      setCustomer(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    
  }, []);

  function deleteCustomer(customer_id, name) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-confirm-delete">
            <div className="title">
              Confirma a exclusão do cliente: <strong>{name}</strong>?{' '}
            </div>
            <button onClick={onClose}>
              <FiX size="18px" /> Cancelar exclusão
            </button>
            <button
              className="yes"
              onClick={() => {
                handleClickDelete(customer_id);
                onClose();
                fetchData();
              }}
            >
              <FiTrash size="18px" /> Sim, pode excluir!
            </button>
          </div>
        );
      },
    });
  }

  function handleClickDelete(customer_id) {
    dispatch(customerDeleteRequest(customer_id));
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Lista de clientes</li>
            </ul>
            <h1>Lista de clientes</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/customer-create">
            <Button className="button is-primary">
              <MdAddCircleOutline
                size={15}
                color="#FFFFFF"
                className="icon-button-back"
              />
              Cadastrar cliente
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <Columns>
              <Columns.Column size={6} className="search-table">
                <div className="field">
                  <div className="control">
                    <select name="column" onChange={setColumn} className="select-search">
                      <option value="" className="placeholder" selected>Selecione uma coluna</option>
                      <option value="customer">Cliente</option>
                      <option value="state">Estado</option>
                      <option value="city">Cidade</option>
                      {admin ?
                        <option value="user">Responsável</option>
                      : ''}
                    </select>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <input
                      className="input search"
                      type="text"
                      placeholder="Pesquisar por ..."
                      autoComplete="off"
                      onChange={setSearchParameters}
                    />
                  </div>
                </div>
                <div className="field">
                  <Link
                    className="button is-primary"
                    onClick={() => {handleSearch()}}
                  >
                    <MdSearch
                      size={15}
                      color="#FFFFFF"
                      className="icon-button-back"
                    /> Pesquisar
                  </Link>
                </div>
              </Columns.Column>
              <Columns.Column size={6} className="has-text-right">
                <span className="total-register">Total de registros: <strong>{customer.total}</strong></span>
              </Columns.Column>
            </Columns>
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <>
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th width="80" className="has-text-centered">Código</th>
                      <th>Nome do Cliente</th>
                      <th>Estado do Cliente</th>
                      <th>Cidade do Cliente</th>
                      <th>Responsável</th>
                      <th width="180" className="has-text-centered">Ações</th>
                    </tr>
                  </thead>
                  {customer.data.length === 0 ? (
                    <tbody>
                      <tr>
                        <td colSpan="5" className="has-text-centered">Nenhum Registro encontrado :(</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {customer.data.map((value, index) => (
                        <tr key={value.id}>
                          <td key={value.id} className="has-text-centered">{value.id}</td>
                          <td>{value.name}</td>
                          <td>{value.state_name}</td>
                          <td>{value.city_name}</td>
                          <td>{value.user_name}</td>
                          <td width="180" className="has-text-centered">
                            <div className="button-action">
                              <Link
                                to={`/registrations/customer-edit/${value.id}`}
                                className="button is-info"
                              >
                                Editar
                              </Link>
                              <Button
                                className="button is-danger"
                                onClick={() => deleteCustomer(value.id, value.name)}
                              >
                                Excluir
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                <Pagination
                  activePage={customer.current_page}
                  itemsCountPerPage={customer.per_page}
                  totalItemsCount={customer.total}
                  onChange={(pageNumber) => handleSearch(pageNumber)}
                  prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                  firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                  nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                  lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
                />
              </>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
