import React from 'react';
import { Columns } from 'react-bulma-components';
import { Chart, Interval } from 'bizcharts';
import { NumberVisitsDaysContainer } from './styles';
import { format } from 'date-fns'

export default function NumberVisitsDays({ data }) {
  const visits = [];
  {data.map((value, index) => {
    const formatDate = format(new Date(value.visits_created_at), 'dd/MM/yyyy');
    visits.push({
      'date': formatDate,
      'total': value.count_customer
    })
  })}

  return (
    <NumberVisitsDaysContainer>
      <Columns>
        <Columns.Column size={12}>
          <Chart height={250} autoFit data={visits} >
            <Interval position="date*total"  />
          </Chart>
        </Columns.Column>
      </Columns>
    </NumberVisitsDaysContainer>
  );
}
