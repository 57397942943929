export function visitSaveRequest(data) {
  return {
    type: '@visit/VISIT_SAVE_REQUEST',
    payload: { data },
  };
}

export function visitSaveSuccess(data) {
  return {
    type: '@visit/VISIT_SAVE_SUCCESS',
    payload: { data },
  };
}

export function visitUpdateRequest(id, data) {
  return {
    type: '@visit/VISIT_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function visitUpdateSuccess() {
  return {
    type: '@visit/VISIT_UPDATE_SUCCESS',
  };
}

export function visitDeleteRequest(visit_id) {
  return {
    type: '@visit/DELETE_VISIT_REQUEST',
    payload: { visit_id },
  };
}
