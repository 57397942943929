import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { applicationUpdateSuccess } from './actions';

export function* applicationSave({ payload }) {
  try {
    const response = yield call(api.post, 'application', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados.');
  }
}

export function* applicationUpdate({ id, payload }) {
  try {
    const response = yield call(api.put, `application/${payload.id}`, payload.data);
    toast.success('Aplicação atualizada com sucesso!');
    yield put(applicationUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a aplicação.');
  }
}

export function* applicationDelete({ payload }) {
  try {
    yield call(api.delete, `application/${payload.application_id}`);
    toast.success('Aplicação excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a aplicação.');
  }
}

export default all([
  takeLatest('@application/APPLICATION_SAVE_REQUEST', applicationSave),
  takeLatest('@application/APPLICATION_UPDATE_REQUEST', applicationUpdate),
  takeLatest('@application/DELETE_APPLICATION_REQUEST', applicationDelete),
]);
