import React from 'react';
import ModalReportsServicesByYear from './Services/modal_services_by_year';

import ModalReportsAgentReason from './Agent/modal_agent_by_reason';
import ModalReportsAgentCityVisit from './Agent/modal_agent_by_city_visit';
import ModalReportsAgentTypeServices from './Agent/modal_agent_by_type_services';
import ModalReportsAgentFormServices from './Agent/modal_agent_by_form_services';
import ModalReportsAgentSalesServicesCity from './Agent/modal_agent_by_sales_city';

import ModalReportsManagerHecByServices from './Manager/modal_manager_hec_by_services';
import ModalReportsManagerMediaPriceServices from './Manager/modal_manager_media_price_services';
import ModalReportsManagerServicesTop from './Manager/modal_manager_services_top';
import ModalReportsManagerCustomer from './Manager/modal_manager_customer';
import ModalReportsManagerApplication from './Manager/modal_manager_application';
import ModalReportsManagerSales from './Manager/modal_manager_sales';
import ModalReportsManagerSalesBilling from './Manager/modal_manager_sales_billing';
import ModalReportsManagerTarget from './Manager/modal_manager_target';
import ModalReportsManagerMachineUsed from './Manager/modal_manager_machine_used';
import ModalReportsManagerVisit from './Manager/modal_manager_visit';

const ModalReports = props => {
  return (
    <>
      {props.type === 'ServicesByYear' ? <ModalReportsServicesByYear  {...props} /> : ''}

      {props.type === 'AgentReason' ? <ModalReportsAgentReason  {...props} /> : ''}
      {props.type === 'AgentCityVisit' ? <ModalReportsAgentCityVisit  {...props} /> : ''}
      {props.type === 'AgentTypeServices' ? <ModalReportsAgentTypeServices  {...props} /> : ''}
      {props.type === 'AgentFormServices' ? <ModalReportsAgentFormServices  {...props} /> : ''}
      {props.type === 'AgentSalesServicesCity' ? <ModalReportsAgentSalesServicesCity  {...props} /> : ''}

      {props.type === 'ManagerHecByServices' ? <ModalReportsManagerHecByServices  {...props} /> : ''}
      {props.type === 'ManagerMediaPriceServices' ? <ModalReportsManagerMediaPriceServices  {...props} /> : ''}
      {props.type === 'ManagerServicesTop' ? <ModalReportsManagerServicesTop  {...props} /> : ''}
      {props.type === 'ManagerCustomer' ? <ModalReportsManagerCustomer  {...props} /> : ''}
      {props.type === 'ManagerApplication' ? <ModalReportsManagerApplication  {...props} /> : ''}
      {props.type === 'ManagerSales' ? <ModalReportsManagerSales  {...props} /> : ''}
      {props.type === 'ManagerSalesBilling' ? <ModalReportsManagerSalesBilling  {...props} /> : ''}
      {props.type === 'ManagerTarget' ? <ModalReportsManagerTarget  {...props} /> : ''}
      {props.type === 'ManagerMachineUsed' ? <ModalReportsManagerMachineUsed  {...props} /> : ''}
      {props.type === 'ManagerVisits' ? <ModalReportsManagerVisit  {...props} /> : ''}
    </>
  )
}

export default ModalReports;
