import styled from 'styled-components';

export const GoogleMapsContainer = styled.div`
  height: 60vh;
  width: 100%;
  border: 1px solid #c7c7c7;
  border-radius: 10px;

  > div, > div > div{
    border-radius: 10px;
  }
`;

export const GoogleMapsDiv = styled.div`
  margin: 0;

  .column.is-3{
    margin-top: 60px;
  }

  .align-column-center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .item {
      display: block;
    }
  }

  h3{
    color: #43425D !important;
    margin-top: 20px;
    position: relative;

    span{
      font-size: 14px !important;
      font-weight: bold !important;
    }
  }

  .value{
    color: rgba(77,79,91,0.4) !important;
    font-size: 14px !important;

    &.right{
      text-align: right;
    }
  }

  #map_canvas{
    width: 100%;
    height: 400px;
  }
`;

export const MarkerDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  border: 3px solid #fcfcfc;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 8px;

  &:hover {
    z-index: 1;
  }
`;
