import React, { useState, useEffect  } from 'react';
import { Columns } from 'react-bulma-components';
import {
  Chart,
  Legend,
  Axis,
  Tooltip,
  Interval,
  Coordinate,
} from "bizcharts";

import { GraphicWorkDone } from './styles';

export default function WorkDone( { data } ) {
  const [isLoading, setIsLoading] = useState(false);
  const [typeGraphic, setTypeGraphic] = useState('value');
  const [dataTotalWorks, setDataTotalWorks] = useState([]);

  function convertToReal(number, options = {}) {
    const { moneySign = true } = options;
    if(Number.isNaN(number) || !number) return "need a number as the first parameter";
    if(typeof number === "string") {
      number = Number(number);
    }
    let res;
    const config = moneySign ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};
    moneySign
    ? res = number.toLocaleString('pt-BR', config)
    : res = number.toLocaleString('pt-BR', config)
    const needComma = number => number <= 1000;
    if(needComma(number)) {
      res = res.toString().replace(".", ",");
    }
    return res;
  }

  var arrayNew = [];
  useEffect(() => {
    const setData = async () => {
      {data.works.map((value, index) => {
        arrayNew.push({type: value.type, value: parseInt(value.value), color: value.color});
      })}
      setDataTotalWorks(arrayNew);
      if (data.works.length === 0){
        arrayNew.push({type: "Sem produto",  value: 100, color: "#F53C56"})
      }
      setDataTotalWorks(arrayNew);
    };
    setData();
  }, []);


  const changeTypeGraphics = (type) => {
    setIsLoading(true);
    if (type === 'hectares'){
      {data.works.map((value, index) => {
        arrayNew.push({type: value.type, value: parseInt(value.total_hectares), color: value.color});
      })}
      setDataTotalWorks(arrayNew);
      setTypeGraphic('hectares');
      setIsLoading(false);
    } else {
      {data.works.map((value, index) => {
        arrayNew.push({type: value.type, value: parseInt(value.value), color: value.color});
      })}
      setDataTotalWorks(arrayNew);
      setTypeGraphic('value');
      setIsLoading(false);
    }
  }

  return (
    <GraphicWorkDone>
      <Columns>
        {isLoading ?
          <div className="loadingGoals">Carregando, aguarde!</div>
        :
          <>
          <Columns.Column size={12}>
            <div className="checkbox-filter">
              <div className="title">Filtrar por: </div>
              <label className="checkbox">
                  <input
                    type="checkbox"
                    value="true"
                    onClick={(e) => {changeTypeGraphics('value')}}
                    checked={typeGraphic == "value"}
                  /> Valor
                </label>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    value="true"
                    onClick={(e) => {changeTypeGraphics('hectares')}}
                    checked={typeGraphic == "hectares"}
                  /> Hectares
                </label>
            </div>
          </Columns.Column>
          <Columns.Column size={9}>
            <Chart data={dataTotalWorks} key={dataTotalWorks} height={350} autoFit animate={ false }>
              <Coordinate type="theta" radius={0.8} innerRadius={0.5} />
              <Axis visible={false} />
              <Tooltip visible={false} />
              <Legend visible={false} />
              <Interval
                label="type"
                adjust="stack"
                position="value"
                color={['color', (xVal) => {
                  return xVal;
                }]}
                shape="rect"
              />
            </Chart>
          </Columns.Column>
          <Columns.Column size={3}>
            <div className="align-column-center">
              {typeGraphic === 'value' ?
                <>
                  {data.works.map((value, index) => (
                    <>
                      <div className="item" key={index}>
                        <h3>
                          <span style={{background: `${value.color}`}} className="color"></span>
                          <span className="value">{(!value.value ? '0,00' : convertToReal(value.value))}</span>
                        </h3>
                      </div>
                    </>
                  ))}
                </>
              :
                <>
                  {data.works.map((value, index) => (
                    <>
                      <div className="item" key={index}>
                        <h3>
                          <span style={{background: `${value.color}`}} className="color"></span>
                          <span className="value">{value.total_hectares}</span>
                        </h3>
                      </div>
                    </>
                  ))}
                </>
              }
            </div>
          </Columns.Column>
        </>
        }
      </Columns>
    </GraphicWorkDone>
  );
}
