import React, { useState } from 'react';
import { Columns } from 'react-bulma-components';
import GoogleMapReact from 'google-map-react';

import { GoogleMapsDiv, GoogleMapsContainer } from './styles';
import Marker from './marker';

export default function GoogleMaps( { data } ) {
  const [center, setCenter] = useState({lat: -26.2593706, lng: -52.7706425 });
  const [zoom, setZoom] = useState(6);

  const markers = [];
  {data.visits.map((value, index) => (
    markers.push({
      'count': value.count_city,
      'lat': value.city_lat,
      'long': value.city_long
    })
  ))}

  const getMapOptions = (maps) => {
    return {
      disableDefaultUI: false,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }],
    };
  };

  return (
    <GoogleMapsDiv>
      <Columns>
        <Columns.Column size={9}>
          <>
            <GoogleMapsContainer>
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBOpYxtWYVQXeAMZ2gGzngovLKvdzu4k60' }}
                defaultCenter={center}
                defaultZoom={zoom}
                options={getMapOptions}
              >
                {markers.map((value, index) => (
                  <Marker
                    key={index}
                    lat={value.lat}
                    lng={value.long}
                    text={value.count}
                    color="#556000"
                  />
                ))}

              </GoogleMapReact>
            </GoogleMapsContainer>
          </>
        </Columns.Column>
        <Columns.Column size={3}>
          <div className="align-column-center">
            {data.visits.map((value, index) => (
              <>
                <div className="item" key={index}>
                  <h3>
                    <div className="value right">{value.city_name} - {value.state_letter} : <span>{value.count_city}</span></div>
                  </h3>
                </div>
              </>
            ))}
          </div>
        </Columns.Column>
      </Columns>
    </GoogleMapsDiv>
  );
}
