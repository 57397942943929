// eslint-disable-next-line import/no-unresolved
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';
import { default as NumberFormat } from 'react-number-format';

import MenuRegistrations from '~/components/MenuRegistrations';
import api from '~/services/api';
import { fieldUpdateRequest } from '~/store/modules/field/actions';

export default function FieldEdit() {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [field, setField] = useState([]);
  const [customer, setCustomer] = useState([]);

  const FieldField = () => {
    const { id } = useParams();
    return id;
  };
  const field_id = FieldField();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`field/${field_id}`);
        setField(response.data);
        // loadFarm(response.data.customer_id)
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('customer/registers/all');
        setCustomer(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  const onChange = e => {
    setField({...field,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    dispatch(fieldUpdateRequest(field_id, data));
  }

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Atualizar talhão</li>
            </ul>
            <h1>Atualizar talhão</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/field">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            {isLoading ? (
              <div className="loading">
              <ImSpinner11
                size={24}
                color="#2a775a"
                className="fa-spin"
              /> Carregando ...</div>
            ) : (
              <form className="manager" onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <div className="control">
                    <label htmlFor="name" className="label">
                      Cliente
                    </label>
                    <select name="customer_id" ref={register({ required: true })} defaultValue={field.customer_id} className="select" onChange={onChange} >
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        customer.map((value, index) => {
                          return <option value={value.id} key={index} selected={field.customer_id === value.id} >{value.name}</option>
                        })
                      )}
                    </select>
                    {errors.customer_id && errors.customer_id.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="name" className="label">
                    Nome do talhão
                  </label>
                  <div className="control">
                    <input
                      name="name"
                      type="text"
                      ref={register({ required: true })}
                      placeholder="Informe o nome do talhão"
                      className="input"
                      defaultValue={field.name}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="name" className="label">
                  Quantidade de hectares
                  </label>
                  <div className="control">
                    <input
                      name="qtde_hectares"
                      type="text"
                      ref={register({ required: true })}
                      placeholder="Informe a quantidade de hectares"
                      className="input"
                      defaultValue={field.qtde_hectares}
                    />
                    {errors.qtde_hectares && errors.qtde_hectares.type === 'required' && (
                      <span className="error-inputs">
                        Este campo é obrigatório
                      </span>
                    )}
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="registration" className="label">
                    Matrícula da propriedade
                  </label>
                  <div className="control">
                    <input
                      name="registration"
                      type="text"
                      ref={register()}
                      placeholder="Informe a matrícula do cliente"
                      className="input"
                      defaultValue={field.registration}
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="name" className="label">
                    Produtividade do talhão em números
                  </label>
                  <div className="control">
                    <textarea
                      name="number_productivity"
                      ref={register}
                      placeholder="Informe os dados de produtividade do talhão"
                      className="textarea"
                      defaultValue={field.number_productivity}
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="name" className="label">
                    Produtividade do talhão
                  </label>
                  <div className="control">
                    <textarea
                      name="description"
                      ref={register}
                      placeholder="Informe os produtividade do talhão"
                      className="textarea"
                      defaultValue={field.description}
                    />
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control has-text-right">
                        <button type="submit" className="button is-primary">
                          <MdCheck
                            size={15}
                            color="#FFFFFF"
                            className="icon-button-back"
                          />
                          Salvar dados
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
