// eslint-disable-next-line import/no-unresolved
import React, { useState }  from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdHome, MdKeyboardBackspace, MdCheck } from 'react-icons/md';
import { Columns, Button } from 'react-bulma-components';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import MenuRegistrations from '~/components/MenuRegistrations';
import { brandSaveRequest } from '~/store/modules/brand/actions';

export default function BrandAdd() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue  } = useForm();
  const [registrationOk, setRegistrationOk] = useState(false);
  const [modalRegistrationOk, setModalRegistrationOk] = useState("");

  function onSubmit(data) {
    dispatch(brandSaveRequest(data));
  }

  const searchBrandExists = async (e) => {
    setRegistrationOk(false);
    if (e.target.value !== '') {
      try {
        const response = await api.get(`brand/search/${e.target.value}`);
        if (response.data.total >= '1') {
          setModalRegistrationOk("is-active");
          setRegistrationOk(true);
        }
      } catch (error) {
        console.log('Error: '+error);
      }
    }
  };

  const handleClickCloseModal = e => {
    setRegistrationOk(false);
    setModalRegistrationOk('');
    setValue('name', '');
  };

  return (
    <div className="w2d-container">
      <Columns>
        <Columns.Column>
          <div className="title-page">
            <ul className="w2d-breadcrumb">
              <li className="home">
                <Link to="/dashboard" className="link-dash">
                  <MdHome size={20} color="#A3AEB7" />
                </Link>
              </li>
              <li className="end">Cadastro de marca</li>
            </ul>
            <h1>Cadastro de marca</h1>
          </div>
        </Columns.Column>
        <Columns.Column className="column-align-right">
          <Link to="/registrations/brand">
            <Button className="button">
              <MdKeyboardBackspace
                size={15}
                color="#0D593F"
                className="icon-button-back"
              />
              Voltar para a lista
            </Button>
          </Link>
        </Columns.Column>
      </Columns>

      <div className="content">
        <Columns>
          <Columns.Column size={3} className="no-padding-menu">
            <MenuRegistrations />
          </Columns.Column>
          <Columns.Column size={9} className="bg-content">
            <div className={`modal ${modalRegistrationOk}`}>
              <div className="modal-background" />
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title">Atenção</p>
                  <button
                    onClick={handleClickCloseModal}
                    className="delete"
                    aria-label="close"
                  />
                </header>
                <section className="modal-card-body">
                  <h2>Está marca já existe em nosso sistema, favor verificar!</h2>
                  <Link
                    to={`/registrations/brand/`}
                    className="button is-primary button"
                  >
                    Ver listagem de marcas
                  </Link>
                </section>
              </div>
            </div>
            <form className="manager" onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <label htmlFor="name" className="label">
                  Nome da marca
                </label>
                <div className="control">
                  <input
                    name="name"
                    type="text"
                    ref={register({ required: true })}
                    placeholder="Informe o nome da marca"
                    className="input"
                    onBlur={searchBrandExists}
                  />
                  {errors.name && errors.name.type === 'required' && (
                    <span className="error-inputs">
                      Este campo é obrigatório
                    </span>
                  )}
                </div>
              </div>
              <div className="field">
                <label htmlFor="name" className="label">
                  Detalhes da marca
                </label>
                <div className="control">
                  <textarea
                    name="description"
                    ref={register}
                    placeholder="Informe os detalhes da marca"
                    className="textarea"
                  />
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control has-text-right">
                      <button type="submit" className="button is-primary">
                        <MdCheck
                          size={15}
                          color="#FFFFFF"
                          className="icon-button-back"
                        />
                        Salvar dados
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Columns.Column>
        </Columns>
      </div>
    </div>
  );
}
