export function segmentSaveRequest(data) {
  return {
    type: '@segment/SEGMENT_SAVE_REQUEST',
    payload: { data },
  };
}

export function segmentSaveSuccess(data) {
  return {
    type: '@segment/SEGMENT_SAVE_SUCCESS',
    payload: { data },
  };
}

export function segmentUpdateRequest(id, data) {
  return {
    type: '@segment/SEGMENT_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function segmentUpdateSuccess() {
  return {
    type: '@segment/SEGMENT_UPDATE_SUCCESS',
  };
}

export function segmentDeleteRequest(segment_id) {
  return {
    type: '@segment/DELETE_SEGMENT_REQUEST',
    payload: { segment_id },
  };
}
